import React , {useEffect, useState} from 'react';
import axios from 'axios';
import { useDispatch , useSelector} from 'react-redux';




import { modifyDisplaysearch , getLeadsSearch} from '../state/redux';
import { useUser } from '../state/reactquery';

import TabLeadsSearch from './TabLeads/TabLeadsSearch';

import './Recherche.scss'
var filtre = /^[a-zA-Z -àâäéèêëïîôöùûüÿç]+$/;
function Recherche(){
  const dispatch = useDispatch()
  
  const {data: user} = useUser(localStorage.getItem('token'))  ;
  const commerciaux= user.commerciaux;

  
  const clients= useSelector(state=>state.leads.leads);
  const [value, setValue ]= useState('');
  
  const findclient =(client,val)=>{
    let liste_nom = client?.nom ? client?.nom?.toLowerCase()?.indexOf(val?.toLowerCase())!==-1 : false ; 
    let liste_prenom = client?.nom ? client?.prenom?.toLowerCase()?.indexOf(val?.toLowerCase())!==-1 : false ; 
    let liste_email = client?.email ? client?.email?.toLowerCase()?.indexOf(val?.toLowerCase())!==-1 : false;
    return (liste_nom|| liste_prenom||  liste_email ) 
  }  

  const handleChange = async(e)=>{ setValue(e.target.value);
    if(e.target.value.length===0 ){ dispatch(getLeadsSearch([]) )}
    else if(e.target.value.length<4){dispatch(getLeadsSearch([...clients].filter(client=> findclient(client,e.target.value.trim())) ));}
    else{
      const response = await axios.post(  `/clients/searchclient/`,{data: e.target.value?.trim(), commerciaux: commerciaux } ,{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
      dispatch(getLeadsSearch([...response.data]))
    }
  }
  
  const seachLead = async (event) => {
    if(!filtre.test(event.target.value)){return   }
    if (event.key === 'Enter') { 
      const response = await axios.post(  `/clients/searchclient/`,{data: event.target.value?.trim(), commerciaux: commerciaux } ,{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
      dispatch(getLeadsSearch([...response.data]))
    }
  }
  
    useEffect(()=>{ document.getElementById('inpusearch').focus() },[])

    return(<div className='search_container' >
                <div className='search_content' >
                    <div className='search_content_top'> 
                        <div> <input  id="inpusearch"     type="text"  value={value} onChange={handleChange}     onKeyDown={seachLead} />  </div>
                        <div className='search_close' onClick={()=>{dispatch(modifyDisplaysearch(false))}}   >  </div>
                    </div>
                    <div className='tablesearch_container'  >
                    <TabLeadsSearch    />
                    </div>
                </div>
            </div> )
}


export default Recherche

