
//import djerbaredux from "./utils/djerbaredux";
import {configureStore, createSlice }  from "@reduxjs/toolkit";



const calendarSlice = createSlice({
  name : "calendar" ,
  initialState : {eventtochange:{},listcalendarevents:[]},
  reducers :{seteventtochange: (state, action)=>{ return  {...state,eventtochange :action.payload} },
              allcalendarevents : (state, action)=>{return  {...state,listcalendarevents: action.payload} }, }
})


const googleapisSlice = createSlice({
  name : "googleapis" ,
  initialState : {mails:[], clientmails:[]},
  reducers :{allmails : (state, action)=>{return  {...state,mails :action.payload} },
              clientmails : (state, action)=>{return  {...state,clientmails: action.payload} },
              deletemail : (state, action)=>{return {...state,mails:state.mails.filter((x)=>x.id !== action.payload.id)}} }
})


const leadSlice = createSlice({
    name : "lead" ,
    initialState :{  leadactu:{},leadmemo:{} ,leadappelant :{}, qualiflead : true },
    reducers :{   initialiseLeadactu : (state, action)=>{ const leed =   {...action.payload};
                                                          Object.keys(leed).map(key=>(leed[key]==="" ||leed[key]===null )&& delete leed[key]);
                                                          return  {...state,leadactu :leed} },

                  modifyChampsLeadactu : (state, action)=>{  return  {...state,leadactu :{...state.leadactu,...action.payload}} },

                  deleteChampsLeadactu : (state, action)=>{const copylead = {...state.leadactu};  action.payload.map(x =>  delete copylead[x]) ;
                                                         return {...state, leadactu : copylead}  },
                  
                  initialiseLeadappelant : (state, action)=>{  return  {...state,leadappelant :action.payload} },
                 
                  initialiseLeadmemo : (state, action)=>{ const leed =   {...action.payload};
                                    Object.keys(leed).map(key=>(leed[key]==="" ||leed[key]===null )&& delete leed[key]   )     ;
                                  return  {...state,leadmemo :leed} },

                  modifyChampsLeadmemo : (state, action)=>{  return  {...state,leadmemo :{...state.leadmemo,...action.payload}} },

                  deleteChampsLeadmemo : (state, action)=>{
                      const copylead = {...state.leadmemo}; action.payload.map(x => delete copylead[x]) ;
                      return {...state, leadmemo : copylead} }, 

                  modifyQualiflead: (state, action)=>{  return  {...state,qualiflead :action.payload} },


    }
});


export const leadsSlice = createSlice({
    name: "leads",
    initialState: { leads: []  , managerleads: [] , commerciaux:[] , leadsmonth:[] ,leadssearch :[] , fileattentes:[] , campagnes:[] , leadsfiltres :null ,
                   filtres :{'status':null, 'interaction' :null ,'campagne':null , 'hot':0 ,'date1':(new Date((new Date()).setMonth((new Date()).getMonth()-6))).setHours(0,0,0),'date2':(new Date()).setHours(0,0,0), } ,choiceliste:"newleads" } ,
    reducers: {allLeads: (state, action) => { state.leads=action.payload   ; },
                defineCampagnes: (state, action) => { state.campagnes=action.payload   ; },
               getLeadsMonth: (state, action) => { state.leadsmonth=action.payload   ; },
                allManagerLeads: (state, action) => {   state.managerleads =action.payload    ; },
                addleadManagerLeads: (state, action) => { 
                  let others = state.managerleads.filter(x=>x.id!== action.payload.id)
                   state.managerleads =[...others,action.payload]
                   } ,
                addlead: (state, action) => {  state.leads=[...state.leads,action.payload] } ,
                deletelead :(state,action)=>{state.leads = state.leads.filter(x=>x.id!==action.payload)},
                getLeadsSearch : (state, action) => { state.leadssearch=action.payload   ; },
                setFileattentes : (state, action) => { state.fileattentes=action.payload   ; },
    
                setLeadsfiltres: (state, action) => { state.leadsfiltres=action.payload   ; },
                setFiltres: (state, action) => { state.filtres=action.payload   ; },
                setChoiceliste: (state, action) => { state.choiceliste=action.payload   ; },
              }
});





  export const formulesSlice = createSlice({
    name: "formules",
    initialState: {corell:'',  datas: [] , formulesaffichees: [], formulesdevis: []  , formule:{} , listecompagnies:[], listecompagniesaffichees:[]},
    reducers: {
      setCorell: (state, action) => {state.corell = action.payload;},
      allFormules: (state, action) => {state.datas = action.payload;},
      defineFormulesDevis: (state, action) => {state.formulesdevis = action.payload;},
      choiceFormule: (state, action) => {state.formule = state.datas.find(x=>x.id===action.payload.id);},
       filterFormules: (state, action) => {
           if(action.payload.type==='all'){
           return { ...state,formulesaffichees :  [...state.datas].sort((a,b)=>a.prix-b.prix)};
           }else if(action.payload.type==='liste'){  
            return  { ...state,formulesaffichees:  state.datas.filter(x=>action.payload.liste.indexOf(x.id)!==-1).sort((a,b)=>a.prix-b.prix)}
           }else if(action.payload.type==='nombre'){
            return  { ...state, formulesaffichees: state.formulesaffichees.filter(x=> x.prix <= action.payload.budget).sort((a,b)=>a.prix-b.prix)}
           }
      },
      defineListeCompagnies: (state, action) => {state.listecompagnies = action.payload;},
      defineListeCompagniesAffichees: (state, action) => {state.listecompagniesaffichees = action.payload;},
    }
  });


  const displaySlice = createSlice({
    name : "display" ,
    initialState : {screeninfos:false, screensearch:false,screensearchgestion:false, screencalendarrdv:false,  screencalendarrelance:false, screenmail:false, screenresiliation : false ,screenvalidcontrat : false , 
                    popupechecs :false, popupqualiflead :false, popupmultitel :false, ecran:'dashboard', message:'messages', citation:"" , disablebuttons : false,screenmodifcontrat: false ,
                    notifnew :{}  ,screengestionnewcontrat:false,screenchangemdp:false  ,screenchangeemail:false  ,screensignature:false, screengagne:false , playanimationlottie:false}, 
    reducers :{modifyDisplayinfos : (state, action)=>{state.screeninfos = action.payload},
                modifyDisplaysearch: (state, action)=>{state.screensearch = action.payload},
                modifyDisplaysearchgestion: (state, action)=>{state.screensearchgestion = action.payload},
                modifyDisplaycalendarrdv: (state, action)=>{state.screencalendarrdv = action.payload},
                modifyDisplaycalendarrelance: (state, action)=>{state.screencalendarrelance = action.payload},
                modifyDisplaymail: (state, action)=>{state.screenmail = action.payload},
                modifyEcran: (state, action)=>{state.ecran= action.payload},
                modifyMessage: (state, action)=>{state.message= action.payload},
                modifyCitation: (state, action)=>{state.citation= action.payload},
                modifyPopupechecs: (state, action)=>{state.popupechecs= action.payload},
                modifyPopupqualiflead: (state, action)=>{state.popupqualiflead= action.payload},
                modifyPopupmultitel: (state, action)=>{state.popupmultitel= action.payload},
                modifyDisplayresiliation: (state, action)=>{state.screenresiliation = action.payload},
                modifyDisplaygestionnewcontrat : (state, action)=>{state.screengestionnewcontrat= action.payload},  
                modifyDisablebuttons: (state, action)=>{state.disablebuttons= action.payload},
                defineNotifnew : (state, action)=>{state.notifnew = action.payload},
                modifyDisplaymodifcontrat : (state, action)=>{state.screenmodifcontrat = action.payload},  
                modifyDisplayscreenchangemdp: (state, action)=>{state.screenchangemdp = action.payload},  
                modifyDisplayscreenchangeemail: (state, action)=>{state.screenchangeemail = action.payload},  
                modifyDisplayscreensignature: (state, action)=>{state.screensignature = action.payload},  
                modifyDisplayscreengagne: (state, action)=>{state.screengagne = action.payload},  
               modifyPlayanimationlottie: (state, action)=>{state.playanimationlottie = action.payload}, 
              }
  });


  const telSlice = createSlice({
    name : "tel" ,
    initialState :{ messagetel: '', etat:'normal'}, 
    reducers :{modifyMessagetel : (state, action)=>{state.messagetel = action.payload},
              modifyEtattel: (state, action)=>{state.etat = action.payload} }
  });


  const gestionSlice = createSlice({
    name : "datagestion" ,
    initialState : {leads:[], datasleads:{} ,clients:[] , allstats :{}  ,  infoscourtier :{}  , dataformcourtier:{} },
    reducers :{  getAllLeads : (state, action)=>{ state.leads=action.payload },
                  getInfoscourtier : (state, action)=>{ state.infoscourtier=action.payload },
                  getDatasLeads : (state, action)=>{ state.datasleads=action.payload },
                  getAllClients : (state, action)=>{ state.clients=action.payload },
                  getAllStats: (state, action)=>{  return  {...state,allstats :{...action.payload}}} ,
                  changeDataformcourtier: (state, action)=>{ state.dataformcourtier=action.payload },
                
                }
});

const requeteSlice = createSlice({
  name: "firstrequest",
  initialState : {calendar: true,gleap: true} ,
  reducers: {defineCalendar : (state, action)=>{ state.calendar=action.payload }, 
              defineGleap : (state, action)=>{ state.gleap=action.payload }}
});

const contratchangeSlice = createSlice({
  name:"contratchange",
  initialState : {value:{}} ,
  reducers: {defineContratchange : (state, action)=>{  state.value=action.payload } }
})


export const {defineCalendar, defineGleap   } = requeteSlice.actions ;
export const {getAllLeads,getDatasLeads,getAllClients,getAllStats,  getInfoscourtier , changeDataformcourtier} = gestionSlice.actions
export const { defineListedocs, deleteChampsLeadactu,modifyChampsLeadactu,   initialiseLeadappelant, initialiseLeadactu, deleteChampsLeadmemo,modifyChampsLeadmemo,initialiseLeadmemo,
               modifyQualiflead  }= leadSlice.actions;
 export const {allLeads,defineCampagnes,  getLeadsMonth, addlead,deletelead,allManagerLeads,getLeadsSearch , setFileattentes , addleadManagerLeads , setLeadsfiltres, setFiltres ,setChoiceliste }= leadsSlice.actions ;
 export const {setCorell,defineNbfavoris,allFormules,defineFormulesDevis, choiceFormule, filterFormules,defineListeCompagnies, defineListeCompagniesAffichees} = formulesSlice.actions;
 export const {allmails, clientmails , deletemail,  } = googleapisSlice.actions;
 export const {allcalendarevents, seteventtochange } = calendarSlice.actions;
 export const { modifyDisplayinfos , modifyDisplaysearch , modifyEcran ,modifyDisplaycalendarrdv ,modifyDisplaycalendarrelance ,modifyPopupmultitel,modifyDisplaymail, modifyMessage,
               modifyCitation, modifyPopupqualiflead,modifyPopupechecs,modifyDisplayresiliation , modifyDisplaysearchgestion ,modifyDisplaymodifcontrat, modifyDisablebuttons , 
               defineNotifnew ,  modifyDisplaygestionnewcontrat ,modifyDisplayscreenchangemdp , modifyDisplayscreensignature ,modifyDisplayscreengagne , modifyPlayanimationlottie ,
               modifyDisplayscreenchangeemail} = displaySlice.actions;
 export const { modifyMessagetel  , modifyEtattel , } = telSlice.actions;
 export const {defineContratchange} = contratchangeSlice.actions;
  


export const store = configureStore({
    reducer :{lead : leadSlice.reducer ,leads : leadsSlice.reducer  , formules : formulesSlice.reducer , 
              googleapis:googleapisSlice.reducer , display : displaySlice.reducer, tel : telSlice.reducer,
              datagestion:gestionSlice.reducer , calendar:calendarSlice.reducer  ,  firstrequest : requeteSlice.reducer,
              contratchange: contratchangeSlice.reducer,
            } ,
    middleware:  (getDefaultMiddleware) =>  getDefaultMiddleware({serializableCheck: false }), 
})