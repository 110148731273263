
import React , {useState , useEffect}  from "react";
import { useDispatch , useSelector} from "react-redux";

import { getallleads } from '../../utils/axios_functions';

import DashboardChoices from "./DashboardChoices";
import DashboardFiltres from "./DashboardFiltres";
import DashBoardLeads from "./DashBoardLeads";
import DashboardTop from "./DashboardTop";
import ListEvents from "../../Components/Calendar/ListEvents";

import './EcranDashboard.scss'
function Dashboard(){
    const dispatch = useDispatch() ;


    const leads= useSelector(state=>state.leads.leads);
 
    useEffect( ()=>{ leads.length<1 && dispatch(getallleads()) ; },[leads.length,dispatch]);

     return(<div className="dashboard" >
                <ListEvents />
                <DashboardTop />
                <DashboardChoices  />
                <DashboardFiltres  />
                <DashBoardLeads   />
            </div>)
}


export default Dashboard ; 