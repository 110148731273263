import { batch } from 'react-redux';
import { store,modifyPopupqualiflead ,modifyQualiflead} from '../state/redux';
import { addEltHistory } from '../state/reactquery';
import { modifyonelead } from './actions_lead';


import { progressions_contact , progressions_devisenvoye, progressions_attentedocuments , progressions_attentesignature } from './listeprogression';

export const modifystatus_fauxnum= ()=>{
    const lead = store.getState().lead.leadactu;
    const fileattentes = store.getState().leads.fileattentes;

    const prochainindex = fileattentes.indexOf(lead.id)  ;
    const prochainid = fileattentes[prochainindex];
   
    // DEV
    addEltHistory('qualiflead', `<div class='horoline1' >Qualification du prospect en <span className=horobold'>Faux numéro</span>  </div>`) ;

    batch( () => {  store.dispatch(modifyQualiflead(true));
                    store.dispatch(modifyPopupqualiflead(false));

                    modifyonelead({'statut_client': 'echec_fauxnum'}) ;
                    });
                    
}


export const modifystatus_nrp= ()=>{
    const heureactu = (new Date()).getHours() ;
    const heurenew = heureactu<16? heureactu+4 :heureactu<18? 9:11 ; 
    let demain =new Date((new Date((new Date().getTime()+24*60*60*1000)).setHours(heurenew+2,0,0,0)));    
    const lead = store.getState().lead.leadactu;

    const newstatutclient = ( progressions_contact.includes(lead.statut_client)?'contact_nrp':progressions_devisenvoye.includes(lead.statut_client)?'devisenvoye_nrp':
                        progressions_attentedocuments.includes(lead.statut_client)?'attente_documents_nrp':progressions_attentesignature.includes(lead.statut_client)?'attente_signature_nrp':'contact_nrp');  

        addEltHistory('qualiflead', `<div class='horoline1' >Qualification du prospect en <span className=horobold'>Ne répond pas</span> </div>`) 
        batch( () => { store.dispatch(modifyQualiflead(true));
                        store.dispatch(modifyPopupqualiflead(false));
                        modifyonelead({ 'date_rdvrelance':demain.toISOString(), 'statut_client':newstatutclient });
                        });            
  
}
