import React, { useEffect, useState  } from 'react';
 

import {convertdateAA } from '../../utils/utils_dates';

import meilleurassu from '../../images/origines/meilleurassu.svg';
import gads from '../../images/origines/gads.svg';
import fb from '../../images/origines/fb.svg';
import inconnu from '../../images/origines/inconnu.png';
import crm from '../../images/origines/crm.png';
 
import './TabloGestion.scss';

const imagesorigine ={"meilleureassurance.com":meilleurassu, "fb": fb, "gads": gads, "crm":crm }
const comparedates=(a,b)=>{ return     (new Date(b.date_reception)).getTime() - (new Date(a.date_reception)).getTime() }

const nblinesaffichees =  Math.trunc((window.innerHeight - 350) / 26 )
                   
const classNames = require('classnames');

function        TabloGestion({id,datas,  clickline}){  

        const [numpage, setNumpage] = useState(1);
        const [affiches, setAffiches] = useState([]);

       const nbpages = Math.trunc((datas.length / nblinesaffichees)) +1;
       const reculer=()=>{ if(numpage!==1){  setNumpage(numpage-1) ;   }}
       const avancer=()=>{ if(numpage<nbpages  ){  setNumpage(numpage+1) ;   }}



       useEffect(()=>{ setAffiches([...datas].sort((a,b)=> comparedates(a,b)).filter((x,i)=> i<=nblinesaffichees*(numpage) && i>=nblinesaffichees*(numpage-1) ))  },[numpage,datas]);

        return(<div  className='tabgestion'  >
                        <table>
                                <thead><tr><td ><span className="coll1">Date de reception</span></td>
                                        {id!=='tabsearch'  &&<td><span className="coll2">Origine</span></td>}
                                        {id!=='tabsearch'  && <td><span className="coll3">Campagne</span></td>}
                                        <td ><span className="coll3">Commercial</span></td> 
                                        <td ><span className="coll4">Email</span></td>
                                        <td ><span className="coll4">Statut</span></td>
                                        {id!=='tabsearch'  &&  <><td><span className="coll6">Telephone 1</span></td><td><span className="coll6">Telephone 2</span></td></>  }
                                        <td ><span className="coll5">Prénom et Nom  
                                                        { nbpages >1 && <div className='navigation' > 
                                                                                <div className='moins'  onClick={reculer}>-</div>
                                                                                <div className='indicpage' > {numpage}/{nbpages}  </div> 
                                                                                <div className='plus'   onClick={avancer}>+</div>
                                                        </div> }
                                        </span>
                                </td>
                                </tr></thead>
                                <tbody className='tbodytabgestion' >{datas.length > 0 &&  affiches.map(x=>(<tr key={x.id} >
                                                       <td ><span className="coll1">{convertdateAA(x.date_reception)}</span> </td>
                                                       {id!=='tabsearch'  && <td  className='caseimg' > <img height="20"  src={imagesorigine[x.origine]||inconnu} alt="imageorigine"   /> </td>}
                                                         {id!=='tabsearch'  &&         <td > {x.campagne}</td> }
                                                          <td > <span  className="coll3" >{x.commercial}</span></td>
                                                        <td   onClick={()=>clickline(x.id)}   > <span  className="coll4" >{x.email}</span></td>
                                                      <td >  <span  className="coll4" >{x.statut_client}</span></td>
                                                  {id!=='tabsearch'  &&  <><td ><span className="coll6">{x.telephone}</span></td><td ><span className="coll6">{x.telephone2}</span></td></>  }
                                                  <td  className="coll5" >{x.nom} {x.prenom}</td> 
                                </tr>))}
                                </tbody>
                        </table>
                </div>)
}

export default TabloGestion
