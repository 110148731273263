import React  , {useState} from "react";
import axios from "axios";
import { useDispatch  , useSelector} from 'react-redux';

import {useUser } from '../../state/reactquery';
import { modifyDisplaymodifcontrat , modifyChampsLeadactu , getAllClients } from '../../state/redux';
import { queryClient } from '../../state/queryClient';
import { dateconvert , convertdatesignature} from "../../utils/utils_dates";
import ButtonSimple from "../Buttons/ButtonSimple";
import FormulaireContrat from "./FormulaireContrat";
import croixclose from '../../images/croixclose.svg';

import './ModifContrat.scss' ;


function ModifContrat(){
   const dispatch = useDispatch() ;
   const {leadactu } =  useSelector(state=>state.lead);
   const contratchange = useSelector(state=>state.contratchange.value)  ;
   const {data: user} = useUser(localStorage.getItem('token'))  ;
  
   const clients = useSelector(state=>state.datagestion.clients)

   const [datacontrat, setDatacontrat] = useState({...contratchange, date_effet: dateconvert(contratchange.date_effet), date_signature : convertdatesignature(contratchange.date_signature) , date_fincontrat : convertdatesignature(contratchange.date_fincontrat)  });
              console.log(contratchange)    
   const modifiercontrat = () =>{axios.post(  `/contrats/updatecontrat/`,{...datacontrat, date_effet:(new Date(datacontrat.date_effet)).toLocaleDateString('fr-FR')  }, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
                                if(datacontrat.etat_contrat !== contratchange.etat_contrat){ 
                                            const newlead = {...leadactu,  statut_client: 'gagne_'+datacontrat.etat_contrat ,  date_effet:(new Date(datacontrat.date_effet)).toLocaleDateString('fr-FR')  } ;
                                            const otherleads = clients.filter(x=>x.id!==leadactu.id) ;
                                            dispatch(modifyChampsLeadactu({statut_client:'gagne_'+datacontrat.etat_contrat}));
                                            axios.post(  `/gestion/alertcontratchangestatut/`,{'lead':newlead  ,'gestionname': user['nom'] }, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
                                            axios.post(  `/clients/updateclients/`,{...newlead}, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
                                            dispatch(getAllClients([...otherleads, newlead]));
                                     }
                                queryClient.invalidateQueries({ queryKey: ['contrats',leadactu?.email,leadactu?.courtier]}) ;
                                dispatch(modifyDisplaymodifcontrat(false))}



    return(<div className="container_modifcontrat"  >
                <div  className="modifcontrat" >
                    <div className="modifcontratclose" ><img src={croixclose}  alt="imgclose" onClick={()=>{dispatch(modifyDisplaymodifcontrat(false))}}  />       </div>
                    <div className="modifcontrat_titre" >Modification de contrat</div>
                    <FormulaireContrat datacontrat={datacontrat} setDatacontrat={setDatacontrat} /> 
                    <br />
                    <ButtonSimple  texte="Enregistrer"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'   onclick={modifiercontrat}    disable={false}  />
                </div>
            </div>)

}


export default ModifContrat