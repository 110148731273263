
import logoHenner from "../images/images_logos/logoHenner.png";
import logoWazari from "../images/images_logos/logoWazari.jpg";
import logoASAFAFPS from "../images/images_logos/logoASAFAFPS.png";
import logoSollyAzar from "../images/images_logos/logoSollyAzar.png";
import logoMalakoff from "../images/images_logos/logoMalakoff.png";
import logoFFA from "../images/images_logos/logoFFA.png";
import logoCegema from "../images/images_logos/logoCegema.png";
import logoNeoliane from "../images/images_logos/logoNeoliane.webp";
import logoAlptis from "../images/images_logos/logoAlptis.png";
import logoFMA from "../images/images_logos/logoFMA.png";
import logoApril from "../images/images_logos/logoApril.png";

export function  logocompagnie(compagnie){

   return compagnie==="SOLLYAZAR"? logoSollyAzar:
            compagnie==="WAZARI"? logoWazari:
            compagnie==="ASAF-AFPS"? logoASAFAFPS:
            compagnie==="HENNER"? logoHenner:
            compagnie==="FFA"? logoFFA:
            compagnie==="FMA"? logoFMA:
            compagnie==="CEGEMA"? logoCegema:
            compagnie==="NÉOLIANE"? logoNeoliane:
            compagnie==="ALPTIS"? logoAlptis:
            compagnie==="MALAKOFF HUMANIS" ?logoMalakoff :
            compagnie==="APRIL" ?logoApril :
            null
}
         