import axios from 'axios';
import { batch } from 'react-redux';
import { Device } from '@twilio/voice-sdk';

import { queryClient } from '../state/queryClient';
import { addEltHistory  , addnotiffromfront} from '../state/reactquery';
import { store  ,modifyMessagetel  , modifyEtattel , modifyPopupqualiflead, modifyQualiflead,
         initialiseLeadactu,  initialiseLeadappelant , modifyEcran ,allFormules} from '../state/redux';

import { getallformules } from './calculstarifs';
import { modifyonelead } from './actions_lead';
import { progressions_gagne } from './listeprogression';



let tel= {device : null, connexion: null  , token:'' };


export const telephone = {
      getTelephone : ()=> tel, 
      setTelephone : (t)=>{tel = t}
  }

function convertteltotwilio(num){ const numero = num?.replace(/([ .])/g,"");
                                  return  numero[0]!=='+' ? '+33'+ numero.substring(1).toLowerCase(): numero;
}

function updatestatustel(etat){
  const user= queryClient.getQueryData(['user',localStorage.getItem('token')])   ;                           
  axios.post(`/twilio/updatesatusphone/`,{'telephone':user['telephone'], 'status' : etat  }, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
}



const handleincomingaccept = (conn)=>{   console.log('Connexion acceptee !!!!') ;
                                          telephone.setTelephone({...telephone.getTelephone(), connexion : conn}  ) 
                                        }

const handleconnexion = (conn)=>{
        conn &&   conn.on('accept', function(conn){ handleincomingaccept(conn)}); 
        conn &&   conn.on('cancel', function(conn){ console.log('CANCEL');  store.dispatch(modifyEtattel('normal')) ; }); // APPEL EXT ABANDON EXT -   
        conn &&  conn.on('disconnect', function(conn){ console.log('DISCONNECT',conn._wasConnected);
                            const event = new Event('postappel');window.dispatchEvent(event);         
                   raccrocher() ;}); // APELL EXT    ABANDON INT ET  APPEL IMT 
        conn &&  conn.on('reject', function(conn){ console.log('REJECT')}); 
        conn &&  conn.on('error', (error) => { console.log('An error has occurred: ', error);});
        conn &&  conn.on('transportClose', (conn) => { console.log('transportClose ', conn);});
        conn &&  conn.on('ringing', function(conn) { console.log("Ringing...", conn);})
}
// Incoming... false
// DISCONNECT true
// CANCEL undefined
export async function initialiseTelephone(){

  const user= queryClient.getQueryData(['user',localStorage.getItem('token')])   ;
  if(!localStorage.getItem('teltwilio') || !user?.['telephone']){ console.log('PASDETEL')  ;return }
    console.log('initialiseTelephone');
  updatestatustel('idle') ; 
     const reqtoken =  await axios.post(`/twilio/token`,{numtel : localStorage.getItem('teltwilio')},{headers:{'Accept':'application/json','Content-Type':'application/json'}} );
   
      
  const  device = new Device(reqtoken.data.token, {codecPreferences: ["opus", "pcmu"],fakeLocalDTMF: true, enableRingingState: true,enableIceRestart:true, disableAudioContextSounds: true });

    telephone.setTelephone({device : device, connexion: null  , token: reqtoken.data.token});

    device.register(); 

    device.on('registered', function () {console.log('registered!!'); store.dispatch(modifyEtattel('normal'))});
    device.on('unregistered', function () {console.log('unregistered mon coco');  initialiseTelephone();  store.dispatch(modifyEtattel('error'))  });
    device.on("error", function (error) {console.log('errortelephone-reinitialisation',error); initialiseTelephone();  store.dispatch(modifyEtattel('error'))    });
    device.on("connect", function (conn) {console.log("connect", conn);     handleconnexion(conn);});
    device.on('ringing', function(conn) { console.log("Ringing...", conn);  store.dispatch(modifyMessagetel(conn.parameters.From));
                                                                            store.dispatch(modifyEtattel('sonnerie')) ;});
    device.on("disconnect", function (conn) { console.log("disconnect"); initialiseTelephone()});
    device.on('incoming', function(conn) {   console.log("Incoming...", telephone.getTelephone()); 
                                            handleconnexion(conn);
                                            telephone.setTelephone({...telephone.getTelephone(), connexion: conn} ) ; 
                                            findclientfromtel(conn.parameters.From) ;
                                            store.dispatch(modifyMessagetel(conn.parameters.From));
                                            store.dispatch(modifyEtattel('sonnerie')) ;});

                                          
                                   
  }


export const phone = async(numtel) =>{ console.log('PHONE') ;
  addEltHistory('appelsortant',  `<div class='horoline1' >Appel sortant</div>` )
                                          const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
                                        const conn = await  telephone.getTelephone().device.connect({params: {From :  user['telephone'] ,To:convertteltotwilio(numtel)  }}  ) ;
                                        handleconnexion(conn);
                                         updatestatustel('busy') ; 
                                        const nb = store.getState().lead.leadactu.nbappels || 0 ;
                                         batch( () => {  store.dispatch(modifyEtattel('appel'));  //  progressions_gagne
                                                         !progressions_gagne.includes(store.getState().lead.leadactu.statut_client) &&   store.dispatch(modifyQualiflead(false));
                                                          modifyonelead({'nbappels': nb+1}) ;
                                   }) ;}


export const raccrocher = ()=>{console.log('RACCROCHER', telephone ,  telephone?.getTelephone()?.connexion ); 
                                updatestatustel('idle') ; 
                                telephone.getTelephone().device    &&  telephone.getTelephone().device.disconnectAll();
                                telephone.getTelephone().connexion &&  telephone.getTelephone().connexion.reject();
                                telephone.getTelephone().connexion &&  telephone.getTelephone().connexion.ignore();
                               batch( () => {
                                    store.dispatch(modifyMessagetel(''));
                                    store.dispatch(modifyEtattel('normal')) ;
                                    store.getState().lead.qualiflead===false  && store.dispatch(modifyPopupqualiflead(true));
                                    store.dispatch(initialiseLeadappelant({}))
                                  })
                               }

    export const rejectcall= ()=>{  console.log('rejectcall',telephone,telephone.getTelephone(),telephone.getTelephone().connexion);

    telephone.getTelephone().connexion && telephone.getTelephone().connexion.reject();
    telephone.getTelephone().connexion && telephone.getTelephone().connexion.ignore();
    }


export const deccrocher = ()=>{ store.dispatch(modifyQualiflead((store.getState().lead.leadappelant.id && store.getState().lead.leadappelant!=='gagne')?false:true));  
                               store.dispatch(allFormules([])); 
                                telephone.getTelephone().connexion.accept();
                                updatestatustel('busy') ; 
                                batch( () => {store.dispatch(modifyEtattel('appel'));  
                                              store.getState().lead.leadappelant.id && store.dispatch(initialiseLeadactu( store.getState().lead.leadappelant));  
                                              store.getState().lead.leadappelant.id && store.dispatch(modifyEcran('ficheclient'));  });
                                setTimeout(()=>{ store.getState().lead.leadappelant.id &&  store.dispatch(getallformules());}, 1000) ;
                                }

const listetelformats = (num)=>{
    var num1 = num.replace("+33","0");
    var num2 = num1.substr(0,2)+'.'+num1.substr(2,4)+'.'+num1.substr(4,2)+'.'+num1.substr(6,2)+'.'+num1.substr(8,2)
    return [num,num1,num2]
}



function cleanleadnull(lead){
    const leed =   {...lead};
    Object.keys(leed).map(key=>(leed[key]==="" ||leed[key]===null || leed[key]===undefined )&& delete leed[key]);
    return  {...leed}
  }

const findclientfromtel = async(numtel) =>{
    let leadappelentrant ;
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
      const response = await axios.post(  `/clients/searchclientbytel/`,{numtel, commercial:user['nom']},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
            if(response.data.id===undefined){ leadappelentrant={} }
            else{  leadappelentrant=response.data}

    store.dispatch(initialiseLeadappelant(cleanleadnull(leadappelentrant)));

    if(leadappelentrant.id){
      addnotiffromfront({id_lead:leadappelentrant.id,notification: `Appel entrant <span>${store.getState().tel.messagetel}</span><span> ${leadappelentrant.nom} </span><span>${leadappelentrant.prenom}</span>` ,postmail:user?.postmail,type_notif:'appelentrant'});
     }else{addnotiffromfront({notification:`Appel entrant <span>${store.getState().tel.messagetel}</span>`,postmail:user?.postmail,type_notif:'appelentrant'});} 
}


                            