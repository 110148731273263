import { store , seteventtochange} from '../state/redux';

import { addEltHistory } from '../state/reactquery';
import { modifyoneleadechec  } from './actions_lead';
import {tenmonthafter , threeyearsafter } from './utils_dates';
import { deleterdvgooglecalendar , rdvcalendar} from './actions_calendar';
import {  progressions_devisenvoye , progressions_attentedocuments , progressions_attentesignature, progressions_gagne , listeechecs} from './listeprogression';



const listetextesechecs = ["Curieux","Trop cher","Pas de produit adapté","Signé à la concurrence","Ne répond plus","Mutuelle entreprise","CMU"]
// const listeechecs =["echec_curieux","echec_tropcher","echec_pasproduitadapte","echec_signeconcurrence","echec_plusdereponse","echec_mutuelleentreprise","echec_cmu"]


const jsontochangelead =(newechec,lead)=>{
    let myjson={};

    const newstatut =  progressions_attentesignature.includes(lead.statut_client)?'attente_signature_'+newechec:
                        progressions_gagne.includes(lead.statut_client)?'gagne_'+newechec:
                        progressions_devisenvoye.includes(lead.statut_client)?'devisenvoye_'+newechec:
                        progressions_attentedocuments.includes(lead.statut_client)?'attentedocs_'+newechec:'contact_'+newechec ;
                        
    switch(newechec){
        case 'echec_curieux' : myjson ={'statut_client': newechec ,'hot': 0 , 'date_rdvrelance':tenmonthafter(lead.date_reception) , 'recyclage':1  } ;break ;
        case 'echec_tropcher' : myjson ={'statut_client': newechec ,'hot': 0 , 'date_rdvrelance':tenmonthafter(lead.date_reception), 'recyclage':1 }  ;break ;
        case 'echec_pasproduitadapte' : myjson ={'statut_client': newechec ,'hot': 0 , 'date_rdvrelance':tenmonthafter(lead.date_reception), 'recyclage':1 } ;break ;
        case 'echec_signeconcurrence' : myjson ={'statut_client': newechec ,'hot': 0 , 'date_rdvrelance':tenmonthafter(lead.date_reception), 'recyclage':1 }  ;break ;
        case 'echec_plusdereponse' : myjson ={'statut_client': newechec ,'hot': 0 , 'date_rdvrelance':tenmonthafter(lead.date_reception), 'recyclage':1 } ;break ;
        case 'echec_mutuelleentreprise' : myjson ={'statut_client': newechec ,'hot': 0 , 'recyclage':0 }  ;break ;
        case 'echec_cmu' : myjson ={'statut_client': newechec ,'hot': 0 , 'recyclage':0 }  ;break ;
        case 'echec_autre' : myjson ={'statut_client': newechec ,'hot': 0 , 'date_rdvrelance':tenmonthafter(lead.date_reception), 'recyclage':1 } ;break ;
        default :  myjson ={'hot': 0 , 'date_rdvrelance':tenmonthafter(lead.date_reception), 'recyclage':1 } ;break ;
    }
    myjson['statut_client']=newstatut ; 

    if(newechec==='echec_cmu'||newechec==='echec_mutuelleentreprise'){ delete myjson['date_rdvrelance'] };
    return myjson 
}


export const modifyechec = (newechec)=>{
 
    const lead = store.getState().lead.leadactu;
    //if(lead.statut_client==='gagne')  return ; 

        // DEV
        addEltHistory('qualiflead', `<div class='horoline1' >Qualification du prospect en <span className=horobold'>Echec ${listeechecs[newechec]} </span>  </div>`)  

    modifyoneleadechec(lead,{...jsontochangelead(newechec,lead)});

    // GESTION CALENDRIER
    
    let testregexid =  new RegExp(lead.id,'g');
    let listcalendarevents =  store.getState().calendar.listcalendarevents;
    let existerdv = listcalendarevents.filter(y=>y.description).filter(y=>y.description.match(testregexid));

    if(newechec==='echec_cmu'||newechec==='echec_mutuelleentreprise'){
        existerdv.length > 0 && deleterdvgooglecalendar(existerdv[0]['id']);
        store.dispatch(seteventtochange({})); 
    }else{ rdvcalendar(tenmonthafter(lead.date_lastmodif),'Relance Apres Echec'+newechec) }
}

export const rdvgagne = (id_client)=>{
    const leads = store.getState().leads.leads;
    const existlead = leads.filter(x=>x.id===id_client);

    if(existlead.length < 0){return }
    else{let lead= existlead[0];rdvcalendar(threeyearsafter(lead.date_lastmodif),'Relance Apres Contrat Signe')}

}

