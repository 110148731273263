
import {  googleLogout } from '@react-oauth/google';
import axios from 'axios';

import { modifyUser } from '../state/reactquery';



export const handlelogout =()=>{googleLogout();  modifyUser({'authenticate' :  false});}

function saveavatartolocalstorage(url) {
  if( window.location.hostname==='localhost'){return }
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url); xhr.responseType = 'blob';  xhr.send();
    xhr.onload = function() {
      const reader = new FileReader();  reader.readAsDataURL(xhr.response);
      reader.onloadend = function() { localStorage.setItem('imgavatar',reader.result) }
    };
}

export const getrefreshtokenandsave = async (response) => {
 
    const  refreshtokenreq = await  axios.post(  `/googleapis/getrefreshtoken`,{code:response.code},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
    await localStorage.setItem('token',refreshtokenreq.data.accessToken)  ; 
    refreshtokenreq.data.accessToken &&  modifyUser({'authenticate' :  true}); 
    saveavatartolocalstorage(refreshtokenreq.data.urlavatar);
  
}


export const loginmailpass =  async (userdata) => {
  const  refreshtokenreq = await  axios.post(  `/users/login`,{...userdata},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
  refreshtokenreq.data?.accessToken &&   modifyUser({'authenticate' :  true}); 
  localStorage.setItem('token',refreshtokenreq.data.accessToken)
  return refreshtokenreq.data.error ? false : true
}
   


