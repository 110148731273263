import React , {useState}  from "react";
import ClientEmail from "./ClientEmail";
import SendMail from "./SendMail";



import './PostMail.scss';
function Postmail(){
    const[ show, setShow] = useState(false)
return(<div  className="emailing">
        <ClientEmail  setShow={setShow}  />
     {  show &&   <div id="containersendemail" ><SendMail  setShow={setShow}  /> </div> }
</div>)
}


export default Postmail

