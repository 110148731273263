import React from "react";

import ButtonSimple from "../Buttons/ButtonSimple";
import './PopupDataSaved.scss'



function PopupDataSaved({courtier,mail}){
    return(<div id="popupdatasaved"  className="popupdatasaved" >
              <div className="popupdatasaved_titre" >Le cabinet {courtier} a bien été crée !</div>
              <div className="popupdatasaved_texte" >L’administrateur du cabinet NRG va recevoir ses coordonnées de connexion sur son email {mail} </div>


    </div>)

}



export default PopupDataSaved


