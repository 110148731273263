import React  from "react";

import { champsgestion } from "../../utils/utils_courtier";

import TelephonieLine from './TelephonieLine'
import TelephonieExterieur from "./TelephonieExterieur";


import './Telephonie.scss';

function Telephonie(){

    return(<> <div className="telephonietop" >
                      <h1>Gestion du standard téléphonique et du répondeur interactif</h1>
               </div>
               <section>
                     <h2>Aiguillage des besoins formulés par le client au téléphone</h2>
                     <p>Assignez les besoins au bon utilisateur</p>
                     <div className="tabletelephonie" >
                        <div ><div>Requête du client</div><div>Utilisateur</div></div>
                        {[...Object.keys(champsgestion)].map(key=><TelephonieLine key={key} titre={champsgestion[key]}      champ={key}     />)}
                     </div>
               </section>
               <TelephonieExterieur   />
    </>)
}



export default Telephonie