import React from "react";


import GroupBtnActionTel from "./GroupBtnActionTel";
import iconfinappel  from '../../images/iconfinappel.svg';

import './AfterCall.scss';
function AfterCall(){

    return(<div className="contentaftercall" > 
                <div className="aftercall" > 
                              <div><img  src={iconfinappel}  width="48"  height="48"   alt="iconechecgris" /> </div>  
                                <div className="aftercall_titre1" >Comment ça s’est passé ? </div>
                                <div className="aftercall_titre2" >Veuillez qualifier votre conversation</div>
                                <GroupBtnActionTel   />
                </div>   
            </div>   )
}



export default AfterCall