import React from 'react';
import { useSelector , useDispatch} from 'react-redux';

import { initialiseLeadmemo , modifyDisplayinfos} from '../../state/redux';
import ButtonSimple from "../../Components/Buttons/ButtonSimple";
import { listejsonall } from '../../utils/leadlistechamps';
import { convertdateXX } from '../../utils/utils_dates';
import './Client.scss'


function Client(){
    const dispatch = useDispatch()
    const lead = useSelector(state=>state.lead.leadactu)
   
   const modifierclient=()=>{dispatch(initialiseLeadmemo(lead)); dispatch(modifyDisplayinfos(true))} 


    const person= (id)=>{
        return (<div key={id} className="client">
                    <div className="client_titre">
                        <div className="client_titre_left">{listejsonall[id]['titre1']}</div>
                        <div className="client_titre_right">{id==="souscripteur" && <span className='datereception'>{lead.date_reception && convertdateXX(lead.date_reception)}</span>}</div>
                    </div>
                <table className='tableclients'><tbody><tr><td className='nomchamp'>Titre</td><td className="valchamp" >{lead[listejsonall[id]['civilite']]}</td></tr>
                 {lead[listejsonall[id]['nom']] &&   <tr><td className='nomchamp'>Nom</td><td className="valchamp tdemail" >{lead[listejsonall[id]['nom']]}<div onClick={()=>{navigator.clipboard.writeText(lead[listejsonall[id]['nom']]);}}  ></div></td></tr>}
                  {lead[listejsonall[id]['nom']] &&   <tr><td className='nomchamp'>Prénom</td><td className="valchamp tdemail" >{lead[listejsonall[id]['prenom']]}<div onClick={()=>{navigator.clipboard.writeText(lead[listejsonall[id]['prenom']]);}}  ></div></td></tr>}
                   {(id==="souscripteur" && lead.adresse)&& <tr><td className='nomchamp'>Adresse</td><td className="valchamp tdemail" >{lead.adresse && lead.adresse.replace(lead.code_postal,'').replace(lead.ville,'').replaceAll(',','').replace("France",'')}<div onClick={()=>{navigator.clipboard.writeText(lead.adresse);}}  ></div></td></tr>}
                   { (id==="souscripteur" && !lead.adresse && lead.num_departement) && <tr><td className='nomchamp'>Departement</td><td className="valchamp" >{lead.num_departement}</td></tr>}
                   {id==="souscripteur" && <tr><td  style={{height:'24px'}} className='nomchampville'></td><td className="valchamp" >{lead.code_postal}  {lead.ville}</td></tr>}
                   { id==="souscripteur" &&   <tr><td className='nomchamp'>Email</td><td className="valchamp tdemail"  ><span className='positionrelative' >{lead.email} </span> <div onClick={()=>{navigator.clipboard.writeText(lead.email);}}  ></div> </td></tr>}
                   { id==="souscripteur" &&   <tr><td className='nomchamp'>Telephone 1</td><td className="valchamp tdemail" >{lead.telephone}<div onClick={()=>{navigator.clipboard.writeText(lead.telephone);}}  ></div></td></tr>}
                   { (id==="souscripteur" && lead.telephone2  ) && <tr><td className='nomchamp'>Telephone 2</td><td className="valchamp" >{lead.telephone2}</td></tr>}
                   { (id==="souscripteur" && lead.telephone3  ) && <tr><td className='nomchamp'>Telephone 3</td><td className="valchamp" >{lead.telephone3}</td></tr>}
                   {( id==="souscripteur" && lead.iban1) &&   <tr><td className='nomchamp'>IBAN 1</td><td className="valchamp tdemail"  ><span className='positionrelative' >{lead.iban1.length>24? lead.iban1.substring(0,24)+'...':lead.iban1  }</span><div onClick={()=>{navigator.clipboard.writeText(lead.iban1);}}  ></div></td></tr>}
                   {( id==="souscripteur" && lead.iban2) &&   <tr><td className='nomchamp'>IBAN 2</td><td className="valchamp tdemail"   >{lead.iban2?(lead.iban2.length>24? lead.iban2.substring(0,24)+'...':lead.iban2) :''}<div onClick={()=>{navigator.clipboard.writeText(lead.iban2);}}  ></div></td></tr>}
                   {( id==="souscripteur" && lead.bic1) &&   <tr><td className='nomchamp'>BIC 1</td><td className="valchamp tdemail"  ><span className='positionrelative' >{lead.bic1.length>24? lead.bic1.substring(0,24)+'...':lead.bic1  }</span><div onClick={()=>{navigator.clipboard.writeText(lead.bic1);}}  ></div></td></tr>}
                   {( id==="souscripteur" && lead.bic2) &&   <tr><td className='nomchamp'>BIC 2</td><td className="valchamp tdemail"  ><span className='positionrelative' >{lead.bic2.length>24? lead.bic2.substring(0,24)+'...':lead.bic2  }</span><div onClick={()=>{navigator.clipboard.writeText(lead.bic2);}}  ></div></td></tr>}
                   {lead[listejsonall[id]['date_naissance']] && <tr><td className='nomchamp'>Date naiss. </td><td className="valchamp tdemail" >{lead[listejsonall[id]['date_naissance']]}<div onClick={()=>{navigator.clipboard.writeText(lead[listejsonall[id]['date_naissance']]);}}  ></div></td></tr>}
                   {lead[listejsonall[id]['regime']]&& <tr><td className='nomchamp'>Regime</td><td className="valchamp" >{lead[listejsonall[id]['regime']]}</td></tr>}
                   {lead[listejsonall[id]['numsecu']] && <tr><td className='nomchamp'>Nº de SS</td><td className="valchamp tdemail" >{lead[listejsonall[id]['numsecu']]}<div onClick={()=>{navigator.clipboard.writeText(lead[listejsonall[id]['numsecu']]);}}  ></div></td></tr>}
                   {lead[listejsonall[id]['organisme']] && <tr><td className='nomchamp'>Organisme</td><td className="valchamp tdemail" >{lead[listejsonall[id]['organisme']]}<div onClick={()=>{navigator.clipboard.writeText(lead[listejsonall[id]['organisme']]);}}  ></div></td></tr>}
                    </tbody></table>
                    
            </div> )}


    return(<div className="clients">
                
                    <div className="clients_liste">
                        {person("souscripteur")}
                        {["conjoint","enfant1","enfant2","enfant3","enfant4","enfant5"].map(x=>(lead[listejsonall[x]['date_naissance']]!==undefined && lead[listejsonall[x]['date_naissance']]!==null && lead[listejsonall[x]['date_naissance']]!=="") && person(x))} 
                    </div>

                    <div className="clients_bottom"> <ButtonSimple  texte="Modifier"   color='bouton_color_orange' size='bouton_size_normal'  onclick={modifierclient}   /> </div>
             
                    
            </div>)

}


export default Client