import React ,  {useEffect}  from "react";
import { useSelector } from 'react-redux';

import { useListmails , useUser } from "../../state/reactquery";

import ButtonImgLeft from "../Buttons/ButtonImgLeft";

import './ClientEmail.scss';
const classNames = require('classnames');

function ClientEmail({setShow}) {
  const {data: user} = useUser(localStorage.getItem('token'))  ;
 
  const leadactu  = useSelector(state=>state.lead.leadactu);

  const {data: messages} = useListmails(user?.postmail,leadactu?.email) ; 

  useEffect(()=>{
        document.querySelector(".divmessages").scroll({ top: document.getElementById("bottommessages").offsetTop });
  },[document.getElementById("bottommessages"),messages])
  

  const infotypemessage = (messageRecordType) =>{
    return  (messageRecordType==='Open' ||messageRecordType==='Click' )? 'Lu': messageRecordType==='Delivery'? "Reçu":
            (messageRecordType==='Bounce' ||messageRecordType==='SpamComplaint' )? "Rejeté":"Envoyé" ;   }


  const messageitem = (message) => (
          <div key={message.id} className={classNames("message",{ "messageto":message.emailFrom === user?.['postmail']},{"messagefrom":message.emailFrom!==user?.['postmail']})} >
            <div className="message_top" >
              <div className="message_top_left">{message.emailFrom === user?.['postmail'] ? "Vous" : leadactu.prenom + ' ' + leadactu.nom}</div>
              <div className="message_top_right" >
                <div> {(new Date(message.created_at)).toLocaleString("fr-FR")} </div>
                {message.emailFrom === user?.['postmail'] && <div className={message.RecordType || 'Send'} > {infotypemessage(message.RecordType)} </div>}
              </div>
            </div>
            <div className={classNames("message_content", { "messageto": message.emailFrom === user?.['postmail'] }, { "messagefrom": message.emailFrom !== user?.['postmail'] })}>
              <div className="messagesub"  >{message.Subject} </div>
              <div className="messagecon" dangerouslySetInnerHTML={{ __html: message.TextBody}}  />
              {message?.listattachements?.length > 0 && <div className="message_attachments" >
                {message?.listattachements?.map((link, index) => (<a key={index} className="messageattachementlink" href={link} target="blank" >{link?.split('/')?.reverse()[0]}</a>))}
              </div>}
            </div>
          </div> )


  return (<div className='divemails' >
              <div className="divmessages">
                  <div id="topmessages"></div>
                  {messages?.length >0 && messages.sort((a,b) => new Date(a.created_at) - new Date(b.created_at)).map(x=> messageitem(x))} 
                  <div id="bottommessages"></div>  
              </div>
              <div className="divmessagesfooter">
                    <ButtonImgLeft texte={messages?.length >0 ?"Répondre":"Ecrire un message"}  color='bouton_color_orange' size='bouton_size_normal' image='imagemail' onclick={()=>{setShow(true)}}   /> 
              </div>         
          </div>);
}

export default ClientEmail;