import React , {useState, useEffect, useMemo} from "react";
import axios from "axios";
import { useSelector , useDispatch } from 'react-redux'; 

import { modifyChampsLeadmemo, deleteChampsLeadmemo , modifyDisplayinfos , modifyEcran } from '../../state/redux';

import { listejsonall} from "../../utils/leadlistechamps";
import { recordclients  } from "../../utils/axios_functions";
import { getallformules } from '../../utils/calculstarifs';

import {modifyleadformulaire} from "../../utils/actions_lead";
import { addEltHistory  } from "../../state/reactquery";

import ButtonSimple from "../Buttons/ButtonSimple";
import Infos from './Infos';
import InfosSouscripteur from "./InfosSouscripteur";
// import { useAddclient } from "../../state/reactquery";
import croixclose from '../../images/croixclose.svg'

import './Formulaire.scss'

const classNames = require('classnames'); 
function Formulaire(){
    const dispatch = useDispatch()
    const ficheclient = useSelector(state=>state.lead.leadactu)
    const memofiche = useSelector(state=>state.lead.leadmemo)

    const [nbadultes, setNbadultes] = useState(memofiche.date_naissance_conjoint?2:1);
    const nbenfs = useMemo(() => {return ['regime_enf1','regime_enf2','regime_enf3','regime_enf4','regime_enf5'].map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length}, [memofiche]);
    const [nbenfants, setNbenfants] = useState(nbenfs);
    
    // const addClient = useAddclient().mutate
    useEffect(()=>{
        memofiche.date_naissance_conjoint && setNbadultes(2);
       const nbenfs = ['regime_enf1','regime_enf2','regime_enf3','regime_enf4','regime_enf5'].map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length;
       setNbenfants(nbenfs)
    },[memofiche])

    const changeNbadultes = (e)=>{  e.target.id==='ad1'? setNbadultes(1): setNbadultes(2) ; 
                                    e.target.id==='ad1'&& dispatch(deleteChampsLeadmemo(Object.values(listejsonall["conjoint"])))  ;
                                    e.target.id==='ad2'&& dispatch(modifyChampsLeadmemo({"regime_conjoint":memofiche['regime_conjoint'] || 'general'}));} 

    const changeNbenfants =(e)=>{const nb = parseInt((e.target.id).replace('enf','')); setNbenfants(nb); 
             ['enfant1','enfant2','enfant3','enfant4','enfant5'].map((x,i)=>i<nb && dispatch(modifyChampsLeadmemo({[listejsonall[x]['regime']]:memofiche[listejsonall[x]['regime']] || 'general'})) ) ;  //:dispatch(deleteChampsLeadmemo(listejsonallcoassures[x]))
             ['enfant1','enfant2','enfant3','enfant4','enfant5'].map((x,i)=>i>=nb && dispatch(deleteChampsLeadmemo(Object.values(listejsonall[x]))));
            }
  
      
   const utilrecalculformules = ()=>{

    let champsverif = ["num_departement","date_effet"] ;const copymemofiche = {...memofiche} ;  const copyficheclient = {...ficheclient} ;
    ["souscripteur","conjoint",'enfant1','enfant2','enfant3','enfant4','enfant5'].forEach(x=>{champsverif.push(listejsonall[x]['date_naissance']) ; champsverif.push(listejsonall[x]['regime']) ;   });
    
    Object.keys(copymemofiche).forEach(x=>!champsverif.includes(x) && delete copymemofiche[x]   );
    Object.keys(copyficheclient).forEach(x=>!champsverif.includes(x) && delete copyficheclient[x]   );

    return  JSON.stringify(copymemofiche)!==JSON.stringify(copyficheclient)
   }    

   const submit = async()=>{   closemodal() ;  dispatch(modifyEcran('ficheclient'));   

                             if(memofiche.id===undefined){ console.log('nouvoclient');
                                          
                                                 let newclient =   await dispatch(recordclients({...memofiche,'need_hospi':0,'need_orthodontie':0,'need_protheses':0,'need_implant':0,'need_lentilles':0,'need_lunettes':0,'need_specialiste':0,'need_prevention':0,'statut_client':'nontraite'  }));
                                                await modifyleadformulaire(newclient)   ;
                                               await dispatch(getallformules());
                                               addEltHistory('enterlead',`<div class='horoline1' >Création du lead sur le CRM.</div>`)
                                             } 
                             else{// console.log('updateclient');
                                await modifyleadformulaire({...memofiche});utilrecalculformules() && dispatch(getallformules()) ;
                                     
                                       }
        }

   const closemodal =()=>{dispatch(modifyDisplayinfos(false))    }

   const nbages = Object.values(listejsonall).map(x=>x.date_naissance).map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length ;
    const disable = nbages!== nbadultes + nbenfants || memofiche.num_departement === undefined || memofiche.email === undefined || memofiche.date_effet=== undefined || memofiche.telephone=== undefined ;
// console.log(nbages, nbadultes , nbenfants,memofiche.num_departement , memofiche.email ,memofiche.date_effet, memofiche.telephone )  
   return(    <div className="container_formulaire"> 
                        <div className="formulaire"> 
                            <div className="formulaire_top">
                                {ficheclient.date_naissance?"Modifier le prospect":"Ajouter nouveau prospect" }
                                <img src={croixclose}  alt="imgclose" onClick={closemodal}  />
                            </div>
                            <div className="formulaire_content">
                                  <Infos id="souscripteur" nbenfants={nbenfants}   setNbenfants={null}    />
                                 <InfosSouscripteur   />
                                         {['conjoint','enfant1','enfant2','enfant3','enfant4','enfant5'].map(id=>(memofiche[listejsonall[id]['regime']]!==undefined && memofiche[listejsonall[id]['regime']]!==null  )&& <Infos id={id}  key={id} nbenfants={nbenfants} setNbenfants={setNbenfants}  />)}
                            </div>
                            <div className="formulaire_bottom">
                               <div><span>Adultes</span>
                                {["ad1","ad2"].map((x,index)=>(<button id={x}  key={x}  className={classNames("btnnb", {"select": nbadultes===index+1}, {"noselect": nbadultes!==index+1} )} onClick={changeNbadultes}>{index+1}</button>))}
                                <span>Enfants</span>
                                {["enf0","enf1","enf2","enf3","enf4","enf5"].map((x,index)=>(<button id={x} key={x}  className={classNames("btnnb", {"select": nbenfants===index}, {"noselect": nbenfants!==index} )} onClick={changeNbenfants}>{index}</button>))}
                                </div> 
                                <ButtonSimple  texte="Valider"   color={disable?'bouton_color_disable':'bouton_color_orange'} size='bouton_size_normal'  onclick={submit}   /> 
                            </div>
                        </div>
                  </div>)
}

export default Formulaire