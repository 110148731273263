import React ,{useState, useEffect} from 'react';
import { useSelector , useDispatch} from "react-redux";

import { setFiltres } from '../../state/redux';

import './CalendarTwoPickers.scss'

const classNames = require('classnames');

const listemois = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']
const joursdelasemaine = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"]

const today = new Date();   const [anneeactu, moisactu] = [today.getFullYear(),today.getMonth()];

function CalendarOnePicker({id,setDisplaytwocalendars}){
     const dispatch = useDispatch() ;
     const filtres = useSelector(state=>state.leads.filtres);
     const [mois, setMois]  = useState(moisactu);
     const [annee, setAnnee]  = useState(anneeactu);
     const [nbjoursmois, setNbjoursmois] = useState('');
     const [indexfirstday, setIndexfirstday]  = useState();
 
     const  touteslescases = document?.getElementsByClassName('casetwodates');
     const idcases =     Array.from(touteslescases).map(( unecase ) => unecase['id'])  ;
 
     const changeecrancalendar =async (mois,annee)=>{  
         setMois(mois) ; setAnnee(annee) ;  setNbjoursmois(new Date(annee,mois+1,0).getDate()) ;
         setIndexfirstday(joursdelasemaine.map(x=>x.toLowerCase()).indexOf(new Date(annee+'-'+(mois+1)+'-0'+1).toLocaleDateString('fr-FR', { weekday: 'long'})))   ;
     }
     
     useEffect(()=>{ changeecrancalendar(mois,annee) ;},[idcases])
 
     const changemonth = (e)=>{const [newmonth,newyear]=e.target.id==='next'?[(mois+1)%12,annee+(mois===11?1:0) ]:[mois===0?11:(mois-1)%12,annee-(mois===1?1:0)]; 
                                  changeecrancalendar(newmonth,newyear) ;}
 
     const clickjour = (e)=>{id==='calendaronepicker1' &&    dispatch(setFiltres({...filtres,'date1': (new Date(new Date(e.target.id).setHours(0,0,0)))})) ;  
                            id==='calendaronepicker2' &&    dispatch(setFiltres({...filtres,'date2': (new Date(new Date(e.target.id).setHours(0,0,0)))})) ;  
                            id==='calendaronepicker2' && setDisplaytwocalendars(false) }
 
    const verifydateselected =(x) => id==='calendaronepicker1' ?(new Date(filtres?.['date1']).getFullYear() === annee && new Date(filtres?.['date1']).getMonth() === mois && x=== new Date(filtres?.['date1']).getDate()) :
                                                          (new Date(filtres?.['date2']).getFullYear() === annee && new Date(filtres?.['date2']).getMonth() === mois && x=== new Date(filtres?.['date2']).getDate()) 
     return(<> <div className='onepicker_top' >
                                <div id='back'  onClick={changemonth} ></div>
                                <div className='onepicker_top_middle'>{listemois[mois]} {annee}</div>
                                <div id='next' onClick={changemonth} ></div>
                        </div>
                        <div className='onepicker_pickercontainer' >
                            <div id='onepicker_picker' className='onepicker_picker' >
                                    {joursdelasemaine.map(x=>(<div  key={x.substring(0,2)}  className='day2lettres' >{x.substring(0,2)}</div>))}
                                    {[...Array(indexfirstday).keys()].map((x)=>(<div key={'vide'+x}  ></div>))  }
                                    {[...Array(nbjoursmois).keys()].map(x=>x+1).map((x,i)=>(<div key={i} id={annee+'-'+(mois+1)?.toString()?.padStart(2,'0')+'-'+x} onClick={clickjour} 
                                                                                                  className={classNames('casetwodates',  {'today':  mois===moisactu && annee===anneeactu && x===today.getDate()},
                                                                                                                {'selected':verifydateselected(x)})}  >
                                                                                            {x} </div>))  }    
                            </div>
                        </div></> 
                 )
 }
 
 function CalendarTwoPickers({setDisplaytwocalendars}){

     return(<div className='calendartwodatepickers' >
                       <div className='onepicker' >
                        <div className='onepicker_titre' >Début</div>
                         <CalendarOnePicker id='calendaronepicker1'  setDisplaytwocalendars={setDisplaytwocalendars} />
                    </div>
                    <div className='onepicker' >
                        <div className='onepicker_titre' >Fin</div>
                        <CalendarOnePicker id='calendaronepicker2'  setDisplaytwocalendars={setDisplaytwocalendars} />
                    </div>
             </div>)
 }
 
 export default CalendarTwoPickers