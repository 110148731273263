import React from "react";

import './AccessToDocs.scss'
function AccessToDocs({nom}){

    const nomdoc =  nom ?nom.replaceAll('é','e').replaceAll('è','e').replace(/([- + /])/g,""):'' ;

   
// ASAFAFPSOsalysV3
    return(<div className="accesstodocs"> 
                        <a href={`https://mutuello.s3.eu-west-3.amazonaws.com/plaquettes/plaquette${nomdoc}.pdf`}    target="blank">
                        <div className="linedocument" >Prestations</div> </a>
                        <div className="docdivider" ></div> 
                        <a href={`https://mutuello.s3.eu-west-3.amazonaws.com/notices/notice${nomdoc}.pdf`}    target="blank">
                        <div className="linedocument" > CG </div> </a>
           </div>  )
}


export default AccessToDocs


