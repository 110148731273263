

import socketIOClient from "socket.io-client";


import { threeyearsafter } from "./utils_dates";
import {alertpbcontrat} from "./actions_lead";
import { rdvgagne } from "./actions_echec_gagne";



import { queryClient }  from '../state/queryClient';
import { addEltHistory , addnotif  , modifyUser}  from '../state/reactquery';

import {store,addlead,allLeads,modifyChampsLeadactu,getLeadsMonth,defineNotifnew ,modifyPlayanimationlottie} from "../state/redux";

const url =  process.env.REACT_APP_URLSOCKET


let socket = null;

export const socketio  = {
    getSocket : ()=> socket, 
    setSocket: (s)=>{socket = s}
}


function handleincominglead(stringlead){
 
    const lead= JSON.parse(stringlead);
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')])
    const leadsmonth = store.getState().leads.leadsmonth ;
   
    if((lead.campagne && (lead.campagne.includes(user['nom']) ))|| lead.commercial===user['nom'] ){
       
         store.dispatch(addlead(lead));
        store.dispatch(getLeadsMonth([...leadsmonth, lead]))
    }
}



function handleincomingpbcontrat(stringlead){
    const lead= JSON.parse(stringlead);
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')])
 
    if((lead.campagne && (lead.campagne.includes(user['nom']) ))|| lead.commercial===user['nom'] ){
        alertpbcontrat(lead.nom,lead.prenom,lead.email,lead.origine )
        store.dispatch(addlead(lead))
    }
}


function handledeletelead(arg){
 
    queryClient.setQueryData((queryClient.getQueryData(['clients','list'])??[]).filter(x=>x.id===arg))
//    store.dispatch(deletelead(arg))
}
function  handlesignatureyousign(args){
    //  store.dispatch(modifyPlayanimationlottie(true)) ; 
    //   setTimeout(()=>{store.dispatch(modifyPlayanimationlottie(false))},6000) ;
    //  (leadtochange?.documents===1  && store.getState().lead.leadactu.id === id_lead)  &&  rdvgagne(id_lead); 

    const argsock= JSON.parse(args);

    const {id_lead, date_rdvrelance,...newleadactu } = argsock

    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]) ;
    const leadtochange = store.getState().leads.leads.filter(x=>x.id===id_lead)[0];
    const  otherleads = store.getState().leads.leads.filter(x=>x.id!==id_lead);

    queryClient.invalidateQueries({ queryKey: ['documents',leadtochange?.id_client]}) ;
    queryClient.invalidateQueries({ queryKey: ['contrats',leadtochange?.email,user?.nomdomaine]}) ;

    if(store.getState().lead.leadactu.id === id_lead){
        store.dispatch(modifyChampsLeadactu({...newleadactu, 'date_rdvrelance':threeyearsafter(leadtochange.date_lastmodif)})); // , 'recyclage' : 2
    }

   leadtochange?.id && store.dispatch(allLeads([...otherleads,leadtochange])); 

}


function handlesignature(args){

    const argsock= JSON.parse(args); 
  
    const id_lead = argsock.id_lead ;
 
    const  otherleads = store.getState().leads.leads.filter(x=>x.id!==id_lead);
   const leadtochange = store.getState().leads.leads.filter(x=>x.id===id_lead);
   queryClient.invalidateQueries({ queryKey: ['histories',id_lead]})
     //DEV
     (leadtochange.documents===1  && store.getState().lead.leadactu.id === id_lead)  &&  addEltHistory('leadgagne', `<div class='horoline1' > <span className=horobold'>Lead passé en Gagné ! </span> </div>` )   
   (leadtochange.documents===1  && store.getState().lead.leadactu.id === id_lead)  &&  store.dispatch(modifyChampsLeadactu({'statut_client':'gagne','contratsigne':1,'date_rdvrelance':threeyearsafter(leadtochange.date_lastmodif), 'hot':0})); // , 'recyclage' : 2

   (leadtochange.documents===1  && store.getState().lead.leadactu.id === id_lead)  &&  rdvgagne(id_lead); 
   (leadtochange.documents!==1  && store.getState().lead.leadactu.id === id_lead)  &&  store.dispatch(modifyChampsLeadactu({'statut_client':'attente_documents','contratsigne':1})); 

 leadtochange.id && store.dispatch(allLeads([...otherleads,leadtochange])); 
}


function handlesignaturemandat(args){
      const docu = JSON.parse(args); 
    const docactus = queryClient.getQueryData(['documents',docu.id_client])  ?? [];
    queryClient.setQueryData(['documents',docu.id_client],[...docactus,docu])
}


function handleenterticket(args){
    queryClient.invalidateQueries({ queryKey: ['tickets']})  ;
    return ; 
    const ticket  = JSON.parse(args); 

    const ticketsactus = queryClient.getQueryData(['tickets']) ?? [] ;
    if(ticketsactus.filter(x=>x.id===ticket.id)){
        queryClient.setQueryData(['tickets'],[...queryClient.getQueryData(['tickets']).filter(x=>x.id!==ticket.id),ticket]) ;    
    }else{ queryClient.setQueryData(['tickets'],[...ticketsactus,ticket]) ;}
   
}

// DEV  ?????      


function handlenotif(args){ 
   
    const  notif= JSON.parse(args);
    console.log('handlenotif',notif['type_notif'], notif.postmail,notif['email']) ; 
    store.dispatch(defineNotifnew({...notif})); 
    
    notif['type_notif']==='enterlead' &&  queryClient.invalidateQueries({ queryKey: ['notes',notif.id_lead]})  ;
    queryClient.invalidateQueries({ queryKey: ['histories',notif.id_lead]}) ;
    (notif['type_notif']==='email' &&  notif['email']  )  &&   queryClient.invalidateQueries({ queryKey: ['clientmails',notif.postmail,notif['email'] ]})  ;
     notif['type_notif']==='email' && queryClient.invalidateQueries({ queryKey: ['listmails']})  ;
// queryClient.getQueryData(['documents',id_client]
    setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000)
    addnotif({...notif})
}


function handlemaildelivery(args){
    console.log('handlemaildelivery') ; 
    const parseargs = JSON.parse(args) ;
    console.log('handlemaildelivery',parseargs?.['postmail'],parseargs['email'] ) ; 
    setTimeout(()=>{
       (parseargs?.['postmail'] && parseargs['email'] )  &&  (   queryClient.invalidateQueries({ queryKey: ['clientmails',parseargs?.['postmail'],parseargs['email'] ]}))  ;
        queryClient.invalidateQueries({ queryKey: ['listmails']})  ;
    },4000)

}

export async function initialiseSocket(){
    console.log('INITIALISESOCKET')  ; 
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]) ;

    if(!user?.['postmail'] ) {return }
    
    const sock = socketIOClient(url, {query : {postmail: user['postmail']} });
   sock && socketio.setSocket(sock);

//   sock.on('disconnect', () => {console.log('DISCONNECT!!!!') ;initialiseSocket()})
 
// setInterval(()=>{sock.emit('ping')},3000)


    sock.on('connect',()=>{sock.emit("login")}  )
    sock.on('enterlead', (arg)=>{ handleincominglead(arg) });
    sock.on('changestatutcontrat', (arg)=>{ handleincomingpbcontrat(arg) });
    sock.on('changecommercialrecieve', (arg)=>{ handleincominglead(arg) });
    sock.on('supprimeleadcommercialrecieve', (arg)=>{ handledeletelead(arg) });
    sock.on('signature', (arg)=>{ handlesignature(arg) });
    sock.on('signaturemandat',  (arg)=>{ handlesignaturemandat(arg) });
    sock.on('enterticket',  (arg)=>{ handleenterticket(arg) });
    sock.on('notif',(args)=>{ handlenotif(args) });
    sock.on('signatureyousign', (arg)=>{ handlesignatureyousign(arg) });
    sock.on('maildelivery', (arg)=>{ handlemaildelivery(arg) });
   sock.on('deconnexion',()=>{console.log('deconnexion')  ; localStorage.removeItem('token')  ; modifyUser({'authenticate' :  false}); localStorage.removeItem('imgavatar') ;;window.location.replace("login");});
}

