import React from "react";

import Draggable from 'react-draggable';

import Telephonie from "./Telephonie";



import './Telephonie.scss'
function TelephonieDraggable(){

    const nodeRef = React.useRef(null);


    return( <Draggable nodeRef={nodeRef} >
                  <div className="telephoniedraggable" ref={nodeRef}>
                        <Telephonie   /> 
                    </div>
            </Draggable>)
}



export default TelephonieDraggable