import React ,{useState} from 'react';
import {useDispatch , useSelector}from 'react-redux'

import { modifyEcran   , addlead   } from '../state/redux';
import { useTickets,useUser , useNotifs, useAllmails} from '../state/reactquery';

import InfoUser from './InfoUser';

import iconmutuello from '../images/menu/iconmutuello.svg';
import iconmutuellobottom from '../images/menu/iconmutuellobottom.svg';
import visite  from '../images/visite.svg';



import './Sidebar.scss'

const classNames = require('classnames');
function Sidebar({setDisplaynotifs}){
       
        const dispatch = useDispatch();
        const  ecran = useSelector(state=>state.display.ecran) ;

       
        const {data: user} = useUser(localStorage.getItem('token'))  ;
        const {data : notifs} = useNotifs();
        const {data:tickets}  = useTickets() ; 
        const {data: emails} = useAllmails(user?.postmail) ; 

        const [displayinfouser, setDisplayinfouser] = useState(false) ;

        const mailsnonouverts = emails?.filter(x=>x.emailTo ===user?.postmail && x.RecordType!=='Open').sort((a,b) => new Date(b.created_at) - new Date(a.created_at)) ;
        const ticketsnews = tickets?.filter(x=>x['statut']==='new')  ;
        const notifsnoopened  = notifs?.filter(x=>x['opened']===0)  ;

        const clickeltmenu = (e)=>{
            if(e.target.id==='notifsbell'){   setDisplaynotifs(displaynotifs => !displaynotifs)}
            else {dispatch(modifyEcran(e.target.id)) ; }
          }

        const  creationleaddemo = () =>{
        let dateeffet = new Date((new Date()).getTime()+ 45*24*60*60*1000) ;  
        let [jour_effet, mois_effet, annee_effet ]= [dateeffet.getDate().toString().padStart(2,'0'),(dateeffet.getMonth()+1).toString().padStart(2,'0'),dateeffet.getFullYear()] ; 
        let [campagne, commercial] = window.location.hostname==='localhost'?["crm / selchesi","selchesi"]:["crm / demo","demo"] ; 
        const fakelead = {"civilite":"M","nom":"Demonstration","prenom":"Alex","adresse":"14 Rue du Théâtre","code_postal":"75015","origine":"meilleurtaux.com",
                          "ville":"Paris","num_departement":"75", "email":"gerard.martin260@gmail.com","telephone":"0757918904","date_naissance":"25/05/1989",
                        "regime":"general", "regime_conjoint":"general","campagne":campagne ,"commercial":commercial , 
                          "statut_client":"nontraite" ,"date_effet" : jour_effet+'/'+mois_effet+'/'+annee_effet ,"date_reception": (new Date()).toISOString()   , 
                          "id":"leadfirstline" ,"id_client":"leadfirstline_client"}

        dispatch(addlead(fakelead))   }

        const avatar2 = ()=>(<div className='avatar2' >{(user['nom']?.charAt(0).toUpperCase()??'' ) + (user['prenom']?.charAt(0).toUpperCase()??'')  }</div>)

        const iconsidebar = (id)=>{
        return(<div  id={id} className={classNames("iconsidebar",id,{'selected':ecran===id})} onClick={clickeltmenu}  >
              { ( id==='ecrantickets'  &&  ticketsnews?.length >0)  &&  <div className={classNames('nbtickets',{'small':ticketsnews.length>99 }  ,{'medium':ticketsnews.length<100 && ticketsnews.length>9 }  ,{'big': ticketsnews.length < 10}   )}  onClick={clickeltmenu}  > {ticketsnews.length} </div>}
              { ( id==='notifsbell'  &&  notifsnoopened?.length >0)  &&  <div className={classNames('nbtickets',{'small':notifsnoopened.length>99 }  ,{'medium':notifsnoopened.length<100 && notifsnoopened.length>9 }  ,{'big': notifsnoopened.length < 10}   )}  onClick={clickeltmenu}  > {notifsnoopened.length} </div>}
              { ( id==='ecranemails'  &&  mailsnonouverts?.length >0)  &&  <div className={classNames('nbtickets',{'small':mailsnonouverts?.length>99 }  ,{'medium':mailsnonouverts.length<100 && mailsnonouverts.length>9 }  ,{'big': mailsnonouverts < 10}   )}  onClick={clickeltmenu}  > {mailsnonouverts?.length} </div>}
      

      
      
        </div>) }
        

        return(<div className="sidebar">
                    <div className="sidebar_top">
                          <div className="logotop">
                                <img src={iconmutuello}  alt="icon"  heigth="16"/>
                                <img src={iconmutuellobottom}  alt="icon"  heigth="6"/>
                          </div> 
                          { (user?.roles?.includes("GESTION"))   &&  iconsidebar("ecrangestionleads")  }
                          { (user?.roles?.includes("COMMERCIAL"))   &&  iconsidebar("dashboard")  }
                          { (user?.roles?.includes("MANAGER"))   &&  iconsidebar("manager")  }
                          { (user?.roles?.includes("COMMERCIAL"))   &&  iconsidebar("ecrantickets")  }
                          {  (user?.roles?.includes("COMMERCIAL"))   &&    iconsidebar("ecranemails")}  
                    </div>  

                    <div className="sidebar_bottom"> 
                        { (user?.roles?.includes("DEV"))   &&  iconsidebar("dev")  }
                        { <img   id = "startdemo" className='visite'  src={visite}  onClick={creationleaddemo}    alt="visite"    />     }
                        { (user?.roles?.includes("ADMIN")  ||user?.roles?.includes("DEV") )   &&  iconsidebar("params")  }
                        { (user?.roles?.includes("COMMERCIAL"))   &&  iconsidebar("notifsbell")  }
                        {/* <div id ="startdemo"   src={visite}  onClick={creationleaddemo}> </div> */}
                        <div  className='avatardivider'  ></div>
                        <div onClick={()=>setDisplayinfouser(!displayinfouser)}>
                              { localStorage.getItem('imgavatar')?<img  className='avatar' src={localStorage.getItem('imgavatar')} alt='avatar' width='34' height="34"  /> : avatar2()} 
                        </div>    
                    </div>  
                   {displayinfouser && <InfoUser  /> }
            </div>)
        }

export default Sidebar