
import React from "react";
import {BrowserRouter,Routes,Route } from "react-router-dom";
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider }  from 'react-redux';



import {store} from './state/redux'; 
import { queryClient } from "./state/queryClient";

import Login from "./Pages/Login";
import Application  from "./Pages/Application";
// import Mondevis from "./Pages/Mondevis";



function App() {


  return (<Provider store={store}>
              <QueryClientProvider client ={queryClient}>
                        <BrowserRouter><Routes>
                        <Route path="/" element={<Application />}   />
                        {/* <Route   path="/mondevis/:documentId" element={<Mondevis />}   /> */}
                                    <Route path="login" element={<Login />} />
                                    <Route path="*" element={<Application />} />
                          </Routes></BrowserRouter>
                          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </QueryClientProvider> 
           </Provider>);
}

export default App;


