import React , {useEffect, useState }from "react";

import axios from "axios";

import { useUser } from '../../state/reactquery';

import './StatsUse.scss'
function StatsUse(){
    const {data: user} = useUser(localStorage.getItem('token'))  ;
  const [listeappels, setListeappels] = useState([])
  const [multiselect, setMltiselect]  = useState({debut:(new Date()).toISOString().split('T')[0], fin : (new Date()).toISOString().split('T')[0] , 'commercial': 'all','courtier' :user['nomdomaine'] }) ;      
  const filterselect =(e) =>{  setMltiselect({...multiselect,[e.target.id]: e.target.value }) }


  const getlistehoroappels = async()=>{

    const response =  await axios.post(`/gestion/gethoroappels`,{...multiselect },  {headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'} });
    console.log('RRRRR',response?.data)
    setListeappels(response?.data?.sort((a,b)=>new Date(a?.date) - new Date(b?.date)))
  }

  const   selectliste  = ()=>{getlistehoroappels()}

useEffect(()=>{getlistehoroappels()},[])
return(<div>
                                <div className="divbilanleads_choices">
                                <div className="nombre" >{listeappels?.length ?? 0}</div>
         
                            <div className="containerselection wd125" >
                                <div className="labelinput" >Debut </div>
                                <input id='debut' type='date' value={multiselect?.debut} onChange={filterselect} />
                            </div>
                            <div className="containerselection  wd125" >
                                <div className="labelinput" >Fin </div>
                                <input id='fin' type='date' value={multiselect?.fin} onChange={filterselect} />
                            </div>

                            <div className="containerselection" >
                                <div className="labelinput" >Commercial</div>
                                <select id='commercial' className='selectchoice  wd140' onChange={filterselect} >
                                    <option value='all' >Tous commerciaux</option>
                                  {[...user.commerciaux]?.length > 0 && [...new Set(listeappels?.map(x => x.commercial)),...user.commerciaux ]?.map((x, i) => <option key={i} value={x} >{x}</option>)}
                                </select>
                            </div>
                            <button className='bouton vert' onClick={selectliste}  >Valider</button>
                        </div>
        <table>
            <thead>
                <tr><td className="tddate" >Date</td><td className="tddate" >Commercial</td><td className="tddate" >Heure</td></tr>
            </thead>
            <tbody>
                        {listeappels.map((x,index)=><tr key={index} ><td className="tddate" >{(new Date(x.date)).toLocaleDateString("fr-FR",{timeZone:"Europe/Paris"}) }</td><td className="tddate" >{x.commercial}</td><td className="tddate" >{(new Date(x.date)).toISOString().slice(11, 19)}</td></tr>)}
                        </tbody>
        </table>

</div>)
}


export default StatsUse