

import React  from 'react';



import Contrats from './Contrats';
import Famille from './Famille';
import Upload from '../../Components/UploadFiles/Upload';



import './EcranGestionOneClient.scss';

function FicheClient(){



    return(<div className="clientsgestion">
                  <div className='clientsgestion_titre'> </div>
                  <div className='ficheclientcontainer' >
                       <Famille /> <Contrats  /> 
                     <div className='containerupload' > <Upload  /></div>  
                  </div>
            </div>)

}


export default  FicheClient

