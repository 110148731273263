import React from 'react';
import { useSelector } from 'react-redux';

import { phonefromlead } from '../../utils/actions_lead';


import TableLeadsDUMB from './TableLeadsDUMB';



function TabLeadsSearch(){

      const leadssearch = useSelector(state=>state.leads.leadssearch);



        return(<TableLeadsDUMB datas={leadssearch} id="searchtable"  titre="notitre"   clicktel={phonefromlead}   />)
}

export default TabLeadsSearch

