import React , {useState,useEffect}  from "react";

import { useUsers , useUser} from "../../state/reactquery";
import { nanoid } from '@reduxjs/toolkit';

import {  sendinfosusers} from "../../utils/utils_courtier";

import './EquipesUserAddModif.scss'

const jsonroles = {'ADMIN':'Administrateur','GESTION':'Gestionnaire',"MANAGER":'Manager',"COMMERCIAL":'Vendeur'}

const classNames = require('classnames');
function EquipesUserAddModif({type,setShowadduser,setShowmodifuser,user}){
      const {data: useractu} = useUser(localStorage.getItem('token'))  ;
      const {data:users}  = useUsers() ;
      const [datauser, setDatauser] = useState({'ADMIN':0,'GESTION':0,'MANAGER':0,'COMMERCIAL':0,'courtier':useractu?.courtier??'fidelis'});
      const [showlistroles, setShowlistroles] = useState(false) ;
      const [showlistequipes, setShowlistequipes] = useState(false)  ;
      const [image, setImage] = useState(null)
      
      useEffect(()=>{user && setDatauser(user)},[user]);

      const toggleshowlistroles = ()=>{setShowlistroles(showlistroles=>!showlistroles)}
      const toggleadminequipe = ()=>{setShowlistequipes(showlistequipes=>!showlistequipes)}

      const modifylistrole = (e)=>{ setDatauser({...datauser,[e.target.id]  : datauser[e.target.id]===1?0:1}) }

      const handleinput = (e) =>{ // console.log('handleinput',type,e.target.id,e.target.value)  ; return
                  e.target.id!=='firstname'?setDatauser({...datauser,[e.target.id]  : e.target.value})  : 
                                 type==='adduser' ?setDatauser({...datauser,[e.target.id]  : e.target.value , 'nom': e.target.value?.toLowerCase()?.replace(/\s/g,'')   ,
                                                                        'postmail':e.target.value?.toLowerCase()?.replace(/\s/g,'')+'.'+(useractu?.courtier??'fidelis')+'@mutuello.email'   }):
                                                   setDatauser({...datauser,[e.target.id]: e.target.value ,'postmail':e.target.value?.toLowerCase()?.replace(/\s/g,'')+'.'+(useractu?.courtier??'fidelis')+'@mutuello.email'   })           ;
                                                
      }
  
      const addimage = (e)=>{ setImage(e.target.files[0]) }
      const modifymanageruser =  (e) =>{
            let newuser = {...datauser} ;
            datauser['id_manager']===parseInt(e.target.id) ? ( delete newuser['id_manager']) : (newuser['id_manager']=parseInt(e.target.id) )
            setDatauser({...newuser}); setShowlistequipes(false)}

      const confirmer =async ()=>{
            let  data= new FormData();const nano =  nanoid() ;        
            let datajson ={}
            Object.keys(datauser).forEach(key=>{ if( key!=='avatar' ){datajson[key]=datauser[key]}   }) ;
                         data.append('datajson', JSON.stringify(datajson) );
                         if( image !==null ){ data.append('avatar',`https://${process.env.REACT_APP_AWS_NAMEBUCKET}.s3.eu-west-3.amazonaws.com/courtiers/avatars/${datauser['courtier']}/${nano}.${image.name.split('.')[1]}`);
                                 data.append('file',image,`${nano}.${image.name.split('.')[1]}`);}
                         else{datajson['avatar']=`https://${process.env.REACT_APP_AWS_NAMEBUCKET}.s3.eu-west-3.amazonaws.com/courtiers/avatars/avatar.png`;}
                  
            sendinfosusers(type,data) ; 
            type==='adduser' ?   setShowadduser(false) :  setShowmodifuser(false) ;
       }


   return(   <div className="tableutilisateurs_userform" >
                  <div > <input type= 'file'  onChange={addimage}  /> </div>
                  <input  id='lastname' type="text" placeholder="Nom"  value={datauser['lastname']} onChange={handleinput}  />        
                  <input  id='firstname' type="text"    placeholder="Prénom"  value={datauser['firstname']} onChange={handleinput} />             
                  <input  id='email'  type="text"    placeholder="Email"  value={datauser['email']} onChange={handleinput}  />  
                  <div className={showlistroles?"down":"up"}  > <div onClick={toggleshowlistroles} >Admin / Gestion </div> 
                        <div className={classNames("listadmingestion",{"show":showlistroles },{"hide":!showlistroles }  )} >
                              { (Object.keys(jsonroles)).map(x=><div key={x}  className="admingestionline">
                                                                        <div id={x} className={classNames("admingestionline_check",{"checked": datauser[x] ===1})} onClick={modifylistrole} ></div>
                                                                        <div className="admingestionline_texte">{jsonroles[x]}</div>
                                                            </div>)}
                        </div>
                  </div>    
                  <div className={showlistequipes?"down":"up"}  > <div  onClick={toggleadminequipe} >Equipe</div>  
                        <div className={classNames("listadmingestion",{"show":showlistequipes },{"hide":!showlistequipes }  )} > 
                        { [...users.filter(x=>x.MANAGER===1)].map(x=><div key={x.id}  className="admingestionline">
                                                       <div id={x.id} className={classNames("admingestionline_check",{"checked": datauser['id_manager']===x.id})} onClick={modifymanageruser} ></div>
                                                       <div className="admingestionline_texte">Equipe de {x['firstname']}</div>
                                                </div>)}
                        </div>
                  </div>  
                  <div className="lineuser_deletemodif">
                        <span className="lineuser_delete" onClick={()=>{ type==='adduser'?setShowadduser(false):setShowmodifuser(false)}} >Annuler</span>
                        <span className="lineuser_modif" onClick={confirmer}  >Confirmer</span>
                  </div>  
            </div>) 
}


export default  EquipesUserAddModif