import React  , {useState} from "react";
import { useUsers } from "../state/reactquery";


import './SelectUser.scss';

const classNames = require('classnames');


function UserChoice ({user,changegestionnaire, setShowliste}){ 
    const handleClick =(e) => {changegestionnaire(e)  ;   setShowliste(showliste=>!showliste)} 
    return (<div key={user.id} id={user.id}  className="listegestionnairesline"  onClick={handleClick}  >
                           <img  className={user['id']<0?'small':''} src={user.avatar||`https://${process.env.REACT_APP_AWS_NAMEBUCKET}.s3.eu-west-3.amazonaws.com/courtiers/avatars/avatar.png`}  width="30"  height="30"   alt="faceuser" />  
                     <div>  {user['lastname']} {user['firstname']||''}   </div> 
                     </div>)};



function SelectUser({  useractu  ,changegestionnaire }){

    const {data:users}  = useUsers() ;
    const [showliste, setShowliste] = useState(false) ;


    return( <div  className={classNames("selectgestionnaire",{"down":showliste},{"up":!showliste})}    >
                                  { (users?.length >0  &&   useractu ) ?   <UserChoice user={useractu}  setShowliste={setShowliste} changegestionnaire={()=>{  return}} /> :<div className="nouser" onClick={()=>setShowliste(!showliste)}  ></div> }  
                             <div className={classNames("listegestionnaires",{"show":showliste},{"hide":!showliste}  )} >
                                    {( users?.length >0 )  && users?.map(user=>  <UserChoice key={user.id}  user={user}  setShowliste={setShowliste}  changegestionnaire={changegestionnaire} />)}      
                            </div>  
                        </div> )
} 


export default SelectUser
