import React  from 'react'
import { useDispatch } from 'react-redux';

import {socketio } from '../../utils/socket';
import { useUser } from '../../state/reactquery';
import { initialiseLeadactu, initialiseLeadmemo , modifyDisplayinfos, modifyDisplaysearch , getLeadsSearch} from '../../state/redux';

import ButtonImgLeft from '../../Components/Buttons/ButtonImgLeft';

import './DashboardTop.scss'
function DashboardTop(){
    const dispatch = useDispatch();

    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const newprospect=async ()=>{   await dispatch(initialiseLeadactu({}));
                                    await  dispatch(initialiseLeadmemo({'origine': 'crm' ,'commercial': user['nom'], 'statut_client':'nontraite','courtier':user?.nomdomaine ?? 'fidelis', 'campagne': 'crm / '+user['nom']}))
                                    dispatch(modifyDisplayinfos(true)) ;
    }
    // <button  onClick={()=>{socketio.getSocket().emit('test')}} >click</button>
    return(<div className="dashbordtop"  >
                <div  className="dashbordtop_top" >
                    <div><h1>Hello {(user['firstname'])} 👋  </h1><h3 className='topnumtel'> {user['telephone']}</h3> </div>
                    <div  className="dashbordtop_right" >
                            <input  className="dashbordtop_input" placeholder='Recherche' type="text"  onFocus={()=>{dispatch(getLeadsSearch([]) ) ;dispatch(modifyDisplaysearch(true))}}     />
                            <ButtonImgLeft texte="Nouveau Prospect"   color='bouton_color_orange' size='bouton_size_normal' image='imageplus' onclick={newprospect}  /> <br/><br/>
                    </div>  
                  </div>
            </div>)
}

export default DashboardTop