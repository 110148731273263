import axios from "axios";

import { store ,addlead,  allLeads ,allManagerLeads,modifyChampsLeadactu , defineCampagnes} from "../state/redux";
import { rdvgagne } from "./actions_echec_gagne";
import { tracking } from './tracking';
import { modifyUser }  from '../state/reactquery';
import { queryClient }  from '../state/queryClient';


export const getallleads = () => async () => {
  const ecran = store.getState().display.ecran ; 
    try {
      const response = await axios({method:'POST',url:`/clients/findallclients`,headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  } });
      if(response.error){modifyUser({'authenticate' :  false});  }
      let leads = [...response.data.leads] ; let campagnes = [...response.data.campagnes]
          for(let i= 0; i<leads.length ; i++){  let lead=leads[i] ;
            if(lead.statut_client==='attente_signature' && lead.contratsigne===1){
              const othersleads = leads.filter(x=>x.id!==lead.id);
              const newlead = {...lead,'statut_client':'gagne'}; //, 'recyclage' : 2
             await  axios.post(  `/clients/updateclients`,{...newlead});
             tracking({'ecran': ecran,'composant':'None' ,'fonction':'modifyhot' ,'getallleads':JSON.stringify({'statut_client':'gagne'}),'lead': JSON.stringify(lead)}) ;//, 'recyclage' : 2
             leads=[...othersleads, newlead]
        rdvgagne(lead.id); 
            } 
               

          }
    store.dispatch(allLeads([...leads]));
    store.dispatch(defineCampagnes([...campagnes]));
    }catch(err){ store.dispatch(allLeads([]));  }
  };
  



    
    export const writenote = (id,note) => {   
      axios.post(  `/memory/writenote/`,{id,note},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
    } 
    
    
    export const getnotebyid =async () => {
      const lead = store.getState().lead.leadactu;

      let note; 
      try { const response = await axios.post( `/memory/getnotebyid`,{id:lead.id }, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
            note= response.data;  return note
        }catch(err){ note =''  ;   return note }
    
      };
    
      


export const getmanagerleads = () => async () => {
  const user= queryClient.getQueryData(['user',localStorage.getItem('token')])  ;

  try {const response = await axios({method:'POST',url :`/clients/findmanagerclients`,data:{'commerciaux': user['commerciaux']||[]},headers:{'Accept':'application/json','Content-Type':'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}`  } });
         store.dispatch(allManagerLeads([...response.data]));   
      }catch(err){   store.dispatch(allManagerLeads([]));}
};


export const recordclients = (lead) => async () => { 
  if(lead.id==="leadfirstline") {return  }
    const reqrecord   =await   axios.post(  `/clients/recordclients/`,lead  , {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} }); 
    store.dispatch(modifyChampsLeadactu({...reqrecord.data})); 
    store.dispatch(addlead({ ...reqrecord.data}))
    return  {...reqrecord.data}
}

export const  updateclients = (lead)  => {
  const ecran = store.getState().display.ecran ; 
  if(lead.id==="leadfirstline") {return  } 
    axios.post(  `/clients/updateclients/`,lead, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
    tracking({'ecran': ecran,'composant':'None' ,'fonction':'updateclients' ,'champschanged':'none','lead': JSON.stringify(lead)}) ;
    let leadactu =store.getState().lead.leadactu;
    let otherleads = store.getState().leads.leads.filter(x=>x.id!==leadactu.id);

    store.dispatch(allLeads([...otherleads, leadactu]));
}