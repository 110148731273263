import React from "react"
import { useSelector } from 'react-redux';
// import { formule } from "../../utils/mafornule"
import AjoutDevis from "./AjoutDevis"
import Jauge from "./Jauge";
import AccessToDocs from "./AccessToDocs";


import { logocompagnie } from '../../utils/logocompagnie';
import ImgPrevention from "../../images/images_postes/ImgPrevention"
import ImgOrthodontie from "../../images/images_postes/ImgOrthodontie"
import ImgImplant from "../../images/images_postes/ImgImplant";
import ImgHospi from "../../images/images_postes/ImgHospi";
import ImgProtheses from "../../images/images_postes/ImgProtheses";
import ImgSpecialistes from "../../images/images_postes/ImgSpecialistes";
import ImgLentilles from "../../images/images_postes/ImgLentilles";
import ImgLunettes from "../../images/images_postes/ImgLunettes";

import { signatureformule } from "../../utils/actions_signegagne";



import './CardFormuleDetail.scss'
const classNames = require('classnames');
function CardFormuleDetail({setShowformule}){
    const formule= useSelector(state=>state.formules.formule) ;

//     const scrooltodiv = (e)=>{
//         const depart = document.getElementById("newleads").getBoundingClientRect().top;
//         const div = document.getElementById(e.target.id.replace("choice","")).getBoundingClientRect().top;
//         window.scrollTo({top: div-depart, behavior: "smooth"}) 
// }

   const retourformules = ()=>{  setShowformule(false) ;  
   setTimeout(()=>{ document.getElementById("lestesdesformules")
                            .scrollTo({top: document.getElementById(formule.id).offsetTop, behavior: "smooth"});
   },1000)
   }

    function formatprix(prix){ return prix?   (Math.round(prix*100)/100).toString().split('.').join(","):'0'}

    const BlockDetail = ({cle,titre,image,nb,children})=>{
        return(<div key={cle} className={classNames("formuledetailsblock",{'last':titre==='Prévention' || titre==='Lentilles'  })}>
                        <div className="formuledetailsblock_divimage" >{image}  </div>
                        <Jauge nb={nb}   />
                        <div>  <div className="formuledetailsblock_texte">{titre}</div>  {children} </div>
                       { titre==='Prévention' &&   <div className="prevevide" ></div>}
                </div>)
    }

    const lineinfos =(texte1,texte2)=>{    
        return   <div className="formuledetailslineinfo" >
                                 <div  className="formuledetailslineinfo_line1">{texte1}</div>
                                 <div className='formuledetailslineinfo_line2'>{texte2} </div>
                  </div>
   } 

   
   const listedatas =(formule)=>{return  [{nb: formule.hospi  ,image:<ImgHospi color='gris' />,  titre :'Hospitalisation' , liste:["Honoraires","Chambre Particulière"], listeinfo:[formule.hospi_honoraire,formule.hospi_chambre]},
                                            {nb: formule.consults  ,image :<ImgSpecialistes color='gris' /> ,  titre :'Consultations' , liste:["Généralistes","Spécialistes","Pharmacies"], listeinfo:[formule.consults_generalistes_p,formule.consults_specialistes_p,formule.consults_pharma_nr]},
                                            {nb: formule.optique_lunettes_nb ,image :<ImgLunettes color='gris' /> ,  titre :'Lunettes'    , liste:["Prise en charge"], listeinfo:[formule.optique_lunettes]},
                                            {nb: formule.optique_lentilles_nb  ,image :<ImgLentilles color='gris' /> ,  titre :'Lentilles'    , liste:["Prise en charge"], listeinfo:[formule.optique_lentilles]},
                                            {nb: formule.dentaire_prothese_nb  ,image :<ImgProtheses color='gris' /> ,  titre :'Soins dentaires' , liste:["Détartrage","Prothèse"], listeinfo:[formule.dentaire_detartrage_p,formule.dentaire_prothese_p]},
                                            {nb: formule.dentaire_implant_nb  ,image :<ImgImplant color='gris' /> ,  titre :'Implants Dentaires', liste:["Implants"], listeinfo:[formule.dentaire_implant]},
                                            {nb: formule.dentaire_orthodontie_nb  ,image:<ImgOrthodontie color='gris' /> ,  titre :'Orthodontie' , liste:["Orthodontie"], listeinfo:[formule.dentaire_orthodontie_p]},
                                            {nb: formule.prevention  ,image:<ImgPrevention color='gris' /> ,  titre :'Prévention' , liste:["Consultations"], listeinfo:[formule.prevention_consult]}]}  

                                    
            
    return(<div  className="detailformule_container">
                <div className="retourformules" onClick={retourformules}  >Retour aux formules</div>
                <div  className="detailformule">
                    <div  className="detailformule_top">
                        <div className="detailformule_top_1">
                        <img src={logocompagnie(formule.compagnie?.toUpperCase())}  width='70' alt='logoCompagnie'/>
                        </div>  
                        <div className="detailformule_top_2">
                                <div className="nomgamme" >{formule.gamme}</div>    
                                <div className="formulenom" >{formule.nom_formule}</div> 
                        </div>  
                        <div className="detailformule_top_3">
                                <div className="prix" >{formatprix(formule.prix)}€</div>      
                                <div className="parmois" >par mois</div>  
                        </div>  
                         <div  className="detailformule_top_4">  <AccessToDocs nom={formule.compagnie+formule.gamme}  /> </div> 
                        <div className="detailformule_top_5">
                            <div className="btnsignature" onClick={()=>{ signatureformule(formule)}}  >Signature</div>
                            <AjoutDevis  formule={formule}    />
                         </div>  
                    </div>
                    <div  className="detailformule_bottom">
                           <div  className="borderright">
                          { (listedatas(formule)).map((x,i)=>(i<4 && <BlockDetail cle={100+i} nb={x.nb} image={x.image} titre={x.titre}   >
                                                                    {x.liste.map((y,index)=>lineinfos(y,x.listeinfo[index]))}
                                                        </BlockDetail>) )}
                             </div>
                             <div>
                             { (listedatas(formule)).map((x,i)=>(i>3 && <BlockDetail cle={500+i} nb={x.nb} image={x.image} titre={x.titre}   >
                                                                    {x.liste.map((y,index)=>lineinfos(y,x.listeinfo[index]))}
                                                        </BlockDetail>) )}
                            </div>
                        </div>

                        </div> 
            </div>)
}


export default CardFormuleDetail