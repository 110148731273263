import React from 'react';
import { useDispatch  } from 'react-redux';

import { choiceFormule } from '../../state/redux';

import { logocompagnie } from '../../utils/logocompagnie';

import CardFormuleJauge from './CardFormuleJauges';
import AjoutDevis from './AjoutDevis'

import lowcost from '../../images/lowcost.svg';

import './CardFormule.scss'


function formatprix(prix){ return prix?   prix.toFixed(2).replace('.',','):'0'}


const CardFormule = ({setShowformule,formule})=>{
     const dispatch= useDispatch()
     const logo  = logocompagnie(formule.compagnie?.toUpperCase());

     const showformule = (e)=>{
               if( e.target.closest('.cardformule_last')===null){setShowformule(true);dispatch(choiceFormule({id: formule.id})) }
     }
 

    return (<div className="cardformule" id ={formule.id} onClick={showformule}>
                      <div className='cardformuleimage' ><img src={logo}  width='70' alt='logoCompagnie'/></div>
                      <div className="nomformule" >              
                                   <span className="nomformule_span1">{formule.gamme} </span>
                                   <span className="nomformule_span2"> {formule.nom_formule && formule.nom_formule.toUpperCase()}</span>     
                                   {formule?.lowcost===1 &&<span><img height="20"  src={lowcost} alt='logolowcost'    /></span> }
                       </div>
                        <CardFormuleJauge  formule={formule} />
                        <div  className="cardformuleright" > 
                                   <span className='cardformuleright_span1'  >{formatprix(formule.prix)}€</span>
                                   <span className='cardformuleright_span2' >par mois</span>
                       </div>
                       <div className='cardformule_last'>
                               <AjoutDevis  formule={formule}  />
                       </div>
          </div> )       
             
}

export default CardFormule