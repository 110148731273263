import axios from 'axios';
import { nanoid } from '@reduxjs/toolkit'

import { store , allcalendarevents , seteventtochange , defineCalendar} from "../state/redux";

import { modifyonelead } from './actions_lead';
import { addEltHistory } from '../state/reactquery';
import { add15minutes , formatdatecalendar } from './utils_dates';
import { progressions_contact , progressions_devisenvoye, progressions_attentedocuments , progressions_attentesignature } from './listeprogression';
    
import { queryClient } from '../state/queryClient';

export const orderevents= (listevents)=>{

    let jsonlistejours = {};  let jsonlistejoursorder ={};
    const listeventsorder = [...listevents].sort((a,b)=> (new Date(a.startDatetime)).getTime() - (new Date(b.startDatetime)).getTime()  );
    for(let i=0; i< listeventsorder.length ; i++){
            if(jsonlistejours.hasOwnProperty((listeventsorder[i].startDatetime).split("T")[0])){
            jsonlistejours[(listeventsorder[i].startDatetime).split("T")[0]] = [...jsonlistejours[(listeventsorder[i].startDatetime).split("T")[0]] ,listeventsorder[i]]
            }else{
            jsonlistejours[(listeventsorder[i].startDatetime).split("T")[0]] =[listeventsorder[i]]
            }
    }
    (Object.keys(jsonlistejours).sort((a,b)=>new Date(new Date(a) - new Date(b) ))).forEach(date=>jsonlistejoursorder[date] = jsonlistejours[date]);
     return {...jsonlistejoursorder}
  }


export const rdvcalendar = async(date,note)=>{   console.log('rdvcalendar');
    const lead = store.getState().lead.leadactu;
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
    const summary = 'RdvMutuello '+(lead.nom  && lead.prenom )? lead.nom  +' '+ lead.prenom : lead.nom ? lead.nom:  lead.prenom ? lead.prenom: lead.email? lead.email : 'Inconnu....'
    const description = note +'#ID'+lead.id+'#ID';
    let data = {summary,description,startDatetime: date ,endDatetime:add15minutes(date),email:user['email']} ;

    const urlgeteventsbyclientid = user['calendar']!=='google' ? `/calendar/geteventsbyclientid` : `/googleapis/geteventsbyclientid` ;
    let id_event ;
    try{let reqevent = await   axios.post(urlgeteventsbyclientid,{email:user['email'], id:lead.id},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}})
            id_event =  reqevent.data.length>0?  reqevent.data[0]['id'] : undefined ; 
    }catch(err){console.log('ERR')}

    const urlpost= user['calendar']!=='google'?(id_event?`/calendar/updaterdv`:`/calendar/recordrdv`):id_event?`/googleapis/updateevent`: `/googleapis/sentevent`;

    const id =user['calendar']!=='google'?(id_event ?id_event :nanoid()): id_event ;

    try{ axios.post(urlpost,{...data,'id':id },{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}})   
              .then(result =>{  insertupdatedevent(result.data)  ;   store.dispatch(seteventtochange(result.data)) })
    }catch(err){ console.log('ERREUR DANS LA MISE A JOUR DES DONNEES CLIENT') }

}

export const insertupdatedevent = (evenement)=>{
    (!evenement?.startDatetime  && evenement?.start?.dateTime) && (evenement['startDatetime'] = evenement?.start?.dateTime ) ;
    const listcalendarevents =   store.getState().calendar.listcalendarevents; 
    const othersevents = listcalendarevents.filter(x=>x.id!==evenement.id) 
    store.dispatch(allcalendarevents([...othersevents, evenement]))
}

export const deleterdvgooglecalendar= (id)=>{
    const listcalendarevents =   store.getState().calendar.listcalendarevents; 
    const othersevents = listcalendarevents.filter(x=>x.id!==id);
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]); 
   store.dispatch(allcalendarevents([...othersevents]))
    try{ axios.post(  `/googleapis/deleteevent`,{id ,email:user['email']},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}});
        }catch(err){ console.log('ERREUR DANS LA SUPPRESION DE L EVENEMENT') }
}

export const searchrdvgooglecalendar= async()=>{
    const lead = store.getState().lead.leadactu;
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]); 
    let testregexid =  new RegExp(lead.id, 'g');
    const listcalendarevents =   store.getState().calendar.listcalendarevents; 
    const rdvclient = listcalendarevents.filter(x=>x.description? x.description.match(testregexid)?x.description.match(testregexid)[0] :undefined  :undefined  ) ;
    if(rdvclient.length>0){ return rdvclient[0]}
    let reqevent = await   axios.post(  `/googleapis/geteventsbyclientid`,{email:user['email'], id:lead.id},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}})
    return  reqevent.data.length>0?   reqevent.data[0]:{} 
   
}




export const rdvfromcalendar = (eventtocalendar)=>{
   
    const lead = store.getState().lead.leadactu;
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]); 
    const screencalendarrelance = store.getState().display.screencalendarrelance;
    
    const newstatut = screencalendarrelance?( progressions_contact.includes(lead.statut_client)?'contact_relance':progressions_devisenvoye.includes(lead.statut_client)?'devisenvoye_relance':
                                                    progressions_attentedocuments.includes(lead.statut_client)?'attentedocs_relance':progressions_attentesignature.includes(lead.statut_client)?'attente_signature_relance':'contact_relance'):
                                                  ( progressions_contact.includes(lead.statut_client)?'contact_rdv':progressions_devisenvoye.includes(lead.statut_client)?'devisenvoye_rdv':
                                                    progressions_attentedocuments.includes(lead.statut_client)?'attentedocs_rdv':progressions_attentesignature.includes(lead.statut_client)?'attente_signature_rdv':'contact_rdv');  // DEV REVOIR
  
    const champslead = {'date_rdvrelance': eventtocalendar.startDatetime,'statut_client':newstatut, commercial : user['nom']  }

    // DEV
    screencalendarrelance  ?addEltHistory('relancefixe',`<div class='horoline1' >Relance fixée ${formatdatecalendar(eventtocalendar.startDatetime)}</div>` ):
                            addEltHistory('rdvfixe',`<div class='horoline1' >RDV fixé ${formatdatecalendar(eventtocalendar.startDatetime)}</div>` ) ;

    modifyonelead(champslead);
    rdvcalendar(eventtocalendar.startDatetime,eventtocalendar.note)
}


export const getallevents = (navigate) => async () => {

    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]); 
   
    const urlgetrdvs = (user['calendar']!=='google') ? `/calendar/getrdvs` : `/googleapis/getevents` ;
    const response = await axios.post(urlgetrdvs,{email:user['email']},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}})
    if(response.data.error==='errorauth'){ navigate('/login');  store.dispatch(allcalendarevents([])) ; return   }   
    store.dispatch(allcalendarevents([...response.data])) ;
    user['email']  && store.dispatch(defineCalendar(false)) ; 
};



  
  

