import React ,{ useState }from "react";
import { useSelector } from "react-redux";
import FormCourtier from "./FormCourtier";
import ButtonSimple from "../../Components/Buttons/ButtonSimple";
import PopupDataSaved from "../../Components/Popup/PopupDataSaved";

function Courtiers({mydata, setMydata, showformulaire, setShowformulaire, choice}) {
   
   
    const  dataformcourtier=  useSelector(state=>state.datagestion.dataformcourtier)


return(<>   
 <PopupDataSaved  courtier={dataformcourtier?.nomcommercial}  mail={dataformcourtier?.adminemail} />
            <div className="coordonnes_titre" >Création d’un nouveau cabinet</div> <br/>
            <ButtonSimple  texte="Commencer"   color='bouton_color_orange' size='bouton_size_normal'  onclick={()=>{setShowformulaire(true)}}   /> <br/>  
            {showformulaire  &&    <FormCourtier   showformulaire={showformulaire} setShowformulaire={setShowformulaire}   choice={choice}  />}

       </>)
}

export default Courtiers

