import React , { useState}  from "react";


// import { getinfoscourtier} from "../../utils/utils_courtier";

import { useUser } from "../../state/reactquery";

import Coordonnes from "./Coordonnees";
import Telephonie from './Telephonie'
import Equipes from "./Equipes";
import Courtiers from './Courtiers';
import StatsUse from './StatsUse' ;

// React QUerry bibliothque
import './EcranParametres.scss'

function EcranParametres(){

    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const [choice , setChoice]  = useState('coordonnees') ;
    const [showformulaire, setShowformulaire]  = useState(false) ; 
    const [mydata, setMydata] = useState({}) ;
    // useEffect( ()=>{   dispatch(getinfoscourtier()) ;},[dispatch]) ;
 
    const Tabs = {'coordonnees': {"nom":"Coordonnées","component":<Coordonnes mydata={mydata} setMydata={setMydata}  choice={choice} showformulaire={showformulaire} setShowformulaire={setShowformulaire}/>} ,
                //   'telephonie': {"nom":"Téléphonie","component": <Telephonie />} ,
                'equipes' : {"nom":"Équipes","component":<Equipes /> }} ;

    user?.roles?.includes("SUPERADMIN")     &&     ( Tabs['courtiers'] =  {"nom":"Courtiers","component":<Courtiers mydata={mydata} setMydata={setMydata}  showformulaire={showformulaire} setShowformulaire={setShowformulaire} choice={choice}   /> })
    user?.roles?.includes("SUPERADMIN")     &&     ( Tabs['courtiers'] =  {"nom":"Patron","component":<StatsUse  /> })

    return(<div className="ecranparametres" >

                <div className="ecranparametrestop"  >
                    <div className="ecranparametrestop_titre"  >Paramétrage du cabinet</div>
                    <div className="ecranparametrestop_menu"  >
                        {Object.keys(Tabs).map(id=>(<div id={id}  key={id} className={choice===id?"selected":""} onClick={(e)=>{setChoice(e.target.id)}} >{Tabs[id]["nom"]}</div>))}
                    </div>   
               </div>

                <div className="ecranparametresbottom" >
                    {Tabs[choice]['component']}
                </div>

           </div>)
}


export default EcranParametres
