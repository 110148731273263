import React , {useState, useEffect} from "react";

import { useUpdateTicket } from "../../state/reactquery";

import { convertdateAA } from "../../utils/utils_dates";

import ButtonSimple from "../../Components/Buttons/ButtonSimple";


import './Ticket.scss'

function TicketModal({ticket,setModal}){
 
  const [sourcemusic, setSourcemusic] = useState(0);
  const [btnplay, setBtnplay] = useState(true);
  const audioElement = document.querySelector("audio");
 

  useEffect(()=>{ ticket && setSourcemusic(ticket['recording_url']) ;   },[ticket])


  const playpause = ()=>{ 
    setBtnplay(btnplay=>!btnplay)  ; 
    audioElement &&  (btnplay?audioElement.play() : audioElement.pause())
  }

  function movetime(event) {
    const coef  = (event.clientX  -document.getElementById('audioplayer_filled').getBoundingClientRect().x) /   280 ; 
    audioElement &&  (audioElement.currentTime = parseInt(coef* (audioElement.duration)) );
    document.getElementById('audioplayer_filled').style.width = (coef*280)+'px'
  }

  useEffect(()=>{
    audioElement &&  audioElement.addEventListener("timeupdate", () => {   
      document.getElementById('audioplayer_filled') && (document.getElementById('audioplayer_filled').style.width = parseInt(audioElement.currentTime * 280 / audioElement.duration )+'px')
      });
  })
  const updateTicket = useUpdateTicket() ;
  const clotureticket = ()=> { updateTicket.mutate({...ticket,'statut': 'finish'});setModal(false)}

  return(<div className="ticketmodal_container" ><div className="ticketmodal" >
                <div className="ticketmodaltop" >
                      <div className="ticket_top" >{ticket['date_ticket'] &&  convertdateAA(ticket['date_ticket'])}</div>
                      <div className="ticket_detail" >
                          {(ticket['nom']  || ticket['prenom'] ) &&  <div className="ticket_detail_line1" > {ticket['nom'] ?ticket['nom']:'' }  {ticket['prenom'] ?ticket['prenom']:'' } </div>  }
                          {(ticket['tel_from'] ) &&  <div className="ticket_detail_line" > {ticket['tel_from'] }  </div>  }
                          {(ticket['email'] ) &&  <div className="ticket_detail_line" > {ticket['email'] }  </div>  }
                      </div>
                  {ticket['ticket_categorie'] &&   <div className="ticket_categorie" >{ticket['ticket_categorie']}</div>  } <br/>
                {ticket?.['ticket_tags']  &&  ticket?.['ticket_tags'].split(',').map((x,i)=> ( <div key={i}  className="ticket_tag" >{x}</div>) )}
                </div>

             <div className="ticket_divider" ></div>
             {ticket['voicemessage_resume'] && <> <div className="ticket_soustitre" >Résumé : </div>
                                                       <div className="ticket_resume" >{ticket['voicemessage_resume']}</div>
                                                       <div className="ticket_divider" ></div>
                                                      </>   } 
             {ticket['recording_url'] && <> <div className="audioplayer" >
                                                            <div  className={"audioplayer_btn "+(btnplay?'play':'pause')} onClick={playpause} ></div>
                                                            <div id="audioplayer_progress" className="audioplayer_progress"  onClick={movetime}  > <div  id="audioplayer_filled" className="audioplayer_filled"></div> </div>
                                                        </div>
                                                       <audio src={sourcemusic}> </audio>
                                                       <div className="ticket_divider" ></div>
                                                      </>   } 
            {ticket['voicemessage_transcription'] && <> <div className="ticket_soustitre" >Transcription : </div>
                                                            <div className="ticket_transcription" >{ticket['voicemessage_transcription']}</div>
                                                            <div className="ticket_divider" ></div>
                                                          </>   }             

              <div className="modalgrpbtn" >
                    <ButtonSimple  texte="Fermer" containerbouton='fullbuton white' color='bouton_color_blancgris noborder' size='bouton_size_normal' onclick={()=>{setModal(false)}}    />   
                    <ButtonSimple  texte=" Clôturer"  containerbouton='fullbuton' color='bouton_color_orange' size='bouton_size_normal' onclick={clotureticket}   />      
              </div>

  </div></div>)

}


export default TicketModal