import React  , {useEffect} from "react";
import axios from "axios";

import uploadcheck  from '../../images/uploadcheck.svg'; 
import uploaddel from '../../images/uploaddel.svg'; 

import './UploadFile.scss'
const classNames = require('classnames');


const lienbucket =`https://${process.env.REACT_APP_AWS_NAMEBUCKET}.s3.eu-west-3.amazonaws.com/courtiers`;  

function UploadFile({typefile,setImages,images}){
    // const [progress, setProgress] = useState(0);
     const mondoc='';


 const addimage = (e)=>{ setImages({...images, [typefile]:e.target.files[0] }) }
    


useEffect(()=>{
  document.getElementById(typefile) && document.getElementById('input'+typefile).addEventListener('dragover', () => {  document.getElementById(typefile).classList.add('hover') });
  document.getElementById(typefile) && document.getElementById('input'+typefile).addEventListener('dragleave', () => {  document.getElementById(typefile).classList.remove('hover') });
})          



   return(<div id={typefile}    className={"uploadlogo "+((mondoc&& mondoc.length >0 )?'uploaded':'uploadwait') } >
          <input id={'input'+typefile} className={classNames("uploadlogo_input"  , {"little":(mondoc&& mondoc.length >0 )    })  }  type="file" name={typefile}    onChange={addimage}   />
                <div className="uploadlogo_2">
                      <span className={"uploadlogo_2_top line1 "+((mondoc&& mondoc.length >0 )?'uploaded':'uploadwait')} >Cliquez ici ou glissez ici </span>
                      <span className={"uploadlogo_2_top line2 "+((mondoc&& mondoc.length >0 )?'uploaded':'uploadwait')} >SVG, PNG, JPG ou GIF (max. 800x400px)</span>
                      {(mondoc&& mondoc.length >0 ) &&  <img  src={uploadcheck} alt='imgcheck' />}<br/> 
                      
                      {/* {mondoc && (<div  className="validcontrat_linkdoc" >
                                      <a  className="uploadcontratlink" href={`${lienbucket}/${typefile}`}   target="blank" >   {typefile}</a>   
                                       <img id={typefile} className="uploaddel" src={uploaddel} alt='imgdel'  onClick={delfile} />  
                                    </div>)}     */}
                                        
                  </div>
                <div className={"uploadlogo_progress_container "+((mondoc&& mondoc.length >0 )?'uploaded':'uploadwait')} >
                  
                           { images[typefile]  && <div className={"uploadlogo_progress"} > </div> }
                </div>
            </div>)
}


export default UploadFile

