import React ,{  useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';


import { queryClient } from '../../state/queryClient';
import { useUser,useManagerleads, updateonemanagerlead,updatemultimanagerlead } from '../../state/reactquery';
import { initialiseLeadactu , modifyEcran  } from '../../state/redux';
import {socketio } from '../../utils/socket';
import { getallformules } from '../../utils/calculstarifs';
import { convertdateAA } from '../../utils/utils_dates';

import CalendarTwoDates from '../Calendar/CalendarTwoDates';

import meilleurassu from '../../images/origines/meilleurassu.svg';
import gads from '../../images/origines/gads.svg';
import fb from '../../images/origines/fb.svg';
import inconnu from '../../images/origines/inconnu.png';
import crm from '../../images/origines/crm.png';
import actualiser from '../../images/actualiser.png';

import './TabLeadsManager.scss';

const classNames = require('classnames');

const comparedates=(a,b)=>{ return     (new Date(b.date_reception)).getTime() - (new Date(a.date_reception)).getTime() }
const imagesorigine ={"meilleureassurance.com":meilleurassu, "fb": fb, "gads": gads, "crm":crm }

function TabLeadsManager(){
        const {data : manleads} = useManagerleads() ;
        const {data: user} = useUser(localStorage.getItem('token'))  ;

        const liste_commerciaux =   user['commerciaux']  
        const [listleadaffiches,setListleadaffiches] = useState([]);
       const [listidchangecom,setListidchangecom] = useState([]);
       const [clickall, setClickall]  = useState(false) ;    
       const [display, setDisplay]= useState(false) ;
        const [selectdate,  setSelecdate ]= useState({}) ;
        const [liststatut, setListstatut] = useState([])


       const [filtres, setFiltres] = useState({"commerciauxffiches": [...new Set(user['commerciaux'])] , selectdate :{} , selectonecom : 'all'  ,selectstatut: 'all' })

       const multifiltres = (filtres)=>{setFiltres({...filtres}) ;
                                        let leadsaff = manleads?.filter(x=>filtres['commerciauxffiches']?.includes(x.commercial))
                                                                .filter(x=>filtres['selectonecom']==='all'?x: x.commercial==filtres['selectonecom'])
                                                                .filter(x=>filtres['selectstatut']==='all'?x: x.statut_client==filtres['selectstatut'])
                                                                .filter(x=> new Date(x?.date_reception) > (filtres.selectdate.date1 ?? new Date('1970-01-01') ) && new Date(x?.date_reception) < (filtres.selectdate.date2 ?? new Date('2035-01-01') ) );
                                        setListleadaffiches(leadsaff)            
       }

       useEffect(()=>{ setListstatut([...new Set(manleads?.map(x=>x.statut_client))]);  multifiltres(filtres) },[manleads])

        useEffect(()=>{if(  !selectdate?.date1 ||  !selectdate?.date2 ){ return }
                        setDisplay(false)      ;
                        multifiltres({...filtres,selectdate:{ 'date1': selectdate?.date1 ,'date2': selectdate?.date2 }})       
        },[selectdate])

        const filterbycom =(e) =>{multifiltres({...filtres,'selectonecom': e.target.value }) }
        const filterbystatus = (e)=>{multifiltres({...filtres,'selectstatut': e.target.value })    }         
        const actudates = () =>{ setSelecdate({}) ; multifiltres({...filtres,selectdate:{}}) }
        const changecommaffiche=(e)=>{
                         filtres.commerciauxffiches.includes(e.target.id)?multifiltres({...filtres,commerciauxffiches:filtres.commerciauxffiches.filter(x=>x!==e.target.id)}):multifiltres({...filtres,commerciauxffiches:[...filtres.commerciauxffiches,e.target.id]});
        }

        const actuleadsmanager=()=>{     queryClient.invalidateQueries({ queryKey: ['managerleads','list']})  }
        
       function  socketoneleadchange(idlead,commercial_new){
                const leadtochange = {...manleads.filter(x=>x.id===idlead)[0] } ;
                const  commercial_old= leadtochange.commercial ; 
                const newlead = {...manleads.filter(x=>x.id===idlead)[0], commercial: commercial_new } ;
                socketio.getSocket().emit('changecommercialemit',JSON.stringify({lead: newlead,commercial_new , commercial_old}));
       }

       const changeMulticommerciaux =(e)=>{
                if(listidchangecom.length===0){return }
                listidchangecom.forEach((id)=>socketoneleadchange(id,e.target.value))   ;
                updatemultimanagerlead(listidchangecom, e.target.value)
                setListidchangecom([]) ;
                setClickall(false) ; 
        }

       const  clickallleads =()=>{  !clickall ? setListidchangecom(listleadaffiches.map(x=>x.id)) : setListidchangecom([])  ; 
                                     setClickall(clickall=>!clickall)  }
                                   
       
                
       return(<div id="managerleads" >
                      <div >
                        <div className='tabmanager_titre'> Leads Manager {user['nom']?.toUpperCase()} {listleadaffiches?.length} <img height="30"  onClick={actuleadsmanager}  src={actualiser} alt="actualiser"   /> </div>
                       
                        <div> {[...liste_commerciaux].map((com,index)=>(<div className='containercheckcom' key={index}> <input className='checkboxcom' type="checkbox" checked={filtres?.commerciauxffiches?.includes(com)} value={com}  id={com}  onChange={changecommaffiche}  /> {com && com.toUpperCase()} </div>))}</div>
                     </div>
                     <div className='divaffectliste' >
                       <div>Affectation de liste selectionnes a </div>    
                        <select     className='selectcom' onChange={changeMulticommerciaux} >
                                                                <option  value={null} >Commercial</option>
                                                              {user['commerciaux']?.map((x,i)=><option key={i}  value={x} >{x?.toUpperCase()}</option>)}
                                                          </select>
                     </div>
                        <table>
                                <thead><tr>
                             <td className='man0   textaligncenter'  >
                             <input type="checkbox" className={classNames("checkbox", { 'checkedyes': clickall })} onChange={clickallleads} checked={clickall} />
                    
                           
                                </td> 
                                        <td >  <div className="positionrelative"> <span onClick={()=>setDisplay(!display)} >Date de réception</span>  <img height="15"  onClick={actudates}  src={actualiser} alt="actualiser"   />   { display  && <CalendarTwoDates  selectdate= {selectdate}  setSelecdate = {setSelecdate } /> }</div></td>
                                     <td >  <span className="man1">Derniere modification</span></td>
                                        <td ><span className="man2">Prénom et Nom</span></td>
                                        <td className='pl0' ><span className="man3">Origine</span></td>
                                        <td className='pl0' ><span className="man3">Campagne</span></td>
                                        <td className='pl0' ><div className='tdcom' >
                                        <span className="man4">Commercial</span>
                                        <select    id='toto' className='selectcom' onChange={filterbycom} >
                                                                <option  value='all' >Commerciaux</option>
                                                              {user['commerciaux']?.map((x,i)=><option key={i}  value={x} >{x?.toUpperCase()}</option>)}
                                                          </select>
                                                        </div>
                                        </td>
                                        {/* <td className='pl0' ><span className="man5">Status</span></td> */}
                                        <td className='pl0' ><div className='tdcom' >
                                        <span className="man4">Status</span>
                                        <select     className='selectcom' onChange={filterbystatus}  >
                                                                <option  value='all' >Tous</option>
                                                              {liststatut.map((x,i)=><option key={i}  value={x} >{x?.toUpperCase()}</option>)}
                                                          </select>
                                                        </div>
                                        </td>
                                        <td></td>
                                </tr></thead>
                                <tbody className='tbodytabmanager' >
                             {manleads?.length > 0 &&   <List listleadaffiches={listleadaffiches}   listidchangecom ={listidchangecom} setListidchangecom= {setListidchangecom} />   }
                                </tbody>
                        </table>                                           
                </div>)
}

export default TabLeadsManager ;




                                           

const ListItem = ({x, listidchangecom, setListidchangecom})=>{ 
        const dispatch =useDispatch()
        const {data: user} = useUser(localStorage.getItem('token'))  ;
        const {data : manleads} = useManagerleads() ;

        const changeCom =  (e) => {  
                const actulead = manleads.filter(x=>x.id===e.target.name)[0];
                const commercial_old = actulead?.commercial ;
                const newlead = {...actulead,'commercial' :e.target.value} ;
        
                socketio.getSocket().emit('changecommercialemit',JSON.stringify({lead: newlead,"commercial_new" : e.target.value, commercial_old}));
                updateonemanagerlead(newlead)
        }
 

         const click = async (e) => {const lead =  manleads.filter(x=>x.id === e.target.closest('tr').id)[0]; 
                        if(e.target.closest('.casechangecom')===null  && e.target.closest('.checkbox')===null  ){
                        await dispatch(initialiseLeadactu(lead)); dispatch(getallformules()); dispatch(modifyEcran('ficheclient')) 
                } 
        }
                const clickcheckbox = (e) => {
                        setListidchangecom((prevSelected) => {
                                if (!prevSelected.includes(e.target.name)) {return [...prevSelected, e.target.name]; }
                                else { return prevSelected.filter((current) => current !== e.target.name);}
                        });
              };
        return (<tr id={x.id} key={x.id}  >
                        <td className='textaligncenter' >
                                <input type="checkbox" className={classNames("checkbox", { 'checkedyes': listidchangecom.includes(x.id) })} name={x.id} onChange={clickcheckbox} checked={listidchangecom.includes(x.id)} />
                        </td>
                        <td className='pl12'>{convertdateAA(x.date_reception)}</td>
                        <td className='pl12'>{convertdateAA(x.date_lastmodif)}</td>
                        <td onClick={click}  >
                                <div className='tablecasenomtel pl12' > <span>{x.nom} {x.prenom} </span>   <span>{x.telephone}</span> </div>
                        </td>
                        <td className='caseimg textaligncenter' >
                                <img height="30" src={imagesorigine[x.origine] || inconnu} alt="imageorigine" />
                        </td>
                        <td className=' textaligncenter'  >{x.campagne || 'pasdecampagne'}</td>
                        <td className='casechangecom textaligncenter' >
                                <select value={x.commercial} className='selectcom' name={x.id} onChange={changeCom} >
                                        {user['commerciaux'].map((x, i) => <option key={i} value={x} >{x?.toUpperCase()}</option>)}
                                </select>
                        </td>
                        <td className=' textaligncenter'  >{x.statut_client}</td>
                        <td><span className='nbappels'> {x.nbappels} appel{x.nbappels>1?'s':''} </span></td>
                </tr>)}

const List =({listleadaffiches=[],  listidchangecom, setListidchangecom  })=>{
        return(listleadaffiches.sort((a,b)=> comparedates(a,b))
                                .map(x=>(<ListItem x={x}  key={x.id} listidchangecom ={listidchangecom} setListidchangecom= {setListidchangecom} />   )))
}




























// Woodeex