import axios from "axios";
import { batch } from 'react-redux'
import { setCorell ,allFormules,filterFormules,  defineListeCompagnies,  defineListeCompagniesAffichees , modifyDisablebuttons } from "../state/redux";
import { dateeffetavanttoday ,dateconvert } from "./utils_dates";
let count ;



async function gettarifsfromcompagnie(dispatch,getState,endurl,lead,corell){

  try { const response = await axios({method:'POST', 'url' : `/${endurl}`,data:{...lead}, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
      if(corell!==getState().formules.corell){ return   }
      count-=1;

      const responseformules_id = response.data.map(x=>x.id);  const formulesactu = [...getState().formules.datas] ;
      const formulesactufiltres = formulesactu.filter(x=>responseformules_id.indexOf(x.id)===-1);
      const formulesactus = getState().formules.datas; 

      batch( () => {  dispatch(allFormules([...formulesactufiltres, ...response.data.filter(x=>x.prix!==0)]));
                      dispatch(filterFormules({'type':'all',})); 
                      dispatch(defineListeCompagnies([...new Set([...formulesactus,...response.data].map(x=>x.compagnie))].sort()));
                      dispatch(defineListeCompagniesAffichees([...new Set([...formulesactus,...response.data].map(x=>x.compagnie))].sort()));
        })

        } catch (err) {  count-=1;  return [] }
         count===1   &&  dispatch(modifyDisablebuttons(false)) ; 
}


export const getallformules = () => async (dispatch,getState) => {  
    const corell = Math.random() ;
  
    const lead = {...getState().lead.leadactu} ;
  
     batch(()=> { dispatch(setCorell(corell)) ; 
                    dispatch(allFormules([])); 
                    dispatch(filterFormules({'type':'liste', 'liste':[]})); 
                    dispatch(defineListeCompagnies([])) ; 
                    dispatch(defineListeCompagniesAffichees([])) ;  });
                    
        
      const [jour,mois,annee]= lead?.date_effet?.split('/')  ;
     
    if( !lead.date_naissance  || !lead.date_effet  || !lead.num_departement  ||   isNaN(new Date(annee+'-'+mois+'-'+jour))  ||   dateeffetavanttoday(lead.date_effet)){return []}

    const listeroutes =  [];
          //  if(window.location.hostname==='localhost'){listeroutes.push('allcompagnies/calcul') }
            if(localStorage.getItem('listecompagnies')===null  ||localStorage.getItem('listecompagnies')===''){
                  Object.keys(listegammes).map(x=> listegammes[x]['veriflead'](lead) && listeroutes.push(listegammes[x]['url']) )
              }else{  
                Object.keys(listegammes).map(x=>(localStorage.getItem('listecompagnies').indexOf(x)!==-1 && listegammes[x]['veriflead'](lead))  && listeroutes.push(listegammes[x]['url']) )
              }                                         
            listeroutes.map(compagnie=> gettarifsfromcompagnie(dispatch,getState, compagnie,lead,corell) );

    count =  listeroutes.length +1  ;
    count >1 && dispatch(modifyDisablebuttons(true)) ; 
    // setTimeout(()=>{dispatch(modifyDisablebuttons(false))}, 10000)

    };


const calculage = (date_naissance,date_effet)=>{
   return  Math.abs(new Date(new Date(dateconvert(date_effet))-new Date(dateconvert(date_naissance)).getTime()).getUTCFullYear() - 1970);
  }

const calculage31 = (date_naissance,date_effet)=> {
    return Math.abs(new Date(new Date(dateconvert(date_effet).substring(0,4)+'-12-31')-new Date(dateconvert(date_naissance)).getTime()).getUTCFullYear() - 1970)
}

const listedatesadultes =(lead)=>{return ['date_naissance','date_naissance_conjoint'].filter(x=>lead[x]!==undefined && lead[x]!==null);  }
const listedatesenfants=(lead)=>{return ['date_naissance_enf1','date_naissance_enf2','date_naissance_enf3','date_naissance_enf4','date_naissance_enf5'].filter(x=>lead[x]!==undefined  && lead[x]!==null);}


const verifages =(lead,age_max_adulte,age_min_adulte,age_max_enfant)=>{
    for(let i=0; i<listedatesadultes(lead).length ; i++){
        if(calculage(lead[listedatesadultes(lead)[i]],lead['date_effet']) > age_max_adulte){return false}
        if(calculage(lead[listedatesadultes(lead)[i]],lead['date_effet']) < age_min_adulte){return false}
    }
    for(let i=0; i<listedatesenfants(lead).length ; i++){ if(calculage(lead[listedatesenfants(lead)[i]],lead['date_effet']) > age_max_enfant){return false}}
    return true
}

const verifages31 =(lead,age_max_adulte,age_min_adulte,age_max_enfant)=>{
    for(let i=0; i<listedatesadultes(lead).length ; i++){
        if(calculage31(lead[listedatesadultes(lead)[i]],lead['date_effet']) > age_max_adulte){return false}
        if(calculage31(lead[listedatesadultes(lead)[i]],lead['date_effet']) < age_min_adulte){return false}
    }
    for(let i=0; i<listedatesenfants(lead).length ; i++){ if(calculage31(lead[listedatesenfants(lead)[i]],lead['date_effet']) > age_max_enfant){return false}}
    return true
}




const veriflead_solly= (lead)=>{if( lead.regime !== 'general'){return false}
                                return verifages(lead,85,18,28)}

const veriflead_ffa = (lead)=>{  return verifages(lead,61,18,26)}

const veriflead_henner  = (lead)=>{return verifages(lead,85,18,26)}

const veriflead_april  = (lead)=>{ return false
  return verifages(lead,85,18,26)}

const veriflead_cegema = (lead)=>{ return verifages(lead,85,18,28)}

const veriflead_malakoff = (lead)=>{
  if( ['97','98'].includes(lead?.code_postal?.substring(0,2)) || ['97','98'].includes(lead?.num_departement)){return false}
  return verifages(lead,100,18,28)}

const veriflead_asaf= (lead)=>{   return (calculage(lead['date_naissance'],lead['date_effet']) > 18  &&  calculage(lead['date_naissance'],lead['date_effet']) < 90 ) }

const veriflead_wazari= (lead)=>{ return verifages31(lead,85,18,26)}

const veriflead_neoliane = (lead)=>{    ;  return verifages(lead,100,18,28)}

const veriflead_alptis = (lead)=>{return verifages(lead,100,18,28)}

const veriflead_fma= (lead)=>{console.log('veriflead_fma',verifages(lead,86,54,27))  ;  return verifages(lead,86,54,27)}

export const listegammes={   'Malakoff':{'veriflead':veriflead_malakoff, 'url':'allcompagnies/calcul'}, 
                              'Cegema':{'veriflead':veriflead_cegema, 'url':'cegema/calcul'}, 
                              'AsafAfps':{'veriflead':veriflead_asaf, 'url':'asaf/calcul'}, 
                              'FMA':{'veriflead':veriflead_fma, 'url':'fma/calcul'}, 
                              'SollyAzar':{'veriflead':veriflead_solly, 'url':'sollyazar/calcul'}, 
                              'Neoliane':{'veriflead':veriflead_neoliane, 'url':'neoliane/calcul'}, 
                              'Alptis':{'veriflead':veriflead_alptis, 'url':'alptis/calcul_all'}, 
                              'FFA':{'veriflead':veriflead_ffa, 'url':'ffa/calcul_all'}, 
                              'April':{'veriflead':veriflead_april, 'url':'april/calcul_all'},  
                              'Henner':{'veriflead':veriflead_henner, 'url':'henner/calcul_all'},  
                              'Wazari':{'veriflead':veriflead_wazari, 'url':'wazari/calcul_all'} 
                             };


