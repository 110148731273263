import React ,{ useState , useEffect}from 'react';
import { useSelector , useDispatch} from 'react-redux';
import axios from "axios";

import { modifyDisplaymail } from '../../state/redux';
import { useUser  , addEltHistory } from '../../state/reactquery';
import { queryClient } from '../../state/queryClient';

import { popup } from '../../utils/actions_lead';
import { modifyonelead } from '../../utils/actions_lead';
import { valideemail } from '../../utils/validations';

import ButtonImgLeft from '../../Components/Buttons/ButtonImgLeft';
import croixclose from '../../images/croixclose.svg'; 
import uploaddel from '../../images/uploaddel.svg'; 

import './SendMail.scss';

const classNames = require('classnames');
const max_size_attachements = 10000000 ;

function SendMail({setShow}){
    const dispatch  = useDispatch() ; 
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const leadactu  = useSelector(state=>state.lead.leadactu);

    const [attachments, setAttachments] = useState([]);
    const [objet, setObjet] = useState('')
    const [message, setMessage] = useState('')
    const [email2, setEmail2] = useState('')
    const [totalsize, settotalsize] = useState(0)

    const disable = objet==='' || message==='' || totalsize>max_size_attachements || (email2.length >0 && !valideemail(email2)) ;

    useEffect(()=>{settotalsize([...attachments].map(x=>x.size).reduce((acc,x)=>acc+x,0))}, [attachments])
  
    const handleFileChange = (e) => {  setAttachments(attachments=>[...attachments,...e.target.files]);};

    const sendmail = async () => {   
            const datasmessage = new FormData();
            datasmessage.append("user", JSON.stringify(user));
            datasmessage.append("id_lead",leadactu.id);
            datasmessage.append("id_client",leadactu.id_client);
            datasmessage.append("courtier",leadactu.courtier);
            datasmessage.append("nom",leadactu.nom);
            datasmessage.append("prenom",leadactu.prenom);
            datasmessage.append("From", user['postmail']);
            datasmessage.append("To", leadactu.email);
            datasmessage.append("email2",  email2);
            datasmessage.append("Subject", objet);
            datasmessage.append("TextBody", message);
            for (let i = 0; i < attachments.length; i++) {datasmessage.append("attachments", attachments[i]);}
            try { await axios.post(  `/email/send/`, datasmessage, { headers: {"Content-Type": "multipart/form-data"} });
                    queryClient.invalidateQueries({ queryKey: ['clientmails',user['postmail'],leadactu.email]}) ;  
                    queryClient.invalidateQueries({ queryKey: ['listmails']})  ;
            }
            catch (error) { console.error(error); }
    };
  
    const envoyermessage =()=>{
          sendmail(); setObjet(''); setMessage('') ; setAttachments([]); dispatch(modifyDisplaymail(false))  ; setShow(false)  ; 
            if(leadactu.statut_client==='' || leadactu.statut_client===undefined){modifyonelead({statut_client :'contact'}); }
          addEltHistory('email',  `<div class='horoline1' > Email <span  className='horolink' >${objet} </span> envoyé. </div>`)
    }

    const deletefilefromliste = (e)=>{ setAttachments(attachments=>[...attachments].filter(x=>x.name!==e.target.id)) }

    return(<div className={classNames('sendmail_container')}   >
                    <div className='sendmail_top' > Nouveau Message à {leadactu.nom}  {leadactu.prenom}   
                              <span>({leadactu.email})</span>
                               <img   src={croixclose}  onClick={()=>{ setShow(false) }} alt="croixclose"   /> 
                    </div>

                    <input  className='sendmail_input'    placeholder='Objet'  value={objet}  onChange={(e)=>{setObjet(e.target.value)}}   />
                    <input  className='sendmail_input'    placeholder="Copie" value={email2}  onChange={(e)=>{setEmail2(e.target.value)}}  />
                    <textarea className='sendmail_textarea'  value={message}  onChange={(e)=>{setMessage(e.target.value)}}   />

                    <div className='sendmail_bottom'>
                              <div >
                            { totalsize>max_size_attachements &&  <div className='messagefilesbig' >Le fichier ne doit pas excéder 4mo</div>}
                                     <div className='containerlistenamefiles'>
                                    {[...attachments].map((x, index)=>(<div  key={index}  className='divfilenameinliste' >
                                                                     <div className='filenameinliste' id={x.name} key={x.name}  onClick={deletefilefromliste}  >{x.name}</div>
                                                                      <img id={x.name} className="uploaddel" src={uploaddel} alt='imgdel'  onClick={deletefilefromliste} />
                                                              </div>))}
                                    </div> 
                              </div>
                              <div className='sendmail_bottom_right'>  
                                    {/* <div className="attachements_jauge" ><div className={classNames("attachements_jauge_interne",{"error":totalsize>max_size_attachements})} style={{'width':totalsize>max_size_attachements?'70px':`${totalsize * 70 / max_size_attachements}px`}}></div></div>  */}
                                    <div className="inputmessageattachement_container"><input type="file" multiple onChange={handleFileChange} /></div>
                                    <ButtonImgLeft texte="Envoyer" color={disable?'bouton_color_disable':'bouton_color_bleu'} size='bouton_size_normal' image='imagemail' onclick={envoyermessage} /> 
                              </div>
                    </div>
              </div>)
}


export default SendMail