import React , {useState}  from "react";
import { useUsers } from "../../state/reactquery";
import EquipesLineUser from "./EquipesLineUser";
import EquipesUserAddModif from "./EquipesUserAddModif"
import './Equipes.scss'


function Equipes(){
      const {data:users}  = useUsers() ;
      const [showadduser, setShowadduser] = useState(false)
     


   return(<><div className="equipestop" ><h1>Gestion de vos équipes et vendeurs</h1> </div>
            <section>
                   <h2>Utilisateurs</h2>
                  <p>Gérer vos utilisateurs,  les droits d’accès ainsi que l’équipe </p>
                  <div className="tableutilisateurs" >
                        <div className="tableutilisateurs_line1" ><div>Utilisateur</div><div>Rôle</div><div>Équipe</div><div>Email et téléphone attribués</div></div>
                        {users?.length >0 &&  users?.filter(x=>x.id>0).map(user=><EquipesLineUser key={user.id} user={user}    setShowadduser={setShowadduser}  />)}
                        {showadduser && <EquipesUserAddModif type='adduser'    setShowadduser={setShowadduser} />}
                  </div>
                 {!showadduser &&  <div  className="ajouteruser" onClick={()=>{setShowadduser(true)}} >Ajouter un utilisateur</div>}
            </section>
        </>) 
}


export default  Equipes