import React , {useEffect, useState} from 'react';
import axios from 'axios';
import { useDispatch , useSelector} from 'react-redux';


import {   modifyDisplaysearchgestion ,initialiseLeadactu,modifyEcran  } from '../../state/redux';
import { useUser } from '../../state/reactquery';


import TabloGestion from './TabloGestion';


import './GestionRecherche.scss';
var filtre = /^[a-zA-Z -àâäéèêëïîôöùûüÿç]+$/;
function GestionRecherche(){
  const dispatch = useDispatch();
  const clients = useSelector(state=>state.datagestion.clients);
  
  const {data: user} = useUser(localStorage.getItem('token'))  ;
  const commerciaux= user.commerciaux;

  const [value, setValue ]= useState('');
  const [resultsearch , setResultsearch] = useState([])


  const findclient =(client,val)=>{
    let liste_nom = client.nom ? client.nom.indexOf(val)!==-1 : false ; 
    let liste_email = client.email ? client.email.indexOf(val)!==-1 : false;
    return (liste_nom||  liste_email ) 
  }

  const handleChange =async (e)=>{ setValue(e.target.value);
    
    if(e.target.value.length===0 ){  setResultsearch([])}
    else if(e.target.value.length<4){setResultsearch([...clients].filter(client=> findclient(client,e.target.value)) );}
    else{if(!filtre.test(e.target.value)){return   }
      const response = await axios.post(  `/clients/searchclient/`,{data: e.target.value?.trim(), commerciaux: commerciaux },{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
      setResultsearch([...response.data])
    }
  }
  const seachLead = async (e) => {
    if (e.key === 'Enter') { if(!filtre.test(e.target.value)){return   }
      const response = await axios.post( `/clients/searchclient/`,{data: e.target.value?.trim(), commerciaux: commerciaux } ,{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
      setResultsearch([...response.data])
    }
  }
  
    const clickline =async(id)=>{

      const exist_client = [...resultsearch].filter(x=>x.id===id)[0] ;dispatch(initialiseLeadactu(exist_client));dispatch(modifyEcran('ficheclientgestion')) ; 

      dispatch(modifyDisplaysearchgestion(false))
  }


    useEffect(()=>{ document.getElementById('inpusearch').focus() },[])

    return(<div className='search_container' >
                <div className='gestionsearch_content' >
                    <div className='search_content_top'> 
                        <div> <input  id="inpusearch"     type="text"  value={value} onChange={handleChange}  onKeyDown={seachLead}   />  </div>
                        <div className='search_close' onClick={()=>{  dispatch(modifyDisplaysearchgestion(false))}}   >  </div>
                    </div>
                    <div className='gestiontablesearch_container'  >
                 { resultsearch.length >0 &&  <TabloGestion id='tabsearch' clickline={clickline}  datas={resultsearch}  />}
                    </div>
                </div>
            </div> )
}


export default GestionRecherche
