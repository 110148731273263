
import React from "react";


import './ButtonStyle.scss' ;
function ButtonImgRight({texte, color, size,image, onclick}){


    return(<div className={"boutonbase boutonimgright "+color+' '+size+' '+image  }  onClick={onclick}  >   <div className="interieurbouton" > {texte}</div></div>)
}


export default ButtonImgRight