

import React , {useState , useEffect} from 'react';
import axios from 'axios';


import './EcranVip.scss'

const comparedates=(a,b)=>{ return     (new Date(b.date_reception)).getTime() - (new Date(a.date_reception)).getTime() }
const nblinesaffichees =  Math.trunc((window.innerHeight * 0.7) / 26 ) -  2;

function InputRecherche({seachLead,search,value, setValue}){
    return( <div style={{width:'900px',marginBottom:'40px',display:'flex' , justifyContent:'space-between'}}  >
                 <input   id="inpusearch"     type="text"  value={value}   onChange={(e)=>{ setValue(e.target.value);}}  onKeyDown={seachLead}  /> 
                 <button  onClick={search} style={{cursor: 'pointer',display:'block',height:'44px',lineHeight:'44px',color:'#FFF',backgroundColor:'#FF8001',border:'none' , borderRadius:'8px' , padding: '0 15px' , fontSize: '18px' , marginLeft:'200px' }} >Recherche</button>
        </div>)
}


var filtre = /^[a-zA-Z -àâäéèêëïîôöùûüÿç]+$/;
function EcranVip(){
    const [value, setValue ]= useState('');
    const [resultsearch , setResultsearch] = useState([])

    const [numpage, setNumpage] = useState(1);
    const [affiches, setAffiches] = useState([]);
   const nbpages = Math.trunc((resultsearch.length / nblinesaffichees)) +1;
   const reculer=()=>{ if(numpage!==1){  setNumpage(numpage-1) ;   }}
   const avancer=()=>{ if(numpage<nbpages  ){  setNumpage(numpage+1) ;   }}

    useEffect(()=>{ setAffiches([...resultsearch].sort((a,b)=> comparedates(a,b)).filter((x,i)=> i<=nblinesaffichees*(numpage) && i>=nblinesaffichees*(numpage-1) ))  },[numpage,resultsearch])
    const search =async ()=>{   if(!filtre.test(value)){return   }
        const response = await axios.post(  `/gestion/searchclient/`,{data: value} ,{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
        setResultsearch([...response.data])
        setAffiches([...response.data].sort((a,b)=> comparedates(a,b)).filter((x,i)=> i<=nblinesaffichees*(numpage) && i>=nblinesaffichees*(numpage-1) )) 
    }    
    const seachLead =  (event) => {  if (event.key === 'Enter') { search() }   }

    return(<div className='ecranvip'  >  
              <h2>Recherche de Client</h2>
              <InputRecherche   seachLead={seachLead}  search={search} value={value} setValue={setValue}  />
              <div className='tablesearchvip_container'  >
                ECRAN VIP
                     { resultsearch.length >0 && <div  className='tabgestion'  >
                        <table>
                                <thead><tr>
                                   <td ><span className="coll3">Commercial</span></td> 
                                   <td ><span className="coll4">Email</span></td>
                                   <td ><span className="coll1">Telephone 1</span></td>
                                   <td ><span className="coll1">Telephone 2</span></td>
                                        <td ><span className="coll5">Prénom et Nom  
                                                { nbpages >1 && <div className='navigation' > 
                                                                        <span className='moins'  onClick={reculer}>-</span>
                                                                        <span className='indicpage' > {numpage}/{nbpages}  </span> 
                                                                        <span className='plus'   onClick={avancer}>+</span>
                                                                </div> }
                                            </span>
                                        </td>
                                </tr></thead>
                                <tbody className='tbodytabgestion' >{resultsearch.length > 0 &&  affiches.map(x=>(<tr key={x.id} >
                                                         <td > {x.commercial}</td>
                                                         <td > {x.email}</td>
                                                        <td ><span className="coll4">{x.telephone}</span></td>
                                                        <td ><span className="coll4">{x.telephone2}</span></td>
                                                        <td > {x.nom} {x.prenom}</td>
                                               
                                                </tr>))}
                                </tbody>
                        </table>
                </div>}
              </div>

          </div>)
}


export default EcranVip  ;

