import React  from "react";
import { useSelector } from 'react-redux';

import ButtonImgLeft from "../../Components/Buttons/ButtonImgLeft";
import CardFormuleDevis from "../../Components/CardsFormule/CardFormuleDevis";

import ImgPrevention from "../../images/images_postes/ImgPrevention"
import ImgOrthodontie from "../../images/images_postes/ImgOrthodontie"
import ImgImplant from "../../images/images_postes/ImgImplant";
import ImgHospi from "../../images/images_postes/ImgHospi";
import ImgProtheses from "../../images/images_postes/ImgProtheses";
import ImgSpecialistes from "../../images/images_postes/ImgSpecialistes";
import ImgLentilles from "../../images/images_postes/ImgLentilles";
import ImgLunettes from "../../images/images_postes/ImgLunettes";
import { senddevis } from "../../utils/actions_devis";


import './FormulesDevis.scss';

 const listecol1 = [{image:<ImgHospi color='gris' />,  titre :'Hospitalisation' , liste:["Honoraires","Chambre Particulière"]},
                {image :<ImgSpecialistes color='gris' /> ,  titre :'Consultations' , liste:["Généralistes","Spécialistes","Pharmacies"]},
                {image :<ImgLunettes color='gris' /> ,  titre :'Lunettes'    , liste:["Prise en charge"]},
                {image :<ImgLentilles color='gris' /> ,  titre :'Lentilles'    , liste:["Prise en charge"]},
                {image :<ImgProtheses color='gris' /> ,  titre :'Soins dentaires' , liste:["Détartrage","Prothèse"]},
                {image :<ImgImplant color='gris' /> ,  titre :'Implants Dentaires', liste:["Implants"]},
                {image:<ImgOrthodontie color='gris' /> ,  titre :'Orthodontie' , liste:["Orthodontie"]},
                {image:<ImgPrevention color='gris' /> ,  titre :'Prévention' , liste:["Consultations"]}]  ;

const BlockIntitulesPostes = ({index,titre,image,children})=>{ return(<div key={index} className="col1block">
                                <div className="col1block_divimage" >{image}</div>
                                <div  className="col1block_texte"><div className="col1block_titre">{titre}</div>{children}</div>
                        </div>) }


function FormulesDevis(){
     const formulesdevis = useSelector(state=>state.formules.formulesdevis);
  
    return (<div  className="formulesdevis_container">
                  { formulesdevis.length >0 &&  <div className="formulesdevis" >
                                                <div className="formulesdevis_firstcol" >
                                                                <div className="formulesdevis_top" >
                                                                         <ButtonImgLeft texte="Envoyer le devis"   color='bouton_color_orange' size='bouton_size_normal' image='imagesenddevis' onclick={()=>{senddevis()}}  /> 
                                                                </div>
                                                                {listecol1.map((l,index)=>(<BlockIntitulesPostes  key={index} index={index} image={l.image} titre={l.titre} >
                                                                                                {l.liste.map((t,i)=> (<div key={t+i+Math.random()} className='col1block_line'>{t}</div>) )}
                                                                                        </BlockIntitulesPostes>)) }
                                                        
                                                </div>
                                                        {formulesdevis[0]   &&   <CardFormuleDevis  formule={formulesdevis[0] }  />   }
                                                        {formulesdevis[1] &&   <CardFormuleDevis  formule={formulesdevis[1]} />}
                                                        {formulesdevis[2] &&   <CardFormuleDevis  formule={formulesdevis[2]}  last={true} />}
                                        
                    </div> }
            </div>)
}

export default FormulesDevis

