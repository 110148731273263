import React from "react";
import { useSelector , useDispatch} from "react-redux";


import { modifyPopupmultitel } from '../../state/redux';
import {  phone}  from '../../utils/phone'




import './PopupMultiTel.scss'
function PopupMultiTel(){
    const dispatch = useDispatch()
    const ficheclient = useSelector(state=>state.lead.leadactu);
   // console.log('PopupMultiTel',ficheclient.telephone2)
    const telefone= (e)=>{e.target.id==="1"? phone(ficheclient.telephone) : e.target.id==="2"? phone(ficheclient.telephone2)  : phone(ficheclient.telephone3) ;
                            dispatch(modifyPopupmultitel(false))    }

    return(<div  className="popupmultitel">
                <div id="1" onClick ={telefone}>{ficheclient.telephone}</div>
                {ficheclient.telephone2  &&   <div id="2" onClick ={telefone}>{ficheclient.telephone2 }</div> }
                {ficheclient.telephone3  &&  <div id="3" onClick ={telefone}>{ficheclient.telephone3 || '+33661084001'}</div>}
           </div>)
}



export  default PopupMultiTel




