import React , {useState}  from "react";
import { useDispatch , useSelector} from "react-redux";

import {   modifyChampsLeadmemo } from '../../state/redux';

import { dateeffetavanttoday ,convertdate} from "../../utils/utils_dates";
import { getallformules } from '../../utils/calculstarifs';
import { modifyonelead } from "../../utils/actions_lead";

import DateAdhesionPicker from "./DateAdhesionPicker";

 

import './DateAdhesion.scss'

const classNames = require('classnames');
function DateAdhesion({formulaire}){
    const dispatch = useDispatch();
    const lead = useSelector(state=>state.lead.leadactu);
    const disablebuttons = useSelector(state=>state.display.disablebuttons)
    const leadmemo = useSelector(state=>state.lead.leadmemo);
    const [opendatepiker, setOpendatepicker] =  useState(false)
    

    const listemois = ["Jamvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"]

    const today = new Date();
    const tomorrow =  new Date(Date.now()+24*60*60*1000);
    const riaday =  new Date(Date.now()+35*24*60*60*1000);
    const demain =(tomorrow.getDate()).toString().padStart(2,'0')+'/'+((tomorrow.getMonth()+1).toString()).padStart(2,'0')+'/'+tomorrow.getFullYear();
    const moisprochain= '01/'+(today.getMonth()===11?'01':(today.getMonth()+2).toString().padStart(2,'0'))+'/'+(today.getMonth()===11?today.getFullYear()+1:today.getFullYear()).toString();    
    const ria =  convertdate(riaday);

    const listdateeffet = {'choixdate0':demain,'choixdate1':moisprochain, 'choixdate2':ria  }


    const clickDate = async (e)=>{ 
         if(listdateeffet[e.target.id]){  formulaire? await dispatch(modifyChampsLeadmemo({...leadmemo, date_effet: listdateeffet[e.target.id]})): await modifyonelead({"date_effet": listdateeffet[e.target.id]});
                                         !formulaire && dispatch(getallformules());}
        (e.target.id==='choixdate3' &&  !opendatepiker  ) &&   setOpendatepicker(true); 
        (e.target.id==='choixdate3' &&  opendatepiker  ) &&   setOpendatepicker(false); 
        e.target.id!=='choixdate3' &&   setOpendatepicker(false); 
    }

   const selected_date = (index) =>{

    return formulaire && index!==4?leadmemo.date_effet=== listdateeffet[`choixdate${index}`] :
           formulaire && index===4?leadmemo.date_effet!== undefined  &&  [demain,moisprochain,ria].indexOf(leadmemo.date_effet)===-1    : 
           index!==4?  lead.date_effet=== listdateeffet[`choixdate${index}`] :
           lead.date_effet!== undefined   &&  [demain,moisprochain,ria].indexOf(lead.date_effet)===-1 
   }


    const listedates=["Demain","1er "+   (listemois[(new Date().getMonth()+1)%12]),"RIA","Choisir date "]; 
return(<><div  className={classNames("divdateahesion",{'disablebuttons': disablebuttons}  )}>  
                {!formulaire && <div className="errordatedepartement" >
                                          {( !lead.date_effet  || !lead.num_departement  || dateeffetavanttoday(lead.date_effet))&&<span>ATTENTION: </span>} 
                                          {( !lead.date_effet  ||dateeffetavanttoday(lead.date_effet))&&<span>Date d'effet invalide ! </span>}
                                          {( !lead.num_departement )&&<span>Indiquer un departemeent ou une adresse !</span>} 
                </div>}
                <div>
                 {listedates.map((x,index)=><button id={`choixdate${index}`} key={x} className={classNames('btn btn_choixdate',{'first':index===0},  {'last':index===listedates.length-1},{'unique':listedates.length===1},  {'selected':selected_date(index) }  )} onClick={clickDate} >  
                                                    {x+(index===3      ?' '+(formulaire?(leadmemo.date_effet!==undefined?leadmemo.date_effet: ''):(lead.date_effet!==undefined?lead.date_effet: '')) :'')}
                </button>)}
                </div>

      
          </div>
          { opendatepiker &&    <DateAdhesionPicker   formulaire={formulaire} setOpendatepicker={setOpendatepicker} />}
       </> )
}


export default DateAdhesion;

