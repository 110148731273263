import React from 'react'


import CardFormule from '../../Components/CardsFormule/CardFormule';

import './ListeFormules.scss';


const classNames = require('classnames');
const  ListeFormules =  React.memo(({setShowformule,datas,pt,closed})=>{
    return(
       <div id="lestesdesformules" className={classNames('listeformules',pt,{'close':closed===true} ,{'open':closed===false} )}>
         {datas.length >0 ? [...new Set(datas)].filter(x=>x.prix!==0 && x.prix!==null).map(x=><CardFormule   key={x.id} formule={x}  setShowformule={setShowformule} />)  : <span>Pas de formule.</span>}
       
       </div>
    )
}
)


export default ListeFormules