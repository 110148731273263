import React from 'react';


import FormCourtier from './FormCourtier';
import PopupDataCourtierSaved from '../../Components/Popup/PopupDataCourtierSaved';

import './Coordonnees.scss'
function Coordonnes({showformulaire, setShowformulaire,choice}){
      return(<>   <PopupDataCourtierSaved  />
                 <div className="coordonnes_titre" >Coordonnées de votre cabinet de courtage</div>
                <div className="coordonnes_soustitre" >Mettez à jour vos informations commerciales et légales</div>
                <br/>
                <FormCourtier showformulaire={showformulaire} setShowformulaire={setShowformulaire}  choice={choice} />
              </>)
  }
  
  
  export default  Coordonnes

