import { store , deleteChampsLeadmemo, modifyChampsLeadmemo} from "../state/redux";

import { listejsonall } from "./leadlistechamps";

export function getAge(date) {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {  age--; }
    return age;
  }
  export  function majuscule(nom){
    return  nom? nom.charAt(0).toUpperCase() +nom.slice(1).toLowerCase():''
  }
  
  
  export function  mineur(date_effet,date_naissance){
  if(!date_effet || !date_naissance) return ;
      const delta_annee = date_effet.split('/')[2]-date_naissance.split('/')[2]
      const delta_mois = date_effet.split('/')[1]-date_naissance.split('/')[1]
      const delta_jour = date_effet.split('/')[0]-date_naissance.split('/')[0]
     return delta_annee<18 || (delta_annee===18 && delta_mois<0 )|| (delta_annee===18 && delta_mois===0 && delta_jour<0)
   }
   
export const validtel =(valeur)=>{
    const total =valeur.split('').reduce((acc,val)=> ['0','1','2','3','4','5','6','7','8','9'].indexOf(val)!==-1? acc+1: acc  ,0)
    const reg = /^[0-9 _+*-]{1,25}$/;
    const regtest = reg.test(valeur.replace(' ',''))
    return (total > 9  && regtest)?  true : false
}
export const validnomprenom = (val)=>{  
  const regex = new RegExp("^[a-zA-Z ûüîïéèàêû']{2,}$"); 
  if(!val || val.length===0){return true}
   return regex.test(val)
}
    
export function validtrue(val){ return true}


export function valideemail(val){
  const reg_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg_email.test(val)}

export const validdateadhesion = (date)=>{
    const aujourdhui = Date.now()
  return  (date.getTime()<aujourdhui+31536000000 && date.getTime()>=aujourdhui) ? true : false;
}

export function validatenaissance(day,month,year,id){
    if(day!=="" &&  month!=="" && day!==undefined &&  month!==undefined  && year!==undefined  && year.length ===4){
        const today = new Date()
        const datenaissance =  new Date(year+'-'+month+'-'+day)
        if(!isNaN(datenaissance.getTime())){
            if( id==='souscripteur' &&  getAge(year+'-'+month+'-'+day)<18 ) { 
              store.dispatch(deleteChampsLeadmemo(['date_naissance'])); return false}
             if(getAge(year+'-'+month+'-'+day)>99) { 
                store.dispatch(deleteChampsLeadmemo([listejsonall[id]['date_naissance']])); return false}
            if(datenaissance < today   ){
              store.dispatch(modifyChampsLeadmemo( {[listejsonall[id]['date_naissance']]: day+'/'+month+'/'+year}));  return true}
        }else{
          store.dispatch(deleteChampsLeadmemo([listejsonall[id]['date_naissance']])); return false
        }
      
    }
    return false
  }
  

  export function validedatenaissance(date,id){
    let year = date.split('-')[0]; let month = date.split('-')[1]; let day = date.split('-')[2] ; 
    if(day!=="" &&  month!=="" && day!==undefined &&  month!==undefined  && year!==undefined  && year.length ===4){
        const today = new Date()
        const datenaissance =  new Date(year+'-'+month+'-'+day)
        if(!isNaN(datenaissance.getTime())){
            if( id==='souscripteur' &&  getAge(year+'-'+month+'-'+day)<18 ) { 
              store.dispatch(deleteChampsLeadmemo(['date_naissance'])); return false}
             if(getAge(year+'-'+month+'-'+day)>99) { 
                store.dispatch(deleteChampsLeadmemo([listejsonall[id]['date_naissance']])); return false}
            if(datenaissance < today   ){
              store.dispatch(modifyChampsLeadmemo( {[listejsonall[id]['date_naissance']]: day+'/'+month+'/'+year}));  return true}
        }else{
          store.dispatch(deleteChampsLeadmemo([listejsonall[id]['date_naissance']])); return false
        }
      
    }
    return false
  }
  
  export function validateadhesion2(day,month,year){  const date = new Date(year+"-"+month+"-"+day)
    if(!isNaN(date.getTime())){
        if(date.getTime()<Date.now()+31536000000 && date.getTime()>=Date.now()  ){
          store.dispatch(modifyChampsLeadmemo( {"date_effet": day+'/'+month+'/'+year}));  return true
        }else{store.dispatch(deleteChampsLeadmemo(["date_effet"])); return false }  
    }
    return false
  }
  


export const validadresse = (place)=>{
    return place.length >5 ? true : false
}


// const numerosecu = 173127511014917;

export function validsecu(num_secu) { 
    const filter=/^\d{15}$/; 
    let deb13=parseInt(num_secu.substring(0,13)); 
    const sdiv97=String(97 - (deb13%97));   // vrai valeur de la cle
    if ((num_secu.substring(6,7) === "A") ||(num_secu.substring(6,7) === "B")){ // CORSE 
        deb13 = (num_secu.substring(6,7)  === "A")? deb13-1000000:  deb13-2000000; 
        num_secu=num_secu.substring(0,6)+ "0" + num_secu.substring(7,15);
        }

    if (  !filter.test(num_secu) || (num_secu.substring(0,1) !== "1" && num_secu.substring(0,1) !== "2") || (parseInt(num_secu.substring(13,15)) !== parseInt(sdiv97))){ 

        return false; 
    }     
   return true;
}

export const  validiban = (iban)=> {
    let newIban = iban.replace(/ /g,'').toUpperCase(),
        modulo = function (divident, divisor) {
            let cDivident = '';
            let cRest = '';

            for (const i in divident ) {
                const cChar = divident[i];
                const cOperator = cRest + '' + cDivident + '' + cChar;

                if ( cOperator < parseInt(divisor) ) {
                        cDivident += '' + cChar;
                } else {
                        cRest = cOperator % divisor;
                        if ( cRest === 0 ) {
                            cRest = '';
                        }
                        cDivident = '';
                }

            }
            cRest += '' + cDivident;
            if (cRest === '') {
                cRest = 0;
            }
            return cRest;
        };

    if (newIban.search(/^[A-Z]{2}/gi) < 0) {
        return false;
    }

    newIban = newIban.substring(4) + newIban.substring(0, 4);

    newIban = newIban.replace(/[A-Z]/g, function (match) {
        return match.charCodeAt(0) - 55;
    });

    return parseInt(modulo(newIban, 97), 10) === 1;
}
