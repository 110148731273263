
import React , {useState , useEffect}  from "react";
import parse from 'html-react-parser';
 import axios from "axios";

 import Files from "./Files";
import TestFormules from "./TestFormules";


import './EcranDev.scss'
function EcranDev(){

    const [listdocs, setListdocs]  = useState([])  ;
    const [message, setMessage]  = useState('')  ;


    useEffect(async()=>{    const listedocs = await axios.get(`/files/listefiles/`)  ; setListdocs(listedocs.data)  },[])
    const uploadfileins3 = async (e)=>{ 
     

        const data = new FormData();
        data.append('file',e.target.files[0]);
        const newdoc = await axios.post(`/files/addfile/`, data ,{  headers:  { 'Accept': 'application/json',"Content-Type": "multipart/form-data"}});
        setTimeout(()=>{document.getElementById('filesnew').value= null ;  },200); 
      
       
}

const uploadonefile = (e)=>{ 
     

    const data = new FormData();
    data.append('file',e.target.files[0]);
    axios.post(`/files/uploadonefile/`, data ,{  headers:  { 'Accept': 'application/json',"Content-Type": "multipart/form-data"}});
    setTimeout(()=>{document.getElementById('addfile').value= null ;  },2000); 
  
   
}
const passerengras = ()=>{
    const maselection1 = window.getSelection() ;
   console.log('maselection1',maselection1) ;  
   const maselection2 = maselection1.toString() ;
   console.log('maselection2',maselection2) ; 
   const newmessage = message.replace(maselection1,`<b>${maselection1}</b>`) 
   setMessage(newmessage)
}

const clickmoi  = async() =>{  
    const reqleadsines = await axios.post(`/dev/ines/`,{ } )
    const leadsines = reqleadsines.data ;
// leadsines.length
    for(var i=0;i<leadsines.length;i++){
        const lead = leadsines[i]  ;
        const response = await axios({method:'POST', 'url' : `/wazari/calcul_all`,data:{...lead}, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
        console.log(lead.id,response.data.length) ; 
    }

}
const deletefile = (e)=>{ axios.post(`/files/delfile/`,{'fichier':e.target.id }  )}

        return(<div className="ecrandev">
            <h1>DEV</h1>
            <button onClick={clickmoi} >CLICK MOI</button><br/><br/>

            <input  id='addfile' type='file'  onChange={uploadonefile}    />
            <br />    <br /> 
{/* <TestFormules   /> */}
            {/* DIV EDITABLE */}
            {/* <button onClick={passerengras} >Gras</button>
               <br />
<div contentEditable  className='sendmail_textarea'  >

</div><br/><button onClick={()=>{

setMessage('<p>Allo</p>')

}} >Press</button> */}

{/* DIV EDITABLE */}

            {/* <table><tbody>
            {todos?.length>0 && todos.sort((a,b)=>a.id-b.id ).map(x=> <tr key={x.id} ><td  onClick={()=>{removeTodo.mutate(x.id)}} >{x.id}</td> <td>{x.titre}</td> <td>{x.resume}</td>  </tr>)  }
                <tr><td></td> <td><input  type='text' value={newtodo.titre} onChange={(e)=>{setNewtodo({...newtodo,'titre': e.target.value})}} /></td> <td><input  type='text' value={newtodo.resume} onChange={(e)=>{setNewtodo({...newtodo,'resume': e.target.value})}} /></td>  </tr>
            </tbody></table>
            <button onClick={addtodo} >Enregistrer</button>
            <table><tbody>
                <tr><td><input  type='text' value={newvaluetodo.id} onChange={(e)=>{setNewvaluetodo({...newvaluetodo,'id': parseInt(e.target.value)})}} /></td> <td><input  type='text' value={newvaluetodo.titre} onChange={(e)=>{setNewvaluetodo({...newvaluetodo,'titre': e.target.value})}} /></td> <td><input  type='text' value={newvaluetodo.resume} onChange={(e)=>{setNewvaluetodo({...newvaluetodo,'resume': e.target.value})}} /></td>  </tr>
            </tbody></table>
            <button onClick={updatetodo} >Enregistrer</button> */}
   
        {/*    /// AWS-V3
        <input  id='filesnew' type="file"  onChange={uploadfileins3}   /> <br/><br/><br/>
{listdocs.map(x=> (  <div id={x} key={x}  onClick={deletefile}  > {x} </div>   )   )} */}


    



             </div>)
}

export default EcranDev