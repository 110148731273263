import React from 'react';

import Jauge from './Jauge';

import ImgPrevention from "../../images/images_postes/ImgPrevention"
import ImgOrthodontie from "../../images/images_postes/ImgOrthodontie"
import ImgImplant from "../../images/images_postes/ImgImplant";
import ImgHospi from "../../images/images_postes/ImgHospi";
import ImgProtheses from "../../images/images_postes/ImgProtheses";
import ImgSpecialistes from "../../images/images_postes/ImgSpecialistes";
import ImgLentilles from "../../images/images_postes/ImgLentilles";
import ImgLunettes from "../../images/images_postes/ImgLunettes";


import './CardFormuleJauges.scss'
const  CardFormuleJauge = ({formule})=>{

  const listnb_formule = [formule.hospi,formule.consults_specialistes_nb,formule.optique_lunettes_nb,formule.optique_lentilles_nb,formule.dentaire_prothese_nb,formule.dentaire_implant_nb,formule.dentaire_orthodontie_nb,formule.prevention]

  return(<div className='cardformulejauge'> 
                {[1,2,3,4,5,6,7,8].map((x,j)=>{
                    const Image=    [ImgHospi ,ImgSpecialistes,ImgLunettes,ImgLentilles,ImgProtheses,ImgImplant, ImgOrthodontie,ImgPrevention][j]
                        return(<div  className='containtimagenivos'   key={Math.random()}  >
                                    <Jauge nb={listnb_formule[j]}     />
                                    <div  className='ht6' ></div>
                                    <Image color='gris'  /> 
                              </div>)
                })}
          </div>)

}

export default  CardFormuleJauge