import React from "react";

import { deleteuser } from "../../utils/utils_courtier";
import ButtonSimple from "../../Components/Buttons/ButtonSimple";

import './AlertePopupDelete.scss'
function AlertePopupDelete({lastname,firstname, telephone, id, setShowpopupdelete , type}){

     const textedelequipe = <><div>Êtes vous bien sûr ?</div><div> Vous vous apprêtez à supprimer </div><div>l’utilisateur <span>{firstname} {lastname}</span></div></>
     const textedelexterne= <><div>Êtes vous bien sûr ?</div>
                              <div> Vous vous apprêtez à supprimer le numéro </div>
                              <div> extérieur <span>{telephone}</span> </div>
                            <div>attribué à <span> {lastname}</span></div></>


    return (<div  className="alertepopupdelete">
        {type ==='delequipe' ? textedelequipe  : textedelexterne}
        <div>
            <ButtonSimple  texte="Annuler" containerbouton='fullbuton white' color='bouton_color_blancgris noborder' size='bouton_size_normal' onclick={()=>{setShowpopupdelete(false)}}    />   
            <ButtonSimple  texte="Confirmer"  containerbouton='fullbuton' color='bouton_color_orange' size='bouton_size_normal' onclick={()=>{deleteuser(id); setShowpopupdelete(false)}}   />      
        </div>


    </div>)
}


export default AlertePopupDelete