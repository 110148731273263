import axios from 'axios';
import { batch } from 'react-redux';

import { tracking } from './tracking';

import { addEltHistory } from '../state/reactquery';
import { store,allLeads ,initialiseLeadactu, modifyChampsLeadactu,modifyEcran, modifyPopupmultitel ,
            modifyDisplaycalendarrelance,deleteChampsLeadactu,modifyDisplaysearch  , modifyDisablebuttons } from '../state/redux';
import { getallformules } from './calculstarifs';
import { phone } from './phone';

import { setFileattentes } from '../state/redux';
import {  testdateafter } from './utils_dates';
import { progressions_gagne } from './listeprogression';

import { queryClient } from '../state/queryClient';
 


export const popup= (div_id)=>{
        document.getElementById(div_id).classList.remove('no');document.getElementById(div_id).classList.add('yes');
        setTimeout(()=>{
            document.getElementById(div_id)?.classList?.remove('yes'); document.getElementById(div_id)?.classList?.add('no');
        },3000)
    }



export const alertpbcontrat = (nom,prenom,email)=>{
    document.getElementById('divalertcontrattext').innerHTML =`<span>${nom}  ${prenom}</span>   <br/>  ${email}</>` ; 
    setTimeout(()=>{   document.getElementById('divalertcontrat').classList.remove('divalertnone')},200);
    setTimeout(()=>{ document.getElementById('divalertcontrat').classList.add('divalertnone')},8000);  
}


export const modifyonelead = async(champsleadchanged)=>{
    
    const lead = store.getState().lead.leadactu;
    const newlead = {...lead, ...champsleadchanged};
    
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
    const leads = store.getState().leads.leads;
    const othersleads = leads.filter(x=>x.id!==lead.id);
    const ecran = store.getState().display.ecran ; 
   
    //ecran !=="ficheclientgestion" && (newlead['commercial'] = user['nom']  ) ;
    store.dispatch(modifyChampsLeadactu({...newlead}));
    store.dispatch(allLeads([...othersleads,newlead]));
    if(lead.id!=="leadfirstline") {  axios.post(  `/clients/updateclients`,{...newlead});
                                        tracking({'ecran': ecran,'composant':'None' ,'fonction':'modifyonelead' ,'champschanged':JSON.stringify(champsleadchanged),'lead': JSON.stringify(lead)}) ;
                                         }

}

export const modifyoneleadechec = async(lead, champsleadchanged)=>{
   // console.log('modifyoneleadechec',champsleadchanged );
    const ecran = store.getState().display.ecran ; 
    const leads = store.getState().leads.leads;
    const othersleads = leads.filter(x=>x.id!==lead.id);
    const fileattentes = store.getState().leads.fileattentes; 
    const newlead = {...lead, ...champsleadchanged};
    if(lead.id==="leadfirstline") {return  }
    const prochainindex = fileattentes.indexOf(lead.id)  ;
     const prochainid = fileattentes[prochainindex];



     await  axios.post(  `/clients/updateclients`,{...newlead});
     tracking({'ecran': ecran,'composant':'None' ,'fonction':'modifyoneleadechec' ,'champschanged':JSON.stringify(champsleadchanged),'lead': JSON.stringify(lead)}) ;

     batch( () => {
            store.dispatch(modifyChampsLeadactu({...champsleadchanged}));
            store.dispatch(allLeads([...othersleads]));
        
        });
}


export const    modifyleadformulaire = (memofiche)=>{ 
    const lead = store.getState().lead.leadactu;
    const ecran = store.getState().display.ecran ; 
    const leads = store.getState().leads.leads;
    const othersleads = leads.filter(x=>x.id!==memofiche.id);
    store.dispatch(initialiseLeadactu({...memofiche}));
    store.dispatch(allLeads([...othersleads,memofiche]));
    if(memofiche.id==="leadfirstline") {return  } ;
    axios.post(`/clients/updateclients`,{...memofiche});
    tracking({'ecran': ecran,'composant':'None' ,'fonction':'modifyleadformulaire' ,'champschanged':JSON.stringify(memofiche),'lead': lead }) ;
}


export const searchandinitialiseclient = async(idlead,phonenow)=>{
  
    store.dispatch(modifyDisablebuttons(false)) ;
    const reqclient = await  axios.post(  `/clients/searchclientbyid`,{'id' :idlead });

    if(idlead==="leadfirstline"){
        let dateeffet = new Date((new Date()).getTime()+ 45*24*60*60*1000) ;  
        let [jour_effet, mois_effet, annee_effet ]= [dateeffet.getDate().toString().padStart(2,'0'),(dateeffet.getMonth()+1).toString().padStart(2,'0'),dateeffet.getFullYear()] ; 
        let [campagne, commercial] = window.location.hostname==='localhost'?["crm / selchesi","selchesi"]:["crm / demo","demo"] ; 
        const fakelead = {"civilite":"M","nom":"Demonstration","prenom":"Alex","adresse":"14 Rue du Théâtre","code_postal":"75015","origine":"meilleurtaux.com",
                            "ville":"Paris","num_departement":"75", "email":"gerard.martin260@gmail.com","telephone":"0757918904","date_naissance":"25/05/1989",
                            "regime":"general", "regime_conjoint":"general","campagne":campagne ,"commercial":commercial , 
                            "statut_client":"nontraite" ,"date_effet" : jour_effet+'/'+mois_effet+'/'+annee_effet ,"date_reception": (new Date()).toISOString()   , 
                            "id":"leadfirstline" ,"id_client":"leadfirstline_client"}
        store.dispatch(initialiseLeadactu(fakelead));
    }else {
        
        if(!reqclient?.data?.id){ store.dispatch(modifyEcran('dashboard')); store.dispatch(setFileattentes([]));return }
        await store.dispatch(initialiseLeadactu(reqclient.data));
    }


    batch( () => { store.dispatch(modifyDisplaysearch(false))  ; 
                    store.dispatch(getallformules());
                    store.dispatch(modifyEcran('ficheclient'))
                phonenow===true   && (   reqclient?.data?.telephone2!==undefined  ? store.dispatch(modifyPopupmultitel(true))   :   phone(reqclient?.data?.telephone) ) ; 
                });
}

export const phonefromlead =async (e)=>{
    const allleads = store.getState().leads.leads;
    const lead =  allleads.filter(x=>x.id === e.target.closest('tr').id)[0];
    searchandinitialiseclient(lead?.id,true)
}


export const demarrersequence = async(datas)=>{
    store.dispatch(setFileattentes([...datas.map(x=>x.id)]))
   await searchandinitialiseclient(datas?.[0]?.id,true) ;
   phone();
}




export const phonefromleadinjoignables =async (e)=>{
    const allleads = store.getState().leads.leads;

    const leadinjoignables= allleads.filter(x=>x.statut_client?.includes('nrp')).sort((a,b)=>new Date(new Date(b.date_lastmodif) - new Date(a.date_lastmodif)));
    const lead =  leadinjoignables.filter(x=>x.id === e.target.closest('tr').id)[0]; 
    const listidall = leadinjoignables.map(x=>x.id);
    const indexlead = listidall.indexOf(lead.id);
    const listidafter = listidall.filter((x,i)=> i>=indexlead);
    store.dispatch(setFileattentes([...listidafter]))
    searchandinitialiseclient(lead?.id,true)

}


export const modifyhot =  (lead)=>{
    // DEV
    addEltHistory('qualiflead', `<div class='horoline1' >Qualification du prospect en <span className=horobold'>${lead.hot===0?'Lead Chaud':'Passage a froid'} </span>  </div>`)  
    const ecran = store.getState().display.ecran ; 
    const newlead = {...lead, hot: lead.hot===0?1:0};
    const leads = store.getState().leads.leads;
    const othersleads = leads.filter(x=>x.id!==lead.id);

    store.dispatch(modifyChampsLeadactu({...newlead}));
    store.dispatch(allLeads([...othersleads,newlead]));
    if(lead.id!=="leadfirstline") {   axios.post(  `/clients/updateclients`,{...newlead});
                                       tracking({'ecran': ecran,'composant':'None' ,'fonction':'modifyhot' ,'champschanged':JSON.stringify({hot: lead.hot===0?1:0}),'lead': JSON.stringify(newlead)}) ;

}

}



// A REVOIR : HOMOGENEHISER ....
export const senddevisfromfiles =async ()=>{
    await   store.dispatch(deleteChampsLeadactu(['formuledevis1','formuledevis2','formuledevis3']));
    const lead = store.getState().lead.leadactu; 
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);

   let leadchampsmodifs = {'statut_client':progressions_gagne.includes(lead.statut_client)?lead.statut_client:
                                            lead.statut_client?.includes('rdv')?'devisenvoye_rdv':
                                            lead.statut_client?.includes('relance')?'devisenvoye_relance':
                                            'devisenvoye',  commercial: user['nom']}; 
   
   modifyonelead(leadchampsmodifs);

    if(!lead.date_rdvrelance || !testdateafter(lead.date_rdvrelance)){store.dispatch(modifyDisplaycalendarrelance(true))}
    // DEV
    addEltHistory('emaildeviscrmsend',   `<div class='horoline1' > Email <span  className='horolink' >Devis conseiller hors CRM</span>  envoyé   </div>`)
}
