import React  , {useState} from "react";
import {useSelector ,  useDispatch} from 'react-redux';
import axios from 'axios';


import { queryClient } from '../../state/queryClient';
import { addEltHistory } from "../../state/reactquery";
import { modifyDisplaygestionnewcontrat} from '../../state/redux';


import { threeyearsafter , convertdate } from "../../utils/utils_dates";
import { modifyonelead } from "../../utils/actions_lead";

import FormulaireContrat from "./FormulaireContrat";
import ButtonSimple from "../Buttons/ButtonSimple";

import croixclose from '../../images/croixclose.svg';

import './CardValidContrat.scss';


function CardNewContratGestion(){
    const dispatch = useDispatch() ;
    const ficheclient = useSelector(state=>state.lead.leadactu);
    const [datacontrat, setDatacontrat] = useState({});

    const validcontrat = async()=>{
            let contrat={...datacontrat,'email': ficheclient.email,'id_client': ficheclient.id_client ,'commercial':ficheclient.commercial, 'date_effet': (new Date(datacontrat.date_effet)).toLocaleDateString('fr-FR')   , courtier:ficheclient.courtier } ;
            const leadupdate =  {'commercial':ficheclient.commercial,'email':ficheclient.email,'formulesigne_compagnie' : contrat.compagnie , 'formulesigne_gamme' : contrat.gamme ,
                                'formulesigne_formule' : contrat.formule ,'date_effet' : (new Date(datacontrat.date_effet)).toLocaleDateString('fr-FR')   ,'num_contrat':contrat.num_contrat , 'formulesigne':'XXX_'+contrat.prix, 
                                'date_rdvrelance':threeyearsafter(ficheclient.date_lastmodif),'documents': 1 ,'statut_client':'gagne','hot':0 } ;//'recyclage' : 2 , 
            modifyonelead({...leadupdate}) ;   
            addEltHistory('leadgagne', `<div class='horoline1' > <span className=horobold'>Lead passé en Gagné !</span> </div>` )    
            Object.keys(leadupdate).map(key=>(leadupdate[key]==="" ||leadupdate[key]===null || leadupdate[key]===undefined )&& delete leadupdate[key]);
            axios.post(`/gestion/updateclient`,{id:ficheclient.id,...ficheclient,...leadupdate },  {headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'} });
            await axios.post(  `/contrats/writecontrat/`,contrat , {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
            queryClient.invalidateQueries({ queryKey: ['contrats',ficheclient?.email,ficheclient?.courtier]}) ;
            dispatch(modifyDisplaygestionnewcontrat(false))
     }


    return( <div className="container_formulaire"> 
                <div id='cardpassgagne'  className='cardpassgagne'>
                        <div className="cardpassgagneclose" ><img src={croixclose}  alt="imgclose" onClick={()=>{dispatch(modifyDisplaygestionnewcontrat(false))}}  />       </div>
                        <div className='cardpassgagne_top cardpassgagne_top_debut ' >
                            <div className='cardpassgagne_top_titre'> Enregistrer Nouveau Contrat                                    </div>
                        </div>
                        <FormulaireContrat datacontrat={datacontrat} setDatacontrat={setDatacontrat} />  
                        <div className='cardpassgagne_buttons'>
                        <ButtonSimple  texte="Continuer"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'   onclick={validcontrat}  
                                                      disable={(!datacontrat['compagnie'] || !datacontrat['formule'] || !datacontrat['prix'] || !datacontrat['num_contrat']|| !datacontrat['date_effet'])}  />
                        </div>
                </div>
            </div>)
}



export default CardNewContratGestion