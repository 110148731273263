import React , {useState}  from "react";
import { useSelector, useDispatch} from 'react-redux';
import axios from 'axios';


import { modifyDisplayresiliation } from '../../state/redux';
import { useUser } from "../../state/reactquery";
import { queryClient } from '../../state/queryClient';


import ButtonSimple from "../../Components/Buttons/ButtonSimple";

import croixclose from '../../images/croixclose.svg';

import './CardResiliation.scss';
const listmodes = {"resilmail":"Par email, envoyé par le client","nomandat":"En recommandé, envoyé par le client","mandat":"En recommandé, avec mandat"   }
const listmotifs = {'echeance':'A échéance','augmentation':'Augmentation','chatel':'Loi Châtel','modifrisque':'Modif. du risque','ria':'Infra-annuelle','retractation':'Rétractation',}
const listchamps1 ={'num_anciencontrat':'N. Ancien Contrat','date_resile':'Date de résiliation'}  //,'dateeffet_contratresil':"Date d'effet contrat (rétractation)"
const listchamps2adresse ={'compagnie':'Nom de la compagnie','adresse_compagnie':'Adresse de la compagnie','complement_adresse_compagnie':"Complément d'adresse",'codepostal_compagnie':'Code postal','ville_compagnie':'Ville',}
const listchamps2email = {'compagnie':'Nom de la compagnie',"email_compagnie" : "Email de la compagnie"}


const classNames = require('classnames');
function CardResiliation(){
    const dispatch = useDispatch() ;
    const lead = useSelector(state=>state.lead.leadactu);
    const {data: user} = useUser(localStorage.getItem('token'))  ;

    const [etape, setEtape] = useState(0) ;

    const [datasresil, setDatasresil] = useState({'objet':'echeance', 'type_resil': 'A échéance', 'type' :"resilmail"  });

    const line1 = datasresil['type']==="resilmail"?<>Par <span>email</span>, envoyé par le client</>:
                  datasresil['type']==="mandat"?<>En recommandé, <span>avec mandat</span></>:
                                                <>En recommandé, <span>envoyé par le client</span></>;

    const line2 = datasresil['objet']==="echeance"?<>Contrat à <span>échéance</span></>:
                  datasresil['objet']==="augmentation"?<>Pour cause d'<span>augmentation</span></>:
                  datasresil['objet']==="chatel"?<>Par la loi <span>chatel</span></>:
                  datasresil['objet']==="modifrisque"?<>Pour cause de <span>modification de risque</span></>:
                                                      <>Résiliation <span>Infra-annuelle</span></>;

    const messagefin = datasresil['type']==="resilmail"?<>S’assurer qu’il envoie bien la lettre par email ou dans l'espace client de sa compagnie.  </>:
                       datasresil['type']==="mandat"?<>Après signature, vous recevrez une notification avec la lettre de résiliation</>:
                                                      <>S’assurer qu’il envoie bien la lettre  en recommandé</>;

    const texte = etape===0?<></>:etape===1? <>{line1}</>:etape===2? <>{line1}<br/>{line2} </>: etape===3? <>{line1}<br/>{line2}<br/>{datasresil['compagnie']?datasresil['compagnie']:<></>} </>:
                    <>{line1}<br/>{line2}<br/>{datasresil['compagnie']?datasresil['compagnie']:<></>   }<br/>Contrat {datasresil['num_anciencontrat']?datasresil['num_anciencontrat']:<></>   } </>;

    const titrecontent = etape===0?'Quel mode de résiliation ?':etape===1?'Quel motif de résiliation ?': etape===2?'Détails du contrat': etape===3?'Coordonnées de la compagnie':''   ;

    const closemodal = ()=>{dispatch(modifyDisplayresiliation(false))}
    const clicktyperesil = (e)=>{setDatasresil({...datasresil,type : e.target.id   }) ;  }
    const definemotif =(e)=>{setDatasresil({...datasresil,'objet': e.target.id ,'type_resil': listmotifs[e.target.id] }) ;
                             e.target.id === 'retractation'? (listchamps1['dateeffet_contratresil']="Date d'effet contrat (rétractation)" )  : delete  listchamps1['dateeffet_contratresil'] }

    const linequestioninputradio = (id,texte,validate,click)=>(<div key={id} className={"linequestioninputradio  "+(Object.keys(listmotifs).includes(id) ? 'half':'all')}  >
                                                                <div  id={id}    className={classNames("buttonradio",{'validated':validate} ) }  onClick={click} ></div>
                                                                <div className="linequestioninputradio_question"  >{texte}</div>
                                                              </div>);
    const changechmap =(e)=>{  console.log('datasresil', datasresil)  ; setDatasresil({...datasresil,[e.target.id]: e.target.value })}
    const grplabelinput = (x,label)=>( <div key={x}  className={"containerlabelinputresil "  +((x==='complement_adresse_compagnie')?'big':'small') } ><div className="inputtextelabel">{label}</div>
                                                    <input id={x} type={x==='date_resile'|| x==='dateeffet_contratresil' ?"date":"text"}  value={datasresil[x]||''}  onChange={changechmap}
                                                            className="inputretractation"  />
                                         </div>) ;
                                          

      const resiliation = async ()=>{  await axios.post(  `/resiliation/resiliation`,{lead,user,datasresil}, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}} ) 
                                        queryClient.invalidateQueries({ queryKey: ['listmails',user['postmail'],lead.email]}) ;  
                                        queryClient.invalidateQueries({ queryKey: ['histories',lead.id]});
                                        queryClient.invalidateQueries({ queryKey: ['documents',lead.id_client]});
                                       }    


    const nextetape =  ()=>{  etape===3 &&  resiliation();
   setEtape(etape=>etape+1)  ; }  
    const listchamps2 = datasresil['type']==="resilmail" ? listchamps2email   :  listchamps2adresse   ;                  
    const questionnaire=  (etape===0?<>{ Object.keys(listmodes).map(x=>(  linequestioninputradio(x,listmodes[x],datasresil['type']===x,clicktyperesil)   ))   }  </>:
                                    etape===1?<>{ Object.keys(listmotifs).map(x=>( linequestioninputradio(x,listmotifs[x],datasresil['objet']===x ,definemotif)))} </>:
                                    etape===2?<>{ Object.keys(listchamps2).map(x=>( grplabelinput(x,listchamps2[x]) )) } </>:
                                    etape===3?<>{ Object.keys(listchamps1).map(x=>( grplabelinput(x,listchamps1[x]) )) } </>:<></>  );

   const disable =  (etape===2 &&  datasresil['type']!=="resilmail") ? (!datasresil['compagnie'] || !datasresil['codepostal_compagnie'] || !datasresil['adresse_compagnie'] || !datasresil['ville_compagnie']) :
                    (etape===2 &&  datasresil['type']==="resilmail") ? !datasresil['compagnie'] :
                      etape===3? (!datasresil['num_anciencontrat'] || !datasresil['date_resile'] )  : false
    
   const grpbuttons = etape===0?<><ButtonSimple  texte="Continuer"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'  onclick={()=>{setEtape(1)}}   />  </>:
                    etape===4 && datasresil['type']==="nomandat"?<><ButtonSimple  texte="Fermer"    containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'  onclick={closemodal}   />  </>:
                    etape===4 && datasresil['type']!=="nomandat"?<><ButtonSimple  texte="Fermer"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'  onclick={closemodal}   />  </>:
                                                                <><ButtonSimple  texte="Retour"   color='bouton_color_blancgris' size='bouton_size_normal'  onclick={()=>{setEtape(etape=>etape-1)}}    />   
                                                                <ButtonSimple  texte="Continuer"   color='bouton_color_orange' size='bouton_size_normal' disable={disable} onclick={nextetape}   />      </>
                    
   const titre = etape===4?<>{datasresil['type']==="mandat"? "Une demande de signature  pour le mandat vient d’être envoyée ": 
                                datasresil['type']==="nomandat"? "Le modèle de lettre de résiliation vient d’être envoyé ":
                                                        "La lettre de résiliation vient d’être envoyée par email"  }
                                à {lead.prenom} {lead.nom}</>:'Résiliation d’un ancien contrat'
   
    return( <div className="container_formulaire"> 
                <div className='cardresilitation'>
                        <div className="cardresilitationclose" ><img src={croixclose}  alt="imgclose" onClick={closemodal}  />       </div>
                        <div className={classNames('cardresilitation_top',{'cardresilitation_top_debut':etape<4} ,{'cardresilitation_top_fin':etape===4}  ) } >
                            <div className='cardresilitation_top_titre'>{titre}  </div>
                            <div className='cardresilitation_top_texte'>{texte}  </div>
                            {etape<4 &&  <div className='cardresilitation_top_boulettes'>{[0,1,2,3].map(x=><div key={x}  className={classNames('boulette',{'orange':etape>=x},{'grise':etape<x} ) } ></div>)}</div>}
                        </div>
                        {etape<4 ? <div className='cardresilitation_content'>
                                            <div className='cardresilitation_content_titre'>{titrecontent}</div><div className="cardresilitation_form" >{questionnaire} </div>
                                    </div>:  <div className='messagefin' > {messagefin}  </div>}
                        <div className='cardresilitation_buttons'>{grpbuttons}</div>
                </div>
            </div>)
}



export default CardResiliation