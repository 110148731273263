import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';

//axios.defaults.baseURL = process.env.REACT_APP_URLBACK;

axios.defaults.baseURL ='https://api.selchesitest.tk/'  ; 


if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
//   ,document.getElementById('root')
// );

ReactDOM.render( <App />,document.getElementById('root'));
