import React from "react";
import { useSelector , useDispatch} from 'react-redux';


import { setFileattentes } from "../../state/redux";


import {modifyhot ,  modifyonelead , searchandinitialiseclient  } from '../../utils/actions_lead';
import { verifleadgagne_arecycle ,nbjours ,convertdateAA } from '../../utils/utils_dates';
import { phone } from "../../utils/phone";


import ButtonImgLeft from "../../Components/Buttons/ButtonImgLeft";
import Telephonie from "../../Components/Telephone/Telephonie";


import meilleurassu from '../../images/origines/meilleurassu.svg';
import gads from '../../images/origines/gads.svg';
import fb from '../../images/origines/fb.svg';
import inconnu from '../../images/origines/inconnu.png';
import crm from '../../images/origines/crm.png';
import recyclage from '../../images/recyclage.svg';
import telephonienext from '../../images/telephonienext.svg';

import './FicheClientTop.scss'
const imagesorigine ={"meilleureassurance.com":meilleurassu, "fb": fb, "gads": gads ,  "crm":crm  , "recyclage":recyclage}
const classNames = require('classnames');
function FicheClientTop(){
    const dispatch = useDispatch() ;
    const lead = useSelector(state=>state.lead.leadactu);
    const { etat} = useSelector(state=>state.tel);
    const { fileattentes }= useSelector(state=>state.leads) ;

    const recyclelead = ()=>{ 
        let dateria = new Date(Date.now()+35*24*60*60*1000)
        const ria =  (dateria.getDate()).toString().padStart(2,'0')+'/'+(dateria.getMonth()+1).toString().padStart(2,'0')+'/'+dateria.getFullYear();
        modifyonelead({"statut_client":'nontraite','date_effet':ria ,  'date_reception' : new Date().toISOString().slice(0, 19).replace('T', ' ')}) ; 
    }

    
    const handleListeappel=async()=>{
        const newfileattente = [...fileattentes] ;
        newfileattente.shift()  ; 
        dispatch(setFileattentes([...newfileattente]));
        await searchandinitialiseclient(newfileattente[0],true);
        phone();
    }
    const numetape = lead?.statut_client==='nontraite'?1: lead?.statut_client?.includes('gagne') ?6: 
                    lead?.statut_client?.includes('attente_documents')?5:lead?.statut_client?.includes('attente_signature')?4:
                    lead?.statut_client?.includes('devisenvoye')?3:2; 

    const Step =({num})=>{
        const titles = ["Nouveau Lead ("+nbjours(lead)+"j)","Contact","Proposition","Attente Signature","Attente Docs","Gagné"]; 
        
        return(<div className={classNames('progressinfo','progressinfo_'+(num+1) ,lead?.statut_client?.includes('gagne')?'gagne':(num<numetape && !lead?.statut_client?.includes('echec') )? "finished" :(num<numetape )? "finishedechec": "nofinished")} > {titles[num]}{(num===numetape-1 && lead.statut_client)&& <span className='infospan' ></span>}
                        {(num===4  && lead.documents===1)  && <svg className='vudocs' width="12" height="10" viewBox="0 0 9.247 6.614"><path  d="M3.981,6.614,0,2.633,1.286,1.347,3.981,3.981,7.961,0,9.247,1.286Z" fill="#FFF"/></svg>}
                        {num!==0 && <svg  className='svgleft'  version="1.1"  viewBox="0 0 9 16"  ><path d="M0,0 L0,16  8,9 C8,9 9,8 8,7  L8,7  0,0  Z"  /></svg> }
                        {( num!==titles.length -1  )&&  <svg  className={classNames('svgrigth',lead?.statut_client?.includes('gagne')?'finishedgagne':  (num<numetape  && !lead.statut_client?.includes('echec') )? "finished" :num<numetape ? "finishedechec": "nofinished")}  version="1.1"  viewBox="0 0 9 16"  ><path d="M0,0 L0,16  8,9 C8,9 9,8 8,7  L8,7  0,0  Z"  /></svg> }
             </div>)}
                
    return(<div className="ficheclienttop"  >
                <div className="ficheclienttop_interieur"  >
                    <div className="ficheclienttop_interieur_part1"  >
                        <div className="ficheclienttopleft"  >
                            <div className={classNames("ficheclienttopleft_flamme", { "cold": lead.hot === 0 }, { "hot": lead.hot === 1 })} onClick={() => { modifyhot(lead) }}  ></div>
                            <div className="ficheclienttopleft_avatar"  >
                                <div className="avatar">  {(lead.nom && lead.prenom) ? lead.nom[0] + lead.prenom[0] : lead.email ? lead.email[0] : ''} </div>
                            </div>
                            <div className="ficheclienttopleft_description"  >
                                <div> {lead.nom ? lead.nom + ' ' + lead.prenom : lead.email}
                                    {(lead?.statut_client?.includes('rdv')) ? <span className='statutcontacttop'> RDV le {lead.date_rdvrelance && convertdateAA(lead.date_rdvrelance)}  </span> : <></>}
                                    {(lead?.statut_client?.includes('relance')) ? <span className='statutcontacttop'> RELANCE le {lead.date_rdvrelance && convertdateAA(lead.date_rdvrelance)}  </span> : <></>}
                                    {(lead?.statut_client?.includes('nrp')) ? <span className='statutcontacttop'> NRP </span> : <></>}
                                    <span className='nbappels'> {lead.nbappels} appel{lead.nbappels>1?'s':''} </span> 
                                </div>
                                <div  ><img height="30" src={lead.recyclage ? recyclage : imagesorigine[lead.origine] || inconnu} alt="imageorigine" /></div>
                            </div>
                        </div>
                        <div className="ficheclienttopright"  >

                            {verifleadgagne_arecycle(lead) && <ButtonImgLeft texte="Nouvelle proposition" color='bouton_color_orange' size='bouton_size_medium' image='imagerecyclage' onclick={recyclelead} />}

                            <Telephonie />
                            {fileattentes.length > 0 && <div className={classNames("telephonienext", { 'disableclick': etat === 'appel' })} onClick={handleListeappel} > <img src={telephonienext} alt='telephonienext' />  </div>}
                        </div>
                    </div>
                    {(!verifleadgagne_arecycle(lead)) && <div className="ficheclienttop_interieur_part2"  >
                        <Step num={0} /><Step num={1} /><Step num={2} /><Step num={3} /><Step num={4} /><Step num={5} />
                    </div>}
                </div>
            </div>)
}

export default FicheClientTop