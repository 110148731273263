import React , {useEffect, useState  }   from 'react';
import { socketio } from '../utils/socket';


import { useSelector , useDispatch} from 'react-redux';
import { useNavigate  } from "react-router-dom";
import Gleap from 'gleap';
// import fs from 'fs'

// ANIMATION REACTLOTTIE  PASSAGE GAGNE
import Lottie from 'react-lottie';
import * as animationlottie from '../images/animationlottie.json';


import { modifyDisplaymail, modifyEcran ,defineGleap , modifyPlayanimationlottie} from '../state/redux';
import { useUser } from '../state/reactquery';

import { initialiseSocket } from '../utils/socket';
import {telephone , initialiseTelephone }  from '../utils/phone';
import { formatdatenotif } from '../utils/utils_dates';
import { verifieversion } from '../utils/verifversion';

import EcranParametres from '../Ecrans/EcranParametres/EcranParametres';
import EcranManager  from "../Ecrans/EcranManager/EcranManager";
import EcranFicheClient  from "../Ecrans/EcranFicheClient/EcranFicheClient";
import EcranVip from "../Ecrans/EcranVip/EcranVip";
import EcranDev from "../Ecrans/EcranDev/EcranDev";
import EcranGestionLeads from "../Ecrans/EcranGestionLeads/EcranGestionLeads";
import EcranGestionOneClient from '../Ecrans/EcranGestionOneClient/EcranGestionOneClient';
import GestionRecherche    from '../Components/Gestion/GestionRecherche';
import EcranTickets from "../Ecrans/EcranTickets/EcranTickets";
import EcranEmails from "../Ecrans/EcranEmails/EcranEmails";
import EcranDashboard from '../Ecrans/EcranDashboard/EcranDashboard';


import Popup from '../Components/Popup/Popup';
import PopupAvertissementSignature from '../Components/Popup/PopupAvertissementSignature';
import PopupAvertissementResil from '../Components/Popup/PopupAvertissementResil';
import PopupALertPbContrat from '../Components/Popup/PopupAlertPbContrat';

import TelephonieDraggable from '../Components/Telephone/TelephonieDraggable';
import Formulaire from '../Components/Formulaire/Formulaire';
import Calendar from '../Components/Calendar/Calendar'
import Recherche from '../Components/Recherche';
import Postmail from '../Components/Email/PostMail';
import Sidebar from "../Components/Sidebar";
import AfterCall from '../Ecrans/EcranFicheClient/AfterCall';
import Echecs from '../Ecrans/EcranFicheClient/Echecs';
import CardResiliation from '../Ecrans/EcranFicheClient/CardResiliation';
import CardNewContratGestion from '../Components/Contrats/CardNewContratGestion';
import Notifs from '../Components/Notifs/Notifs';
import ModifContrat from '../Components/Contrats/ModifContrat';
import ChangeMdp from '../Components/ChangeMdp';
import CardChangeEmail  from '../Components/CardChangeEmail';
import CardSignature from '../Components/SigneGagne/CardSignature';
import CardGagne from '../Components/SigneGagne/CardGagne';



import './Application.scss';


const Ecrans={'dashboard':<EcranDashboard />,'manager':<EcranManager />,'ficheclient':<EcranFicheClient />,'params':<EcranParametres />,
              'ecranvip':<EcranVip />,'dev': <EcranDev />,'ecrantickets':<EcranTickets />,'ecranemails':<EcranEmails />,
              'ecrangestionleads':<EcranGestionLeads />,'ficheclientgestion':<EcranGestionOneClient />   }
            
function Application(){ 
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getTelephone} = telephone ; 

    const gleap = useSelector(state=>state.firstrequest.gleap)  ;
    const notifnew = useSelector(state=>state.display.notifnew)  ;
    const etat = useSelector(state=>state.tel.etat);

   
    const screenmodifcontrat = useSelector(state=>state.display.screenmodifcontrat)
    const [displaynotifs , setDisplaynotifs] = useState(false)
    const token =  localStorage.getItem('token') ;
    const {data: user} = useUser(token)  ;
    const {screeninfos, ecran , screencalendarrdv ,screencalendarrelance, screensearch, screenmail, popupqualiflead , popupechecs , 
           screenresiliation  ,screensearchgestion ,screengestionnewcontrat , screenchangemdp, screensignature  , screengagne ,screenchangeemail, playanimationlottie}= useSelector(state=>state.display);


    window.addEventListener('click', (event) => { 
     // socketio?.getSocket()?.connected===false  &&    initialiseSocket(); 
        (displaynotifs && (event.target.id!=='notifsbell' && !([...event.target.classList].includes('delnotif')) ))&&  setDisplaynotifs(false) ;
     });

     useEffect(()=>{ verifieversion() ;},[]);
 
    useEffect(()=>{if(!localStorage.getItem('token') || user?.['authenticate']===false){  navigate('/login'); return } },[user,navigate] )



    useEffect(()=>{ if(user?.roles?.includes("GESTION") && user?.roles?.length ===1 ){dispatch(modifyEcran('ecrangestionleads')) ;  }
                    },[socketio,user,dispatch] );
    

  useEffect(()=>{ 
    console.log('USEEFFECTAPPUSER', user) ; console.log('USEEFFECTAPPUSERID', user?.['id'])
    if(!user?.['id']){  return  ;}
      initialiseSocket(); initialiseTelephone()  },[user?.['id']] );
                      
    useEffect(()=>{
        if(!user?.['email'] || !gleap ){return }
        dispatch(defineGleap(false)) ; 
        Gleap.initialize("1SWQbVOLkiuU39dxBILcn1XrflnzIzJn");
        Gleap.setLanguage("fr"); 
        Gleap.identify(user['email'], { name: user['firstname'],email: user['email'], phone: user['telephone'] , customData:{'lastname': user['lastname'], 'courtier' :user['courtier']   } }) ;
        Gleap.registerCustomAction((customAction) => {
                if (customAction.name === "VISITE_GUIDEE") {
                  document.getElementById("startdemo").click() }
              });
    },[user,gleap,dispatch])


    const divnotifpopup = (notif)=>{ return( <div   id='notif_popup'  className='notif_popup' >
                                                <div className='notif_date' >{formatdatenotif(notif?.date_notif ?? new Date())}   {notif?.id} </div>
                                                <div className='notif_texte' dangerouslySetInnerHTML={{__html: notif?.notification}} />     
                                        </div>)
    }

    return(<div className="administration">
             {/* {(!user?.roles?.includes("GESTION") || !user?.roles?.includes("ADMIN") )&&<div style={{'position':'fixed','left':'10px','zIndex':10000, 'color':'red', 'fontSize':'10px','fontWeight':'bold'}} > {etat}</div>} */}
                        {user?.authenticate?<> <Popup   id="popupsignature"  /><Popup   id="popupsendmail"  /><Popup   id="popupdevis"  />
                                                <PopupAvertissementSignature /> 
                     
                                                {(etat!=='normal' && etat!=='error' && ecran!=='ficheclient' && ecran!==undefined ) && <TelephonieDraggable  />}
                                                <PopupAvertissementResil  /><PopupALertPbContrat />
                                               {/* <div className='contentanimationlottie'>
                                                    <Lottie options={{loop: false,autoplay: false, animationData: animationlottie,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'} }}
                                                        height={'100vh'}  width={'100%'}   isStopped={!playanimationlottie} />
                                                </div>  */}
                                                {popupqualiflead===true && <AfterCall />}
                                                {popupechecs===true && <Echecs  />}
                                                {screenresiliation && <CardResiliation />}
                                                {screensignature && <CardSignature /> }
                                                {screenchangeemail  &&  <CardChangeEmail  />}
                                                {screengagne &&  <CardGagne /> }
                                                {screenchangemdp && <ChangeMdp  />}
                                                {screenmodifcontrat && <ModifContrat  />}
                                                {screengestionnewcontrat && <CardNewContratGestion /> }
                                                {screeninfos && <Formulaire  />}
                                                {(screencalendarrdv||screencalendarrelance) && <Calendar />}
                                                {screensearch && <Recherche /> }
                                                {screensearchgestion && <GestionRecherche /> }
                                                {screenmail && <div className='search_container' > 
                                                                    <div className='search_close' onClick={()=>{dispatch(modifyDisplaymail(false))}}   >  </div>
                                                                   <Postmail />
                                                                </div>  }
                                                <Sidebar  setDisplaynotifs={setDisplaynotifs}   displaynotifs={displaynotifs} />
                                              
                                                {Ecrans[ecran || 'dashboard']}
                                          </>:<></>}
                        {notifnew?.id &&  divnotifpopup(notifnew)}
                        {displaynotifs && <Notifs />}
             </div>)
}

export default Application


