import React from "react"; 
import { useSelector } from "react-redux";



import { phonefromlead, phonefromleadinjoignables} from '../../utils/actions_lead';
import { verifleadgagne_arecycle } from '../../utils/utils_dates';
import { progressions_attentedocuments,progressions_attentesignature,progressions_echecs,progressions_relance , progressions_rdv} from '../../utils/listeprogression';

import TableLeadsDUMB from "../../Components/TabLeads/TableLeadsDUMB";

import './DashBoardLeads.scss' ;

function filtredatemois(date){
    let premierdumois =  (new Date((new Date()).getFullYear()+'-'+((new Date()).getMonth()+1)+'-01')).getTime() ;
    return (new Date(date)).getTime()  > premierdumois }


function DashBoardLeads(){

    const {leads , leadsfiltres,choiceliste}= useSelector(state=>state.leads);

    const leadstoday= leads.filter(x=>(x.statut_client==='nontraite')).sort((a,b)=>new Date(new Date(b.date_reception) - new Date(a.date_reception)));
    const leadsrecycle = leads.filter(x=>(verifleadgagne_arecycle(x) )).sort((a,b)=>new Date(new Date(b.date_reception) - new Date(a.date_reception)));
    const leadsrdv= leads.filter(x=>(progressions_rdv.includes(x.statut_client))).sort((a,b)=>new Date(a.date_rdvrelance) -new Date(b.date_rdvrelance) )   ;
    const leadsrelances= leads.filter(x=>((progressions_relance.includes(x.statut_client) &&  x.statut_client!=="gagne") || x.statut_client==="devisenvoye")).sort((a,b)=>new Date(new Date(b.date_rdvrelance) - new Date(a.date_rdvrelance)));
    const leadinjoignables= leads.filter(x=> x.statut_client ==='contact_nrp' || x.statut_client ==='devisenvoye_nrp').sort((a,b)=>new Date(new Date(b.date_lastmodif) - new Date(a.date_lastmodif)));
    const leadswaitsign= leads.filter(x=> progressions_attentesignature.includes(x.statut_client)).sort((a,b)=>new Date(new Date(a.date_lastmodif) - new Date(b.date_lastmodif))); 
    const leadwaitdocs= leads.filter(x=>progressions_attentedocuments.includes(x.statut_client)).sort((a,b)=>new Date(new Date(a.date_lastmodif) - new Date(b.date_lastmodif)));
    const leadshot= leads.filter(x=>x.hot===1&& !progressions_echecs.includes(x.statut_client)  ).sort((a,b)=>new Date(new Date(b.date_lastmodif) - new Date(a.date_lastmodif)));
    const leadsgagnes= leads.filter(x=>x.statut_client==="gagne").filter(x=>filtredatemois(x.date_lastmodif)|| x.id==="leadfirstline"  ).sort((a,b)=>new Date(new Date(a.date_lastmodif) - new Date(b.date_lastmodif)));
    const leadsimpayes=  leads.filter(x=>(x?.statut_client?.includes('impaye')));

    


    return(<div className="dashboardleads">  
                {choiceliste ==="newleads"  &&      <TableLeadsDUMB datas={[...leadstoday,...leadsrecycle]} id="newleads" titre="Nouveaux Leads"   clicktel={phonefromlead}   />}
                {choiceliste ==="rdv"  &&      <TableLeadsDUMB datas={leadsrdv} id="rdv" titre="RDV à venir"   clicktel={phonefromlead}  />}
                {choiceliste ==="relances"  &&      <TableLeadsDUMB datas={leadsrelances} id="relances"  titre="A relancer"    clicktel={phonefromlead}    />}
                {choiceliste ==="injoignables"  &&      <TableLeadsDUMB datas={leadinjoignables} id="injoignables"  titre="NRP"     clicktel={phonefromleadinjoignables}    />}
                {choiceliste ==="waitsign"  &&      <TableLeadsDUMB datas={leadswaitsign} id="waitsign" titre="Signatures en attente"     clicktel={phonefromlead}  />}
                {choiceliste ==="waitdocs"  &&   <TableLeadsDUMB datas={leadwaitdocs} id="waitdocs" titre="Documents en attente"  clicktel={phonefromlead} />}
                {choiceliste ==="hot"  &&  <TableLeadsDUMB datas={leadshot} id="hot" titre="Leads Chauds"   clicktel={phonefromlead}  />}
                {choiceliste ==="leadsgagnes"  &&      <TableLeadsDUMB datas={leadsgagnes} id="leadsgagnes" titre="Leads Gagnes"      clicktel={phonefromlead}  />}
                {choiceliste ==='impayes'  &&      <TableLeadsDUMB datas={leadsimpayes} id="impayes" titre="Impayés"      clicktel={phonefromlead}  />}
                {choiceliste ==='customchoice'  &&      <TableLeadsDUMB datas={leadsfiltres} id="customchoice" titre="Mes Filtres"      clicktel={phonefromlead}  />}
            </div>)
}


export default DashBoardLeads