import React from "react";


import './Jauge.scss'

const classNames = require('classnames');
function Jauge({nb}){
return ( <div className='containerjauge' >    
                {[1,2,3,4,5].map((x,i)=>{return  (<div key={Math.random()} 
                className={classNames('containtimagenivos_nivo',{'containtimagenivos_nivo_gris': i<5-nb} ,{'containtimagenivos_nivo_bleu': i>=5-nb})}  ></div>) })}
        </div> )
}

export default Jauge