import React  , {useState} from "react";
import {useSelector ,  useDispatch} from 'react-redux';

import { queryClient } from '../../state/queryClient';
import { useDocuments, addEltHistory} from "../../state/reactquery";
import {  modifyDisplayscreengagne} from '../../state/redux';

import { threeyearsafter  } from "../../utils/utils_dates";
import { modifyonelead } from "../../utils/actions_lead";
import { rdvgagne } from "../../utils/actions_echec_gagne";
import { activeanimation } from "../../utils/activeanimation";

import Upload from "../UploadFiles/Upload";
import ButtonSimple from "../Buttons/ButtonSimple";

import croixclose from '../../images/croixclose.svg';

import './CardGagne.scss';


let jsonliste ={ 'cni':[],'attestationro':[], 'ribfirst':[], 'ribsecond':[], 'kbis':[],'resil':[],'signature':[],'devis':[],'autre':[] }


const orderdocuments = (liste) => {
  let jsonliste ={ 'cni':[],'attestationro':[], 'ribfirst':[], 'ribsecond':[], 'kbis':[],'resil':[],'signature':[],'devis':[],'autre':[] }
    if(!liste){ return }
      for(let doc of liste ){ 
        jsonliste.hasOwnProperty(doc.type_file)?(jsonliste[doc.type_file]=[...jsonliste[doc.type_file] , doc]):(jsonliste['autre'] = [...jsonliste['autre'],doc]) 
      }
      return jsonliste
  }
 
const classNames = require('classnames');
function CardGagne(){
    const dispatch = useDispatch() ;

   const ficheclient = useSelector(state=>state.lead.leadactu);

    const {data: documents} = useDocuments(ficheclient?.id_client) ;
    const [step, setStep] = useState('intro') ;

    const datacontrat= {"compagnie":ficheclient.formulesigne_compagnie,"gamme":ficheclient.formulesigne_gamme ,"formule":ficheclient.formulesigne_formule,
                        "prix":ficheclient.formulesigne && ficheclient.formulesigne.split('_')[1]  , "num_contrat":ficheclient.num_contrat,"date_effet":ficheclient.date_effet};

    const listedocs =documents?  orderdocuments(documents) : jsonliste; 
    const test_uploadwithcontrat = ['cni','attestationro','ribfirst','signature'].every(elt=>[...Object.keys(listedocs).filter(x=>listedocs[x].length >0)].includes(elt) ) ;

    const passageleadgagne = async()=>{
      const leadupdate =  { 'date_rdvrelance':threeyearsafter(ficheclient.date_lastmodif),'documents': 1 ,'statut_client':'gagne', 'hot':0 } ;  //'recyclage' : 2 ,
      activeanimation()
      modifyonelead({...leadupdate}) ;   
      rdvgagne(ficheclient.id);
      addEltHistory('leadgagne', `<div class='horoline1' > <span className=horobold'>Lead passé en Gagné ! </span> </div>` )    
      queryClient.invalidateQueries({ queryKey: ['histories',ficheclient?.id]})     ;
     
    }


const contententintro = ()=>( <><div className='cardsignnonliste_titre'>Passer un lead en Gagné</div>
                <div className='cardpassgagne_top_boulettes'>{[0,1,2].map(x=><div key={x}  className={classNames('boulette',{'orange':0>=x},{'grise':0<x} ) } ></div>)}</div>
              
                    <div  className='cardgagnecontent'>
                          Le contrat est bien signé !<br/>Plus que quelques actions pour passer le lead en Gagné
                     </div>  
                     <ButtonSimple  texte="Continuer"   color='bouton_color_orange' size='bouton_size_normal buttonpaddinglr50'   onclick={()=>{setStep('documents')}}  />  </>       )

const contentuploaddocuments = () =>(<>
        <div className='cardsignnonliste_titre'>Passer un lead en Gagné</div>
        <div className='cardpassgagne_top_boulettes'>{[0,1,2].map(x=><div key={x}  className={classNames('boulette',{'orange':1>=x},{'grise':1<x} ) } ></div>)}</div>
            <div className="cardgagne_textes" >
                <div className="cardgagne_texte1" > Documents complémentaires</div>
                <div className="cardgagne_texte2" > Veuillez glisser les documents suivants</div>
            </div>

        <div className="cardgagne_uploadcontainer" ><Upload  /></div>  
        <div className='cardsignnonliste_buttons'>
                <ButtonSimple  texte="Retour" containerbouton='fullbuton white'   color='bouton_color_blancgris  noborder' size='bouton_size_normal'  onclick={()=>{  setStep('intro')}}    />   
                <ButtonSimple  texte="Continuer" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' onclick={()=>{setStep('fin') ; passageleadgagne()}}   disable={!test_uploadwithcontrat} />      
        </div>
</>)

const contentfin = ()=>(<> <div className='cardsignnonliste_titre'>Gagné !</div>
                        <div className='cardsignnonliste_titre'>Votre contrat avec Nicolas Pastenaque est validé </div>
                        <div className='cardgagne_fin'>
                        {datacontrat['compagnie']?datacontrat['compagnie']:<></>}<br/>
                                            {datacontrat['gamme']?datacontrat['gamme']:<></>} {datacontrat['nomformule']?datacontrat['nomformule']:<></>}  <br/>
                                            Contrat {datacontrat['num_contrat']?datacontrat['num_contrat']:<></>}<br/>
                                            Date d’effet :{datacontrat['date_effet']?  (new Date(datacontrat.date_effet)).toLocaleDateString('fr-FR'):<></>}
                        </div>
                                     
                        <ButtonSimple  texte="Fermer" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' onclick={()=>{dispatch(modifyDisplayscreengagne(false))}}    disable={false} />      
</>)

   const contentcard = step==='intro'?contententintro():step==='documents'?contentuploaddocuments():contentfin()  ;


    return( <div className="container_formulaire"> 
                <div id='cardgagne'  className='cardgagne'>
                <div className="closefenetre" ><img src={croixclose}  alt="imgclose" onClick={()=>{dispatch(modifyDisplayscreengagne(false))}}  />       </div>
       
                        {contentcard}
                    
                </div>
            </div>)
}



export default CardGagne