import React , {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { useUser } from '../../state/reactquery';
import { modifyDisplaysearchgestion } from "../../state/redux";


import './EcranGestionLeads.scss'

function EcranGestionLeads(){
    const dispatch = useDispatch() ;
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const [statleads,setStatleads] = useState({'data':{},'campagnes':[]}) ;
    const [listleads, setListeleads]  = useState([]);
    const [listchamps, setListechapms]  = useState({commerciaux :[], campagnes:[] , origines:[] , status:[]})
    const [multiselect, setMltiselect]  = useState({debut:(new Date()).toISOString().split('T')[0], fin : (new Date()).toISOString().split('T')[0] , 
                                                    'statut_client':'all', 'campagne': 'all'  , 'commercial': 'all' , "origine":'all' , 'type_date':'date_reception',
                                                    'courtier':user['nomdomaine']}) ;

    const filterselect =(e) =>{  setMltiselect({...multiselect,[e.target.id]: e.target.value }) }


    function listleadscom(myleads){  
        let listcamp =[]; let statjson ={} ;
                for(let mylead of myleads){ 
                    let com = mylead.campagne?(mylead.campagne.split('/')[1]?mylead.campagne.split('/')[1].trim():''):'' ; 
                    let camp = mylead.campagne?(mylead.campagne.split('/')[0]?mylead.campagne.split('/')[0].trim().toLowerCase():''):'' ;
                    !listcamp.includes(camp) && listcamp.push(camp)  ; 
                        if(statjson.hasOwnProperty(com)){
                                if(statjson[com].hasOwnProperty(camp)){ statjson[com][camp]+=1 }
                                else{statjson[com][camp]=1 }
                        }else{statjson[com]={}; statjson[com][camp]=1 }
    
        delete statjson[undefined] ; delete statjson[''] ; delete statjson['simon'] ;
    }
    setStatleads({'data'  : statjson   , 'campagnes': listcamp   })

    }
    const getleads = async()=>{

      const response =  await axios.post(`/gestion/getdataleads`,{...multiselect },  {headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'} });
      setListeleads(response?.data?.sort((a,b)=>new Date(a?.date_reception) - new Date(b?.date_reception)))
      listleadscom([...response?.data])
    }
    
    const getchamps = async()=>{
        console.log('GETCHAMPS')
        const response =  await axios.post(`/gestion/getchamps`,{'courtier':user['nomdomaine']},  {headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'} });
        setListechapms(response?.data)
      }

      console.log(user)


    useEffect(()=>{getleads() ; getchamps()},[])

    const   selectleads  = ()=>{getleads()}

    const generecsv = ()=>{
        const csvString = [ ["Date Reception","Email", "Telephone","Origine","Campagne" , "Commercial" , "Status" ],...[...listleads].map(item => [item.date_origine,item.email,item.telephone,item.origine,item.campagne,item.commercial,item.statut_client])]
                             .map(e => e.join(",")) .join("\n");
        var hiddenElement = document.createElement('a');  
        hiddenElement.href='data:text/csv;charset=utf-8,'+encodeURI(csvString);hiddenElement.target='_blank';hiddenElement.download ='fauxnumeros.csv';  
        hiddenElement.click();  
    } 

    let septjoursavant =  new Date().getTime()-7*24*60*60*1000;
    let dateday = new Date(septjoursavant); 
    let diff = dateday.getDate() - dateday.getDay(); 
    let dimanche = new Date((new Date(dateday.setDate(diff))).setHours(0,0,0)).toISOString();
    let samedi = (new Date((new Date(dimanche)).getTime()+6*24*60*60*1000)).toISOString()

    const premiermois = new Date()  ;     premiermois.setDate(1); premiermois.setMonth(premiermois.getMonth());
    const premiermoisprecedent = new Date()  ;     premiermoisprecedent.setDate(1); premiermoisprecedent.setMonth(premiermoisprecedent.getMonth() - 1);

const cliquebouton = async(e)=>{
    const selection = e.target.id==="assuragencysemaine" ? {debut:dimanche, fin : samedi, 'statut_client':'echec_fauxnum', 'campagne': 'all'  , 'commercial': 'all' , "origine":'assuragency', 'type_date':'date_reception' } :
                        e.target.id==="meilleureassurance.comsemaine"?{debut:dimanche, fin : samedi, 'statut_client':'echec_fauxnum', 'campagne': 'all'  , 'commercial': 'all' , "origine":'meilleureassurance.com', 'type_date':'date_reception' } :
                        e.target.id==="assuragencymois"?{debut:premiermois, fin : premiermoisprecedent, 'statut_client':'echec_fauxnum', 'campagne': 'all'  , 'commercial': 'all' , "origine":'assuragency', 'type_date':'date_reception' } :
                        e.target.id==="meilleureassurance.commois"?{debut:premiermoisprecedent, fin : premiermois, 'statut_client':'echec_fauxnum', 'campagne': 'all'  , 'commercial': 'all' , "origine":'meilleureassurance.com', 'type_date':'date_reception' } :null


    const response =  await axios.post(`/gestion/getdataleads`,{...selection , 'courtier' :user['nomdomaine'] },  {headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'} });
    setListeleads(response?.data?.sort((a,b)=>new Date(a?.date_reception) - new Date(b?.date_reception)))  ; 
    const csvString = [ ["Date Reception","Email", "Telephone","Origine","Campagne" , "Commercial" , "Status" ],...[...response?.data].map(item => [(new Date(item.date_origine)).toLocaleString("fr-FR",{timeZone:"Europe/Paris"}),item.email,item.telephone,item.origine,item.campagne,item.commercial,item.statut_client])]
                            .map(e => e.join(",")) .join("\n");
    var hiddenElement = document.createElement('a');  
    hiddenElement.href='data:text/csv;charset=utf-8,'+encodeURI(csvString);hiddenElement.target='_blank';hiddenElement.download ='fauxnumeros.csv';  
    hiddenElement.click();  

}


    return( <div className="divbilanleads" >
<input  className="leadstop_input" placeholder='Recherche' type="text"  onFocus={()=>{dispatch(modifyDisplaysearchgestion(true))}}     />
                        <div className="divbilanleads_choices">
                                <div className="nombre" >{listleads?.length ?? 0}</div>
                            <div className="containerselection wd150" >
                                <div className="labelinput" >Type de Dates</div>
                                <select id="type_date" className='selectchoice wd150' onChange={filterselect} >
                                    <option value='date_reception' >Date de reception</option>
                                    <option value='date_lastmodif' >Derniere Modification</option>
                                    <option value='date_efet' >Date effet Contrat</option>
                                </select>
                            </div>
        
                            <div className="containerselection wd125" >
                                <div className="labelinput" >Debut </div>
                                <input id='debut' type='date' value={multiselect?.debut} onChange={filterselect} />
                            </div>
                            <div className="containerselection  wd125" >
                                <div className="labelinput" >Fin </div>
                                <input id='fin' type='date' value={multiselect?.fin} onChange={filterselect} />
                            </div>
                            <div className="containerselection wd150" >
                                <div className="labelinput" >Origines</div>
                                <select id='origine' className='selectchoice wd150' onChange={filterselect} >
                                    <option value='all' >Toutes origines</option>
                                    {listchamps.origines?.length > 0 && [...new Set(listleads?.map(x => x.origine)),...listchamps.origines ]?.map((x, i) => <option key={i} value={x} >{x}</option>)}
                                </select>
                            </div>
                            <div className="containerselection wd150 "  >
                                <div className="labelinput" >Campagnes</div>
                                <select id='campagne' className='selectchoice wd150' onChange={filterselect} >
                                    <option value='all' >Toutes campagnes</option>
                                    {listchamps.campagnes?.length > 0 && [...new Set(listleads?.map(x => x.campagne)),...listchamps.campagnes ]?.map((x, i) => <option key={i} value={x} >{x}</option>)}
                                </select>
                            </div>
                            <div className="containerselection" >
                                <div className="labelinput" >Commercial</div>
                                <select id='commercial' className='selectchoice  wd140' onChange={filterselect} >
                                    <option value='all' >Tous commerciaux</option>
                                    {listchamps.commerciaux?.length > 0 && [...new Set(listleads?.map(x => x.commercial)),...listchamps.commerciaux ]?.map((x, i) => <option key={i} value={x} >{x}</option>)}
                                </select>
                            </div>
                            <div className="containerselection" >
                                <div className="labelinput" >Statuts</div>
                                <select id='statut_client' className='selectchoice  wd180' onChange={filterselect} >
                                    <option value='all' >Tous status</option>
                                    {listchamps.status?.length > 0 && [...new Set(listleads?.map(x => x.statut_client)),...listchamps.status ]?.map((x, i) => <option key={i} value={x} >{x}</option>)}
                                </select>
                            </div>
        
                        </div>
                        <button className='bouton vert' onClick={selectleads}  >Valider</button>
                        <button className='bouton rouge' onClick={generecsv}  >CSV</button>
                        <div  className="scrolltable" >
                                    {listleads.length >0 &&<table className="rowleadstable ">
                                                                        <thead><th>Date Reception</th> <th>Email</th> <th>Telephone</th> <th>Campagne</th> <th>Commercial</th> <th>Statut</th> </thead>
                                                                        <tbody>
                                                                            <tr></tr>{listleads.map((x,i)=><tr key={i}><td>{(new Date(x.date_origine)).toLocaleString("fr-FR",{timeZone:"Europe/Paris"})}</td>
                                                                                                                        <td>{x.email}</td><td>{x.telephone}</td> <td>{x.campagne}</td><td>{x.commercial}</td>   <td>{x.statut_client}</td>                                                     </tr>)}
                                                                        </tbody>
                                                                </table>}
                            </div>


                            <div className="partie1" >
                                <div>
                                        <button id="meilleureassurance.comsemaine" className='bouton bleu' onClick={cliquebouton}  >Faux Numéros MeilleurAssurance  Semaine Passee</button>
                                        <button id="meilleureassurance.commois" className='bouton bleu' onClick={cliquebouton}  >Faux  Numeros  MeilleurAssurance Mois precedent</button>
                                </div>
                                <div>{Object.keys(statleads?.data)?.length >0 &&<table className="rowleadstable">
                                                    <thead><th>Conseiller</th>  {statleads?.campagnes?.map(camp =><th key={camp} >  {camp?.toUpperCase()}  </th> )} </thead>
                                                    <tbody>
                                                    {Object.keys(statleads?.data).map((key,index)=><tr className="rowleadsweek" key={index}>
                                                                                                        <td>{key}</td>
                                                                                                        {statleads?.campagnes?.map(camp =><th key={camp} >{statleads?.data[key][camp]||0}</th> )} 
                                                                                                    </tr>)}
                                                    </tbody>
                                                </table>}
                                </div>
                            </div>

                            


                          
                    </div>

)
}


export default EcranGestionLeads

