import React , {useState}  from "react";

import { useUsers } from "../../state/reactquery";

import EquipesUserAddModif from "./EquipesUserAddModif";
import AlertePopupDelete from  "./AlertePopupDelete"

import './EquipesLineUser.scss'
const listerolesuser = ['MANAGER','ADMIN','GESTION','COMMERCIAL','EXTERNE']
const textecolors = {'MANAGER':['Manager','manager'],'ADMIN':['Admin','admin'],'GESTION':['Gestion','gestion'],'COMMERCIAL':['Vendeur','vendeur'],'EXTERNE':['Externe','externe']}

const badgerole =(role)=>(<div  key={role}   className={"badge "+textecolors[role][1]} >{textecolors[role][0]}</div>) ;


const classNames = require('classnames');
 
function EquipesLineUser({user}){

    const [showmodifuser, setShowmodifuser]  = useState(false);
    const [showpopupdelete, setShowpopupdelete]  = useState(false);
    const {data:users}  = useUsers() ;
    let equipes = users.filter(x=>x['MANAGER']===1) ;


    return(<> {showpopupdelete && <AlertePopupDelete type='delequipe' firstname={user['firstname']} lastname={user['lastname']} id={user['id']} setShowpopupdelete={setShowpopupdelete} />}
              {showmodifuser ? <EquipesUserAddModif type='modifyuser' setShowadduser={null} setShowmodifuser={setShowmodifuser} user={user} /> :
                            <div className={classNames("tableutilisateurs_user", { 'manager': user['MANAGER'] === 1 })} >
                                <div > <img src={user['avatar'] || `https://${process.env.REACT_APP_AWS_NAMEBUCKET}.s3.eu-west-3.amazonaws.com/courtiers/avatars/avatar.png`} width="30" height="30" alt="imageavatar" />  </div>
                                <div>  <div>  {user['firstname']} {user['lastname']}  </div><div>  {user['email']} </div>    </div>
                                <div>{[...listerolesuser.filter(x => user[x] === 1)].length > 0 && listerolesuser.filter(x => user[x] === 1).map(x => badgerole(x))}</div>
                                <div> {equipes.filter(x => x.id === user.id_manager)[0] ? <>Equipe de {equipes.filter(x => x.id === user.id_manager)[0]['lastname']} </> : '-'}</div>
                                <div> <div>{user['postmail']} </div><div>{user['telephone']}</div> </div>
                                <div className="lineuser_deletemodif">
                                    <span className="lineuser_delete" onClick={() => { setShowpopupdelete(true) }}   >Supprimer</span>
                                    <span className="lineuser_modif" onClick={() => { setShowmodifuser(true) }} >Modifier</span>
                                </div>
                            </div>} </> )
      
}


export default EquipesLineUser