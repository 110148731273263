import React  , {useState} from "react";
import {  useSelector} from 'react-redux';

import { dateconvert , convertdate} from "../../utils/utils_dates";

import './FormulaireContrat.scss' ;

const listecompagnies =['ALPTIS','AMIS','APICIL', 'APIVIA','APRIL', 'ASAF-AFPS','CEGEMA','CIPRES VIE','MALAKOFF HUMANIS',
                        'COVERITY','ECA ASSURANCES',  'ENTORIA','FFA', 'FMA', 'GENERALI','GMC', 'GSMC','HARMONIE',
                        'MODULASSUR',  'NÉOLIANE',  'REPAM','SOLLYAZAR', 'SPVIE','SWISSLIFE', 'WAZARI','HENNER',
                        'e','efz', 'efz2','fda'];


const jsonstatuts ={'encours':'En cours','impaye_1mois':'Impayé un mois','impaye_2mois':'Impayé deux mois','impaye_3mois':'Impayé trois mois','resiliecmugroupe':'Résilié CMU / Contrat groupe',
                        'resilieecheanceria':'Résilié Echeance / RIA','radiation':'Résilié Radiation','retractation':'Résilié Rétractation','resilieautre':'Résilié Autre','attentesignature':'Attente Signature'}
const listchamps ={'compagnie':'Compagnie','gamme':'Gamme','formule':'Formule','prix':'Prix','num_contrat':'Numéro de contrat','date_effet':'Date d’effet','date_signature':'Date de signature'}  
const classNames = require('classnames');
function FormulaireContrat({datacontrat, setDatacontrat}){

   const ecran = useSelector(state=>state.display.ecran) ; 

   const [listautocomplete, setListautocomplete] = useState([]) ;


   const handleChange =(e)=>{ console.log(datacontrat, e.target, e.target.id, e.target.value); 
                             e.target.id==='compagnie' && setListautocomplete(listecompagnies.filter(x=>x.toLowerCase().indexOf(e.target.value.toLowerCase())===0));
                             (e.target.id==='compagnie' && e.target.value.length===0 ) && setListautocomplete([]); 
                            const newvalue =  e.target.id==='compagnie'?  e.target.value?.toUpperCase() : e.target.value  ; 
                             setDatacontrat({...datacontrat,[e.target.id]:newvalue })}


    const definecompagnie =(e)=>{  setDatacontrat({...datacontrat,'compagnie': e.target.id }) ; setListautocomplete([])}

    const grplabelinput = (x,label)=>( <div key={x}  className={classNames("containerinputlabel" ,{"small":(x==='date_effet'  || x==='date_signature' || x==='num_contrat'  ) }  )} >
                                            <div className="inputtextelabel">{label}</div>
                                            {x==='compagnie' &&  <div style={{position:"absolute",backgroundColor:'#FFF', zIndex:2000,top:'69px', left:'0px', width:'320px', maxHeight:'400px', overflowY:'scroll' }} >
                                                                        {listautocomplete.map(x=><div key={x} id={x}  style={{padding:'5px 15px'}} onClick={definecompagnie}  >{x}</div>)}
                                                                </div>}
                                            <input id={x} type={(x==='date_effet'  || x==='date_signature' || x==='date_fincontrat'  )?"date":"text"  } 
                                                    autoComplete="off"   onBlur={()=>{setTimeout(()=>{setListautocomplete([])  },2000);   }}  onChange={handleChange} 
                                                    value={(x==='date_effet'||x==='date_signature'||x==='date_fincontrat')?datacontrat[x]:datacontrat[x] }   />
                                    
                                        </div>) ;

    const changestatutcontrat = () =>( <div   className="containerinputlabel" >
                                            <div className="inputtextelabel">Statut du contrat</div>
                                            <select value={datacontrat.etat_contrat}    className='selectmofistatutcontrat' id='etat_contrat'  onChange={handleChange} >
                                                        {Object.keys(jsonstatuts).map((y,i)=><option key={i}  value={y} >{jsonstatuts[y]}</option>)}
                                            </select>
                                        </div>);

    return(<div className="modifcontrat_form" >
                                {Object.keys(listchamps).map(x=>( grplabelinput(x,listchamps[x]) ))}
                                {ecran ==='ficheclientgestion' &&  grplabelinput('date_fincontrat',"Date de fin du contrat")}
                                {ecran ==='ficheclientgestion' &&  changestatutcontrat()}
                    </div> )

}


export default FormulaireContrat