import React  , {useState} from 'react';
import { useSelector , useDispatch} from 'react-redux';

import { progressions_rdv  , progressions_relance , progressions_nrp , progressions_attentesignature ,
         progressions_attentedocuments} from '../../utils/listeprogression';

import { setChoiceliste } from '../../state/redux'; 

import attentes from '../../images/leadschoices/attentes.svg';
import injoignables from '../../images/leadschoices/injoignables.svg';
import newleads from '../../images/leadschoices/newleads.svg';
import rdvjour from '../../images/leadschoices/rdvjour.svg';
import iconsign from '../../images/leadschoices/iconsign.svg';
import flamme from '../../images/flammehot.svg';
import relances from '../../images/leadschoices/relances.svg';
import alert from '../../images/alert.svg';


import './DashboardChoices.scss';
const classNames = require('classnames');
function DashboardChoices(){
    const dispatch = useDispatch() ; 
    const {leads , choiceliste}= useSelector(state=>state.leads);
    const leadsnews= leads.filter(x=>(x.statut_client==='nontraite'));
    const leadsrdv= leads.filter(x=>(progressions_rdv.includes(x.statut_client)));
    const leadsrelances= leads.filter(x=>((progressions_relance.includes(x.statut_client) &&  x.statut_client!=="gagne") || x.statut_client==="devisenvoye"))
    const leadsnrp= leads.filter(x=>(progressions_nrp.includes(x.statut_client)));
    const leadsattentessign= leads.filter(x=>(progressions_attentesignature.includes(x.statut_client)));
    const leadsattentesdocs= leads.filter(x=>(progressions_attentedocuments.includes(x.statut_client)));
    const leadshot= leads.filter(x=>x.hot===1);
    const leadsimpayes=  leads.filter(x=>(x?.statut_client?.includes('impaye')));
    //.filter(x=>(x?.statut_client?.includes('impaye')));

    const choiceleads = (e)=>{  dispatch(setChoiceliste(e.target.id.replace("choice","")) );}

    const choice =(id,titre,image,nb)=>( <div  id={id} className={classNames("dashbouton",{"dashboutonchoice":id==="choice"+choiceliste} )} onClick={choiceleads} >
                                                <div className="dashbouton_col1">
                                                        <img height="18" src={image} alt="imgchoix" />
                                                        <div className="dashbouton_titre">{titre}</div>
                                                </div>
                                                <div className="dashbouton_nb">{nb}</div>
                                        </div>)
                              
    return(<div className="dashboardtop">
                {choice("choicenewleads","Nvx leads",newleads,leadsnews.length )}
                {choice("choicerdv","RDV à venir",rdvjour,leadsrdv.length)}
                {choice("choicerelances","A relancer",relances,leadsrelances.length )}
                {choice("choiceinjoignables","NRP",injoignables,leadsnrp.length )}
                {choice("choicewaitsign","Signatures en attente",iconsign,leadsattentessign.length )}
                {choice("choicewaitdocs","Documents en attente",attentes,leadsattentesdocs.length )}
                {choice("choicehot","Leads Chauds",flamme,leadshot.length )}
                {choice("choiceimpayes","Impayés",alert,leadsimpayes.length )}
        </div>)
}

export default DashboardChoices