import React from 'react';
import { useDispatch  } from 'react-redux';

import { defineContratchange ,modifyDisplaymodifcontrat } from '../../state/redux';
import { useUser , deleteContrat } from '../../state/reactquery';

import { logocompagnie } from '../../utils/logocompagnie';

import './CardFormuleContrat.scss'

import lowcost from '../../images/lowcost.svg';

function formatprix(prix){  return prix?   parseFloat(prix)?.toFixed(2).replace('.',','):''}

const classNames = require('classnames');

const CardFormuleContrat = ({contrat})=>{
 const dispatch= useDispatch()

      const logo  = logocompagnie(contrat?.compagnie?.toUpperCase());

      const {data: user} = useUser(localStorage.getItem('token'))  ;

      const clickcard = (e) =>{
        if(e.target.classList.value==='deletecontrat'){  deleteContrat(contrat.indexe) }
        else{dispatch(defineContratchange(contrat))  ;dispatch( modifyDisplaymodifcontrat(true))}
      }



    return (<div className={classNames("cardcontrat",{'cardcontrat_attentesignature':contrat?.etat_contrat==='attentesignature' } ,{'noaction':( user?.['GESTION']!==1 && contrat?.etat_contrat==='attentesignature' ) }     )} onClick={clickcard}  >
                <div className='cardcontrat_image' >
                       {logo ? <img src={logo}  width='70' alt='logoCompagnie'/> : <div className='pasdelogo' ><div>{contrat?.compagnie}</div></div>}
                </div>
                <div className="cardcontrat_formule" >              
                        <div className="cardcontrat_formule_line1"> {contrat.gamme} </div>
                        <div className="cardcontrat_formule_line2"> {contrat.formule}</div>     
                        {contrat?.lowcost===1 &&<div><img height="20"  src={lowcost} alt='logolowcost'    /></div> }
                </div>
                <div  className="cardcontrat_details" > 
                        <div className='cardcontrat_details_numcontrat' >Contrat nº {contrat.num_contrat?contrat.num_contrat:''}</div>  
                       {contrat.date_signature && <div className='cardcontrat_details_dates' ><span className='graybold' >Date de signature: </span>{contrat.date_signature && <span className='lightgray' >{new Date(contrat.date_signature).toLocaleDateString('fr-FR') }</span>}   </div>  }
                       {contrat.date_effet &&  <div className='cardcontrat_details_dates' ><span className='graybold' >Date d'adhesion: </span>{contrat.date_effet && <span className='lightgray' >{contrat.date_effet}</span>}   </div>       }
                        {(user?.['GESTION']===1 && contrat.date_fincontrat)&&<div className='cardcontrat_details_dates' ><span className='graybold' >Date fin  contrat :</span><span className='lightgray' >{new Date(contrat.date_fincontrat).toLocaleDateString('fr-FR')}</span></div>}     
                </div>
                <div className='cardcontrat_prix'>
                        <div className='cardcontrat_prix_line1'>{formatprix(contrat.prix)}€</div>
                        <div className='cardcontrat_prix_line2'>par mois</div>
                </div>
                {user?.['GESTION']===1 &&  <div className='indiccontrat' >{contrat.etat_contrat}</div>}
                {user?.['GESTION']===1 &&  <div className='deletecontrat' >X</div>}
          </div> )       
             
}

export default CardFormuleContrat