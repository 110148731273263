import React  from "react";
import { useSelector } from "react-redux";
import { useHistories } from "../../state/reactquery";

import { formatdatehistory } from "../../utils/utils_dates";

import './Historique.scss';

 
function Historique(){

    const ficheclient = useSelector(state=>state.lead.leadactu);

    const {data: histories} = useHistories(ficheclient?.id) ; 



    return(<div  className='histories'>
                <div className="histories_container">  
                <div  className='histories_line'></div>
                {histories?.length >0 && histories.sort((a,b)=>(new Date(b.date)).getTime()  - (new Date(a.date)).getTime()   )
                                                  .map(x=><div key={x.id} className='history'>
                                                                    <div  className={'history_img history_'+x.type}></div>
                                                                    <div  className="history_description">
                                                                <div    dangerouslySetInnerHTML={{ __html: x.description }}    />
                                                                            <div className='history_date'>{formatdatehistory(x.date)}</div>
                                                                    </div>
                                                            </div>)}
                </div> 
            </div> )
}

export default Historique