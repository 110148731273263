import React from "react";

import ButtonSimple from "../Buttons/ButtonSimple";
import './PopupDataSaved.scss'



function PopupDataCourtierSaved(){
    return(<div id="popupdatacourtiersaved"  className="popupdatasaved" >
              <div className="popupdatasaved_titre" > Vos modifications ont bien été enregistrées ! </div>
  
    </div>)

}



export default PopupDataCourtierSaved


