import React  , { useState, useEffect ,useMemo, useRef} from 'react';
import { useDispatch, useSelector} from 'react-redux'; 

import { validsecu, validtel, valideemail, validnomprenom , validiban , validtrue} from '../../utils/validations';
import { deleteChampsLeadmemo ,  modifyChampsLeadmemo , modifyDisplayscreenchangeemail } from '../../state/redux';

import { listejsonall } from '../../utils/leadlistechamps';

import './Infos.scss';
import './TitleInput.scss';
const validfunctions = {"prenom": validnomprenom, "nom": validnomprenom, "numsecu": validsecu , "telephone": validtel ,  "telephone2": validtel ,  "telephone3": validtel ,
                         "email": valideemail,"email2": valideemail, "iban1": validiban , "iban2": validiban , "organisme": validtrue , "bic1": validtrue , "bic2": validtrue  , 'complement_adresse' :validtrue  }

const classNames = require('classnames'); 
function TitleInput({holderplace,nameinput,id}){
      const elementRef = useRef();
    const dispatch = useDispatch() ; 
    const fichememo = useSelector(state=>state.lead.leadmemo);
    const lead = useSelector(state=>state.lead.leadactu);
    
    const [valid, setValid] = useState(( fichememo[listejsonall[id][nameinput]]!==null && fichememo[listejsonall[id][nameinput]]!==undefined) ? true : undefined)   
    const [focus, setFocus] = useState(false);
    const [val, setVal] = useState(fichememo[listejsonall[id][nameinput]] ||'');
     const valueinput = useMemo(() => fichememo[listejsonall[id][nameinput]], [fichememo,listejsonall,id,nameinput]);
     
const copytoclipboard =()=>{  navigator.clipboard.writeText(elementRef.current.value||elementRef.current.placeholder);}
const  changeemail = ()=>{dispatch(modifyDisplayscreenchangeemail(true))}
    useEffect(()=>{  (valueinput || valueinput==='' )&& setVal(valueinput);  },[valueinput])

    const formatIban = (val) => {
        let formattedText = val.split(' ').join('');
        if (formattedText.length > 0) { formattedText = formattedText.match(new RegExp('.{1,4}', 'g')).join(' '); }
        return formattedText;
      }
    const handleBlur=(e)=>{setFocus(false);   
                              validfunctions[e.target.name]?validfunctions[e.target.name](e.target.value) ? setValid(true)  : setValid(false) : setValid(true) ;
                          (validfunctions[e.target.name]&&validfunctions[e.target.name](e.target.value)) &&      dispatch(modifyChampsLeadmemo( {[listejsonall[id][nameinput]] :(nameinput==='iban1' || nameinput==='iban2')?  formatIban(e.target.value) :  e.target.value })); 
                              (validfunctions[e.target.name]&&   !validfunctions[e.target.name](e.target.value)) && dispatch(deleteChampsLeadmemo([listejsonall[id][nameinput]])); 
                        }

                           
    return( <div className="grouptitleinput"> 
                    <div className={classNames("grouptitleinput_containerinput", nameinput ,{"grouptitleinput_containerinput_valid": valid===true} ,{"grouptitleinput_containerinput_error": valid===false  } , {"grouptitleinput_containerinput_focus": focus} )}> 
                                <input style={{fontSize: '16px'}} ref={elementRef} value={val} id={id} placeholder={holderplace} name={nameinput} onChange={(e)=>{ setVal(e.target.value)} } onFocus={()=>{(listejsonall[id][nameinput]==='email'&& lead.email)?changeemail() :setFocus(true)}}  onBlur={handleBlur} readOnly={listejsonall[id][nameinput]==='email' && lead.email  } />
                                <div  className='logocopy' onClick={copytoclipboard}  ></div>
                    </div>
                   
            </div>)
}



export default TitleInput

