import React from "react";


import  "./PopupAvertissementSignature.scss"
function PopupAvertissementSignature(){
    return(<div id="popuparvertsign"  className="popuparvertsign">
        Veuillez renseigner tooutes les informations (organismes, noms, prenoms.... des assures! )
    </div>)
}


export default PopupAvertissementSignature