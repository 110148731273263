import React from "react";
import axios from 'axios' ; 
import { useDispatch , useSelector} from "react-redux";
import { modifyonelead } from "../../utils/actions_lead";
import { rdvgagne } from "../../utils/actions_echec_gagne";
import { addEltHistory } from "../../state/reactquery";
import ButtonImgTop from "../../Components/Buttons/ButtonImgTop";

import {modifystatus_nrp,modifystatus_fauxnum} from "../../utils/actions_modifstatuts";

import { modifyDisplaycalendarrdv, modifyPopupqualiflead , modifyPopupechecs , modifyDisplaycalendarrelance , modifyQualiflead} from '../../state/redux';
import { raccrocher } from "../../utils/phone";

// DEV A REVOIR
import './GroupBtnActionTel.scss';


function GroupBtnActionTel(){
    const dispatch = useDispatch();
    const lead = useSelector(state=>state.lead.leadactu);
    const eventtochange = useSelector(state=>state.calendar.eventtochange);

    const relancerdv = ()=>{dispatch(modifyDisplaycalendarrelance(true)) }    
    const prendrerdv = ()=>{dispatch(modifyDisplaycalendarrdv(true)) }
    const maintientcontrat = () =>{
        axios.post(  `/contrats/maintiencontrat/`,{email: lead.email} , {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
        modifyonelead({'statut_client': 'gagne',   'hot': 0}) ;  // 'recyclage' : 2 , 
        rdvgagne(lead.id); 
        // DEV
        addEltHistory('qualiflead', `<div class='horoline1' >Recyclage: Maintient du contrat </div>`)  

        dispatch(modifyQualiflead(true));
        dispatch(modifyPopupqualiflead(false));
    }

    return( <div className="grpbtnactiontel" >
                     { lead.recyclage===2 &&  <ButtonImgTop  texte="Maintien"  type='bouton_maintien'  onclick={maintientcontrat} />  }
                        <ButtonImgTop  texte={(eventtochange.id && lead.statut_client?.includes('relance'))?"Modifier Relance":"Relancer"}  type='bouton_relance' onclick={relancerdv}  /> 
                        <ButtonImgTop  texte={(eventtochange.id && lead.statut_client?.includes('rdv'))?"Modifier Rdv":"Rdv"}    type='bouton_rdv' onclick={prendrerdv}  /> 
                        <ButtonImgTop  texte="NRP"  type='bouton_nrp'  onclick={()=>{raccrocher() ; modifystatus_nrp();}}  /> 
                        <ButtonImgTop  texte="Fx Num"  type='bouton_fauxnum' onclick={()=>{modifystatus_fauxnum();raccrocher()}} /> 
                        <ButtonImgTop  texte="Echec" type='bouton_echec' onclick={()=>{dispatch(modifyPopupqualiflead(false))  ; dispatch(modifyPopupechecs(true));raccrocher()}}   />
                      
                </div>)
}



export default GroupBtnActionTel