import axios from 'axios';

export async function verifieversion(){ 
       console.log('verifieversion')
    // let toto =await axios.get('https://frontcrm.s3.eu-west-3.amazonaws.com/version.txt' ,{headers: { 'Access-Control-Allow-Origin': '*'}}) ;
   // let toto =await axios.get('https://selchesibucket.s3.eu-west-3.amazonaws.com/docsclients/cabinettest/2023/clients/PLzlXpZGFJqX3n3xFka4I/signature202308311013.txt' ,{headers: { 'Access-Control-Allow-Origin': '*'}})
   let req = await   axios.post('/readversion',{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}})
 
    let versionactu = JSON.parse(req.data)?.['version']   ; 
    let versionapp = localStorage.getItem('version')  ;

    if(versionactu !== versionapp ){console.log('On reload');  localStorage.setItem('version',versionactu)   ;  window.location.reload(true)}

    setInterval(()=>{verifieversion()},4*60*60*1000) ;
     
    }
    // if( process.env.REACT_APP_URLBACK!=='https://api.mutuello.com/')return  
    // let versionback ;
    // try{
    //  const reqversion =    await  axios.post(  `/readversion`,{'version' :packageJson?.version },{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}});
   
    //  versionback =JSON.parse(reqversion.data)?.version ;
    // }catch(err){console.log(err)}

    // if(localStorage.getItem('version')!==packageJson.version ){  
    //     window.location.reload(true) ;localStorage.setItem('version',packageJson.version )}else{console.log('c est la meme version')}