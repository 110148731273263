
import React , { useState , useEffect }  from "react";
import { useSelector  , useDispatch } from "react-redux";
import axios from "axios";


import { modifyDisplayscreensignature } from "../../state/redux";
import { addEltHistory , useUser} from "../../state/reactquery";
import { queryClient } from '../../state/queryClient';

import { rdvgagne } from "../../utils/actions_echec_gagne";
import { threeyearsafter } from "../../utils/utils_dates";
import { dateconvert } from "../../utils/utils_dates";
import { modifyonelead } from "../../utils/actions_lead";
import { progressions_gagne } from "../../utils/listeprogression";

import ButtonSimple from "../Buttons/ButtonSimple";
import FormulaireContrat from "./FormulaireContrat";

import croixclose from '../../images/croixclose.svg';
import yousignorange from '../../images/yousignorange.svg';
import yousigngris from '../../images/yousigngris.svg';


import './CardSignature.scss' ;

const nbsteps={'intro':0,'formulaire':1,'uploadcontratsigne' :2 ,'uploadcontratnonsigne' :2 , 'fin':3};
const classNames = require('classnames');

function CardSignature(){

      const dispatch = useDispatch()  ;
      const {data: user} = useUser(localStorage.getItem('token'))  ;
      const [step, setStep] = useState('intro') ;
      const [errorTypedoc, setErrorTypedoc] = useState(false) ;
      
      const [choice,setChoice] = useState(null) ;
      const [filecontrat, setFilecontrat] = useState(null)   ;
      const [validuploadcontrat, setValiduploadcontrat] = useState(false) ;

      const ficheclient = useSelector(state=>state.lead.leadactu);
      const [datacontrat, setDatacontrat] = useState({"compagnie":ficheclient.formulesigne_compagnie,"gamme":ficheclient.formulesigne_gamme ,"formule":ficheclient.formulesigne_formule,
                                                      "prix":ficheclient.formulesigne && ficheclient.formulesigne.split('_')[1]  , "num_contrat":ficheclient.num_contrat,
                                                       "date_effet":dateconvert(ficheclient.date_effet)});
     

      const addfilecontrat = (e)=>{
            if(choice==='uploadcontratnonsigne' && e.target.files[0].type!=='application/pdf'){
                  setFilecontrat(null)  ; setErrorTypedoc(true)
            }else{
                  setFilecontrat(e.target.files[0]); setErrorTypedoc(false) ;  setTimeout(()=>{setValiduploadcontrat(true)  },1000);
            }
      }
 
      const fermeturecard =()=>{
            if(choice==='uploadcontratsigne' && ficheclient.statut_client?.documents ===1 ){
                  const leadupdate =  { 'date_rdvrelance':threeyearsafter(ficheclient.date_lastmodif),'documents': 1 ,'statut_client':'gagne', 'hot':0 } ;  //'recyclage' : 2 ,
                  modifyonelead({...leadupdate}) ;   
                  rdvgagne(ficheclient.id);
                  addEltHistory('leadgagne', `<div class='horoline1' > <span className=horobold'>Lead passé en Gagné ! </span> </div>` )    
                  queryClient.invalidateQueries({ queryKey: ['histories',ficheclient?.id]})     ;
            }
            dispatch(modifyDisplayscreensignature(false))
      }

      const sendcontrat = async(e)=>{  

            const data = new FormData() ;
            data.append('file',filecontrat,`/${'signature'+(new Date()).toISOString().slice(0,16).replace(/\D+/g,'')}.${filecontrat.name.split('.')[1]}`);

            const {email,nom,prenom, courtier, id_client , commercial   , id }= ficheclient ;
            const { telephone_courtier,email_courtier,nom_cabinet   }= user ;

            data.append('lead', JSON.stringify({email,nom,prenom, courtier,id_client , commercial ,id })) ; 
            data.append('datacontrat', JSON.stringify(datacontrat)) ;
            data.append('user',JSON.stringify({...user,telephone_courtier,email_courtier,nom_cabinet})) ; 
            // DONNEES NECECESSAIRES EN BACKEND
            //   {email,nom,prenom, courtier, id_lead ,id_client , commercial   }= ficheclient ;
            //    {compagnie, gamme ,prix, formule,num_contrat, date_effet }= datacontrat ;
            // const { telephone_courtier,email_courtier,nom_cabinet   }= user ;

            let dataformulelead={'formulesigne_compagnie':datacontrat['compagnie'] , 'formulesigne_formule':datacontrat['formule'] , 'formulesigne_gamme':datacontrat['gamme']  }

            if(choice==="uploadcontratnonsigne"){   
                  await   axios.post(  `/yousign/uploadfiletosignature/`,data, {headers: { 'Accept': 'application/json', "Content-Type": "multipart/form-data"} }); 
                  addEltHistory('emailliensignaturecrmsend',  `<div class='horoline1' > Email <span  className='horolink' >Lien signature pour contrat extérieur </span>  envoyé </div>`) ; 
                  modifyonelead({...dataformulelead,'statut_client':progressions_gagne.includes(ficheclient.statut_client)?ficheclient.statut_client:ficheclient.statut_client?.includes('rdv')?'attente_signature_rdv':ficheclient.statut_client?.includes('relance')?'attente_signature_relance':'attente_signature'});
            }else{  await   axios.post(  `/contrats/addcontratexterieursigne/`,data, {headers: { 'Accept': 'application/json', "Content-Type": "multipart/form-data"} });
                  addEltHistory('documents',  `<div class='horoline1' > Bulletin ${datacontrat['compagnie']} ${datacontrat['formule']}  , signé à l’extérieur, a été importé.</div>`) ; 
                  modifyonelead({...dataformulelead,'contratsigne':1,'statut_client':ficheclient.statut_client.documents===1?'gagne': progressions_gagne.includes(ficheclient.statut_client)?ficheclient.statut_client:ficheclient.statut_client?.includes('rdv')?'attente_documents_rdv':ficheclient.statut_client?.includes('relance')?'attente_documents_relance':'attente_documents'});
            }

            setTimeout(()=>{document.getElementById('filecontrat') && (document.getElementById('filecontrat').value= null );setFilecontrat(null)  },1000); 
                              queryClient.invalidateQueries({queryKey:['contrats',ficheclient?.email,ficheclient?.courtier]}) ;
                              queryClient.invalidateQueries({ queryKey: ['documents',ficheclient?.id_client]})
                              setStep('fin');
            }

    const contententintro = ()=>( <><div className='cardsignnonliste_titre'>Signature</div>
                                          <div className="signaturenonlistee_texte" >Importez le bulletin émis sur <span className="signaturenonlistee_texte_orange" >l’extranet de la compagnie </span> </div>
                                          <div className='cardpassgagne_top_boulettes'>{[0,1,2,3].map(x=><div key={x}  className={classNames('boulette',{'orange':nbsteps?.[step]>=x},{'grise':nbsteps?.[step]<x} ) } ></div>)}</div>
                                          <div className='cardsignnonliste_twocards' >
                                                {["uploadcontratsigne","uploadcontratnonsigne"].map(x=><div key={x}  id={x}  className={'cardsignnonliste_card '+x }   onClick={()=>{setChoice(x)   ; setStep('formulaire')}}   >
                                                                                                                  <div className={classNames('cardsignnonliste_twocards_bullet',{"selected":choice===x}  )}></div>
                                                                                                                  <div className="cardsignnonliste_texte1" >J’importe un bulletin</div>
                                                                                                                  <div className="cardsignnonliste_texte2" >{x==="uploadcontratsigne"?"déjà signé":"à faire signer"}</div>
                                                                                                            </div>  )}
                                          </div>
                                          <div className="signaturenonlistee_texte" >Pour les compagnies listées sur Mutuello,</div>
                                          <div className="signaturenonlistee_texte" >rendez-vous dans<span className="signaturenonlistee_texte_orange" > l’onglet formules</span> 
                                </div></>       )
     
     const contentformulaire = ()=>(<><div className='cardsignnonliste_titre'>Bulletin de souscription</div>
                                          <div className='cardpassgagne_top_boulettes'>{[0,1,2,3].map(x=><div key={x}  className={classNames('boulette',{'orange':1>=x},{'grise':1} ) } ></div>)}</div>
                                          <div className='cardsignnonliste_formtitre'>Saisir les détails du contrat </div>
                                          <FormulaireContrat   datacontrat={datacontrat} setDatacontrat={setDatacontrat} /> 
                                          <div className='cardsignnonliste_buttons'>
                                                <ButtonSimple  texte="Retour" containerbouton='fullbuton white'   color='bouton_color_blancgris  noborder' size='bouton_size_normal'  onclick={()=>{setStep('intro')}}    />   
                                                <ButtonSimple  texte="Continuer" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' onclick={()=>{choice==="uploadcontratsigne" ?setStep("uploadcontratsigne") : setStep("uploadcontratnonsigne") }}
                                                  disable={(!datacontrat['compagnie'] || !datacontrat['formule'] || !datacontrat['prix'] ||  !datacontrat['date_effet'])}  />      
                                    </div></>)

     const contentdejasigne = () =>( <><div className='cardsignnonliste_titre'>Importer le bulletin <span className="cardsignnonliste_titre_orange" >déjà signé  </span></div>
                                          <div className='cardpassgagne_top_boulettes'>{[0,1,2,3].map(x=><div key={x}  className={classNames('boulette',{'orange':2>=x},{'grise':2} ) } ></div>)}</div>
                                          <div className="cardsignnonliste_textegris" >Le bulletin est déjà signé ? Veuillez le glisser ici</div>
                                          <div className="cardsignnonliste_containerinputcontrat"  >
                                                <input  id='filecontrat'  className="cardsignnonliste_inputcontrat" type='file' onChange={addfilecontrat}   />
                                                <div className="cardsignnonliste_inputcontrattexte">Bulletin signé </div>
                                                <div className="filenamecontrat" >{filecontrat?.name}</div>
                                          </div>  
                                    <div className='cardsignnonliste_buttons'>
                                          <ButtonSimple  texte="Retour" containerbouton='fullbuton white'   color='bouton_color_blancgris  noborder' size='bouton_size_normal'  onclick={()=>{  setStep('formulaire')}}    />   
                                          <ButtonSimple  texte="Continuer" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' disable={!validuploadcontrat} onclick={sendcontrat}  />      
                                    </div></>)

    const contentnonsigne = () =>( <><div className='cardsignnonliste_titre'>Importer le bulletin <span className="cardsignnonliste_titre_orange" > à faire signer</span></div>
                                    <div className='cardpassgagne_top_boulettes'>{[0,1,2,3].map(x=><div key={x}  className={classNames('boulette',{'orange':2>=x},{'grise':2} ) } ></div>)}</div>
                                    <div className="cardsignnonliste_textegris" >Veuillez glisser le bulletin à faire signer</div>
                                    <div className="cardsignnonliste_textegris cardsignnonliste_textegris_yousign " ><span>Signature électronique en partenariat avec</span>    <img  src={yousigngris}  height="28"  alt='yousigngris'  />  </div>
                                    <div className="cardsignnonliste_containerinputcontrat"  >
                                          <input  id='filecontrat'  className="cardsignnonliste_inputcontrat" type='file' onChange={addfilecontrat}   />
                                          <div className="cardsignnonliste_inputcontrattexte">Bulletin signé </div>
                                          <div className="filenamecontrat" >{filecontrat?.name}</div>
                                         { errorTypedoc && <div className="errorTypedoc" >Veuillez import un PDF</div>}
                                    </div>  
                                    <div className='cardsignnonliste_buttons'>
                                    <ButtonSimple  texte="Retour" containerbouton='fullbuton white'   color='bouton_color_blancgris  noborder' size='bouton_size_normal'  onclick={()=>{  setStep('formulaire')}}    />   
                                    <ButtonSimple  texte="Continuer" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' disable={!validuploadcontrat} onclick={sendcontrat}  />      
                              </div></>)

     const contentfin = ()=>(<><div className='cardsignnonliste_titre'>{choice==="uploadcontratnonsigne"?<>Votre bulletin a bien été envoyé pour signature</>:<>Félicitations !</> }</div>
                              <div className="cardsignnonliste_textefin" >{choice==="uploadcontratnonsigne"?<>{datacontrat['compagnie']},{datacontrat['gamme']} {datacontrat['nomformule']}  , Date d’effet : {datacontrat['date_effet']}</>:
                                                                                                            <>Vous avez bien ajouté votre bulletin signé.</> } </div>
                              <div className="cardsignnonliste_textefin" >{choice==="uploadcontratnonsigne"?<>Dès signature, vous serez notifié dans votre console !</>:
                                                                                                            <>Préparer les documents liés au bénéficiaire pour passer le lead en Gagné </> } </div>
                              {choice==="uploadcontratnonsigne"&&<div className="cardsignnonliste_textefinorange" ><span> Signature électronique en partenariat avec   </span> <img  src={yousignorange}  height="28"  alt='yousignorange'  /></div>}
                              <ButtonSimple  texte="Fermer" containerbouton='fullbuton'   color='bouton_color_orange' size='bouton_size_normal'  onclick={fermeturecard}    /></>) ;

    const contentcard = step==='intro'?contententintro():step==="formulaire"?contentformulaire() :step==="uploadcontratsigne"?contentdejasigne():step==="uploadcontratnonsigne"?contentnonsigne() : contentfin();

    return(<div className="containercard"> 
                     <div className='cardsignnonliste'> 
                        <div className="closefenetre" ><img src={croixclose}  alt="imgclose" onClick={()=>{dispatch(modifyDisplayscreensignature(false))}}  />       </div>
                        {contentcard}
                     </div>
            </div>)
}


export default CardSignature ;