
import React, {useEffect}  from 'react';

import { useDispatch , useSelector } from 'react-redux'; 

import { modifyChampsLeadmemo } from '../../state/redux';

import {listejsonall} from '../../utils/leadlistechamps';

import './Regime.scss';
const classNames = require('classnames'); 
function Regime({id}){
    const fichememo  = useSelector(state=>state.lead.leadmemo);
    const dispatch = useDispatch() ; 
   
    useEffect(()=>{ fichememo[listejsonall[id]['regime']]===undefined && dispatch(modifyChampsLeadmemo({[listejsonall[id]['regime']]:'general'}))},[dispatch,fichememo,id]);


    const clickgenderbutton=(e)=>{dispatch(modifyChampsLeadmemo( {[listejsonall[id]['regime']]:e.target.name}))}

    return ( <div className="groupregime " > 
           <div  className="groupregime_buttons" >
                <button  name="general"  className={classNames('button button_gender mr', {'button_gender_selected': fichememo[listejsonall[id]['regime']]==='general'})} onClick={clickgenderbutton}>General</button>
                <button name="independant"  className={classNames('button button_gender', {'button_gender_selected': fichememo[listejsonall[id]['regime']]!=='general'})} onClick={clickgenderbutton}>Independant</button>
            </div>
        </div>)

}



export default  Regime
