
import React   from 'react';
import { useSelector } from 'react-redux'; 

import TitleInput from './TitleInput';
 import TitleInputAdresse  from './TitleInputAdresse'; 
import MiniDepartements from './MiniDepartements';



import './Infos.scss';
function InfosSouscripteur(){
    const fichememo  = useSelector(state=>state.lead.leadmemo);
    
    return(<div className='infossouscripteur' >
                <div className='lineinfossouscripteur'>
                    <TitleInput  id="souscripteur"    holderplace={fichememo.email} nameinput="email"     />
                    <TitleInput  id="souscripteur"   holderplace={fichememo.email2} nameinput="email2"     />
                    <TitleInput  id="souscripteur"   holderplace={fichememo.telephone} nameinput="telephone"     />
                    <TitleInput  id="souscripteur"   holderplace={fichememo.telephone2} nameinput="telephone2"     />
        
                </div>
                <div className='lineinfossouscripteur'>
                <TitleInputAdresse    /><TitleInput  id="souscripteur"    holderplace={fichememo.complement_adresse} nameinput="complement_adresse"     /> 
                </div>
                <MiniDepartements  />
                <div  className='lineinfossouscripteur'>
                        <TitleInput  id="souscripteur"   holderplace={fichememo.iban1} nameinput="iban1"     /> 
                        <TitleInput  id="souscripteur"   holderplace={fichememo.bic1} nameinput="bic1"     /> 
                </div>
                <div  className='lineinfossouscripteur'>
                    <TitleInput  id="souscripteur"   holderplace={fichememo.iban} nameinput="iban2"     /> 
                    <TitleInput  id="souscripteur"  holderplace={fichememo.bic2} nameinput="bic2"     /> 
                </div>      
         </div>)
}

export default InfosSouscripteur