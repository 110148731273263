import axios from 'axios';

import { store,  modifyDisplaycalendarrelance,deleteChampsLeadactu } from '../state/redux';
import { queryClient  } from '../state/queryClient';
import { addEltHistory , addDocument } from '../state/reactquery';



import { progressions_gagne } from './listeprogression';
import { modifyonelead , popup} from './actions_lead';
import { testdateafter } from './utils_dates';


export const senddevis =async ()=>{
        const lead = store.getState().lead.leadactu; 
        const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
        const formulesdevis = store.getState().formules.formulesdevis ;
        const [sol1,sol2,sol3] = [formulesdevis[0],formulesdevis[1],formulesdevis[2]] ;
  
       

        // MODIFICATION  DU LEAD
        await   store.dispatch(deleteChampsLeadactu(['formuledevis1','formuledevis2','formuledevis3']));
        let leadchampsmodifs = {'statut_client':progressions_gagne.includes(lead.statut_client)?lead.statut_client:
                                lead.statut_client?.includes('rdv')?'devisenvoye_rdv':
                                lead.statut_client?.includes('relance')?'devisenvoye_relance': 'devisenvoye',  commercial: user['nom']}; 

        sol1  ? (leadchampsmodifs['formuledevis1']=sol1.id+'_'+sol1.prix):(leadchampsmodifs['formuledevis1']='X_X') ;
        sol2  ? (leadchampsmodifs['formuledevis2']=sol2.id+'_'+sol2.prix):(leadchampsmodifs['formuledevis2']='X_X') ;
        sol3  ? (leadchampsmodifs['formuledevis3']=sol3.id+'_'+sol3.prix):(leadchampsmodifs['formuledevis3']='X_X') ;

        modifyonelead(leadchampsmodifs);

        // PRISE RDV DANS LE CAS OU NON PREVU FUTUR
        if(!lead.date_rdvrelance || !testdateafter(lead.date_rdvrelance)){store.dispatch(modifyDisplaycalendarrelance(true))}

        //    POPUP
        popup('popupdevis');

        // GENERATION ET ENVOI DE DEVIS

       await axios.post(`/devis/senddevisfromcrm`,{user,lead,sol1,sol2,sol3},{headers:{'Accept':'application/json','Content-Type':'application/json'}});
        queryClient.invalidateQueries({ queryKey: ['listmails',user['postmail'],lead.email]}) ;  
        queryClient.invalidateQueries({ queryKey: ['histories',lead.id]})
        queryClient.invalidateQueries({ queryKey: ['documents',lead.id_client]})

}
