import React, { useEffect ,useState   } from "react";
import {useDispatch,useSelector, batch} from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { initialiseLeadactu ,modifyEcran, modifyDisplaycalendarrdv,modifyDisplaycalendarrelance,  seteventtochange , modifyDisablebuttons } from '../../state/redux';
import { useUser } from "../../state/reactquery";

import { listestatus } from "../../utils/listeprogression";
import { getallevents , orderevents} from "../../utils/actions_calendar";
import { getallformules } from '../../utils/calculstarifs';
import { formatdatefrance , dateheuremin} from "../../utils/utils_dates";

import './ListEvents.scss';

const rdvpro = (y)=>{  return   y.description? ((y.description.indexOf("#ID")) !==-1)  : false }
const showdetailevent = (m)=>{ rdvpro(m) && document.getElementById('detailrdv'+m.id).classList.toggle('show') }
const cacherdv = (id_divlistejour)=>{ document.getElementById(id_divlistejour) && document.getElementById(id_divlistejour).classList.add('cache_rdvjour'); }
const togglecacherdv = (id_divlistejour)=>{ document.getElementById(id_divlistejour) && document.getElementById(id_divlistejour).classList.toggle('cache_rdvjour'); }
const classNames = require('classnames');

function ListEvents(){
  let navigate = useNavigate();
  const dispatch = useDispatch(); 
  const {data: user} = useUser(localStorage.getItem('token'))  ;
  const [listevents,setListevents] = useState({})
  const leads = useSelector(state=>state.leads.leads);
  const listcalendarevents= useSelector(state => state.calendar.listcalendarevents);
  const firstrequest= useSelector(state => state.firstrequest);
  //console.log('listevents' , listevents)
  useEffect(()=>{ const liste = orderevents(listcalendarevents.filter(x=>(new Date(x.startDatetime)).getTime()  >= (new Date() ).getTime()-7*24*60*60*1000 ));
                     setListevents(liste) ; 
            },[listcalendarevents])

 useEffect(()=>{  Object.keys(listevents).forEach((x,i)=> { ((new Date(x)).setHours(0, 0, 0, 0)<(new Date()).setHours(0, 0, 0, 0)) && cacherdv('jour'+i) }); },[listevents])
  
  async function showclient(e){  dispatch(modifyDisablebuttons(false)) ;
                                const response = await axios.post(`/clients/searchclientbyid/`,{id:e.target.parentNode.id},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
                                if(!response?.data?.id){ return }
                                batch(async ()=>{ await dispatch(initialiseLeadactu(response.data));  dispatch(getallformules()); dispatch(modifyEcran('ficheclient'));  })
                              }


  const modifevent =async  (e)=>{  let fiche ;  
      if(leads.filter(x=>x.id===e.target.parentNode.id)[0]){fiche = leads.filter(x=>x.id===e.target.parentNode.id)[0]}
      else{const response = await axios.post(`/clients/searchclientbyid/`,{id : e.target.parentNode.id} ,{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
      fiche = {...response.data}}
      dispatch(initialiseLeadactu(fiche)) ;  
      dispatch(seteventtochange(listcalendarevents.filter(x=>x.id===e.target.id)[0]));
      document.getElementById('detailrdv'+e.target.id)&& document.getElementById('detailrdv'+e.target.id).classList.remove('show') ;
      fiche &&   ( fiche.statut_client?.includes('rdv')?  dispatch(modifyDisplaycalendarrdv(true))  :  dispatch(modifyDisplaycalendarrelance(true))   );
  }

  useEffect(()=>{ firstrequest.calendar===true && dispatch(getallevents(navigate));   
  },[dispatch,navigate,firstrequest.calendar,user]);

  const descriptif = (y)=>{ let lead  =leads.filter(x=>x.id===y.description.split('#ID')[1])[0] ;
   return (lead?.statut_client?.split('_'))?.map((y,i)=> (listestatus[y] &&<span  key={i} className={classNames('minibadge',{"minibadgeechec":lead?.statut_client?.includes('echec')},{"minibadgegagne":lead?.statut_client?.includes('gagne')})}> {listestatus[y]} </span> )) 
     // return(((y && y.description && y.description.split('#ID')[1]) && lead )&& (!lead?'':(lead.statut_client)?<> {lead.statut_client} </>:''))
    }
    
  const indic_hot = (y)=>{ let lead  =leads.filter(x=>x.id===y.description.split('#ID')[1])[0] ;
    return(((y && y.description && y.description.split('#ID')[1]) && lead )&& (!lead?'':(lead.hot)?<>  {lead.hot===1&& <div ></div>}</>:''))
    }


    return (<div  id="listevents"  className="listevents" >

                 <div className="listevents_titre"  >Votre planning</div>
                <div  className="listedesevents"    >
                {listevents &&  Object.keys(listevents).map((x,i)=> <div  id={'jour'+i}  key={i} className='cursorpointer'  >
                                                              <div  className='formatjour' onClick={()=>{togglecacherdv('jour'+i)}} >{formatdatefrance(x)}</div>
                                                              {listevents && listevents[x].map(y=><div   key={y.id} ><div onClick={()=>showdetailevent(y)}  
                                                                                          className={classNames('formatrdv',{'formatrdv_pro':rdvpro(y)},{'formatrdv_nopro':!rdvpro(y)})}>
                                                                                              <div className='formatrdv_heuremin'>{dateheuremin(y.startDatetime)} </div>
                                                                                              <div  className='summary'>{y.summary}   
                                                                                              { rdvpro(y)&&  <span className='calendarflammeX'>{indic_hot(y)} </span>}
                                                                                              { rdvpro(y)&&  <div className='calendarstatut'>{descriptif(y)} </div>}
                                                                                               </div>
                                                                                    </div>
                                                                                    <div  id={'detailrdv'+y.id} className="hideinfos" >
                                                                                                              {y.description && y.description.split('#ID')[0]}
                                                                                              <div className="hideinfos_boutons"  id={y.description? y.description.split('#ID')[1] : Math.random()} >
                                                                                                    <button  id={y.id} onClick={modifevent} >Modifier</button>
                                                                                                    <button   onClick={showclient} >Page Client</button>
                                                                                            </div>  
                                                                                    </div>
                                                                                    </div>)}                             
                                                    </div> )}    
                  </div>      
              </div>)
}


export default ListEvents