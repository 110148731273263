import { store } from '../state/redux';



//   const listehospis = [64,74,111,121,69,120]

   const p_hospi=(x,n) => {return x.hospi >=n }
   const p_orthodontie=(x,n) => {return x.dentaire_orthodontie_nb >=n }
   const p_implant=(x,n) => {return x.dentaire_implant_nb >=n }
   const p_prothese=(x,n) =>  {return x.dentaire_prothese_nb >=n }
   const p_lentilles=(x,n) => {return x.optique_lentilles_nb >=n }
   const p_lunettes=(x,n) => {return x.optique_lunettes_nb >=n }
   const p_prevention=(x,n) => {return x.prevention >=n }
   const p_specialistes=(x,n) => {return x.consults_specialistes_nb>=n }


  //  la moins chère avec au moins 4 x  nb_etoiles
//   const  formulespardefault = (datas , nb_etoiles)=>{
//       return datas.filter(x=>(x.hospi>=nb_etoiles?1:0)+(x.dentaire_orthodontie_nb>=nb_etoiles?1:0)+(x.dentaire_prothese_nb>=nb_etoiles? 1 : 0)+(x.optique_lentilles_nb>=nb_etoiles?1:0) +
//                                 (x.optique_lunettes_nb>=nb_etoiles?1:0)+(x.prevention>=nb_etoiles?1:0) +(x.dentaire_implant_nb>=nb_etoiles?1:0)+(x.consults_specialistes_nb>=nb_etoiles? 1 : 0) >=4 )
//                    .sort((a,b)=>a.prix - b.prix)[0]          
//   }




// 

 function djerbaredux  (){
        const ficheclient = store.getState().lead.leadactu; 

        const alldatas = store.getState().formules.datas;
        const listecompagnies = store.getState().formules.listecompagniesaffichees;
        const datas = alldatas.filter(x=>listecompagnies.indexOf(x.compagnie)!==-1)
   if(datas.length===0){return []}
   const [hospi,orthodontie,protheses,lentilles,lunettes,prevention,implant,specialistes]=['need_hospi','need_orthodontie','need_protheses','need_lentilles','need_lunettes','need_prevention','need_implant','need_specialiste'].map(x=>ficheclient[x]===1?true:false );

      const filtrebesoins =(x,nb_etoiles)=>{     
            return   (  (hospi?p_hospi(x,nb_etoiles):x) &&(orthodontie?p_orthodontie(x,nb_etoiles):x) && (protheses?p_prothese(x,nb_etoiles):x) && (lentilles?p_lentilles(x,nb_etoiles):x)  && 
                         (lunettes?p_lunettes(x,nb_etoiles):x)  && (prevention?p_prevention(x,nb_etoiles):x) && (implant?p_implant(x,nb_etoiles):x)  && (specialistes?p_specialistes(x,nb_etoiles):x)     ) 
      }
      

      const formbesoins2etoiles = datas.filter(x=> filtrebesoins(x,2)).sort((a,b)=>a.prix - b.prix)[0] ;
      const formbesoins3etoiles = datas.filter(x=> filtrebesoins(x,3)).sort((a,b)=>a.prix - b.prix)[0] ;
      const formbesoins4etoiles = datas.filter(x=> filtrebesoins(x,4)).sort((a,b)=>a.prix - b.prix)[0] ;
      const formbesoins5etoiles = datas.filter(x=> filtrebesoins(x,5)).sort((a,b)=>a.prix - b.prix)[0] ;
    
      // La formule hospi
      const  formulehospi =  datas.filter(x=>x.id===64)[0];
       let formulestrouvees = []
        if(!hospi&&!orthodontie&&!protheses&&!lentilles&&!lunettes&&!prevention&&!implant&&!specialistes ){ 
                //  formulestrouvees = [formulelamoinschere(datas),formulespardefault(datas,2),formulespardefault(datas,3),formulespardefault(datas,4),formulespardefault(datas,5)];
                formulestrouvees =  datas
        }
        else if(hospi&&!orthodontie&&!protheses&&!lentilles&&!lunettes&&!prevention&&!implant&&!specialistes ){ 
                formulestrouvees = [formulehospi,formbesoins2etoiles,formbesoins3etoiles,formbesoins4etoiles,formbesoins5etoiles];
          
        }else{
                formulestrouvees = [formbesoins2etoiles,formbesoins3etoiles,formbesoins4etoiles,formbesoins5etoiles]; 
        }

        return  [...new Set(formulestrouvees.filter(x=>x!==undefined).map(x=>x.id))]

    
}




export default djerbaredux
