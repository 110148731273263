import React , {useState}  from "react";
import Ticket from "./Ticket";
import TicketModal from "./TicketModal";

import { useTickets , useUpdateTicket } from '../../state/reactquery';
import './EcranTickets.scss'

// PRETIIER
// Apel en fonction de  l ID !!!
// 
function EcranTickets(){
    const [modal, setModal ]= useState(false) ;
    const [ticketchoiced, setTicketchoiced] =useState({});
    const {data:tickets}  = useTickets() ; 
    const updateTicket = useUpdateTicket() ;


    const clickticket =(e)=>{ let tick = tickets.filter(x=>x.id=== e.target.closest((".ticketclick")).id )[0] ;
                        setTicketchoiced(tick) ;
                        (tick['statut'] === 'new')  &&  updateTicket.mutate({...tick,'statut': 'inprogress'});
                        setModal(true);
    }


    return (<div  className="ecrantickets"  >
                {modal && <TicketModal ticket={ticketchoiced}  setModal={setModal}  />  }
                      <div  className="ecrantickets_titre"  >Messages vocaux</div>   
                      <div  className="ecrantickets_sections"  >  
                            <div  className="ecrantickets_section"  > 
                                        <div  className="ecrantickets_section_top"  >Nouveau </div>  
                                        { tickets?.filter(x=>x?.statut==='new').sort((a,b)=>new Date(b?.date_ticket) - new Date(a?.date_ticket)).map(ticket=>(<Ticket key={ticket.id}  ticket={ticket}  clickticket={clickticket}  /> ))     }
                            </div>     
                            <div  className="ecrantickets_section"  > 
                                        <div  className="ecrantickets_section_top"  >En cours </div>  
                                        { tickets?.filter(x=>x?.statut==='inprogress').sort((a,b)=>new Date(b?.date_ticket) - new Date(a?.date_ticket)).map(ticket=>(<Ticket  key={ticket.id} ticket={ticket}    clickticket={clickticket} /> ))     }
                            </div>     
                            <div  className="ecrantickets_section"  > 
                                        <div  className="ecrantickets_section_top"  >Clôturé</div>  
                                        { tickets?.filter(x=>x?.statut==='finish').sort((a,b)=>new Date(b?.date_ticket) - new Date(a?.date_ticket)).map(ticket=>(<Ticket key={ticket.id}  ticket={ticket}   clickticket={clickticket} /> ))     }
                            </div>    
                      </div>   
                               
         </div>)
}


export default EcranTickets