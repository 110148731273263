import React , {useState  , useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useUser } from '../../state/reactquery';
// import { getMailsclient } from '../../utils/actions_email';
import { defineFormulesDevis } from '../../state/redux';

import GroupFiltres from './GroupFiltres';
import Client from './Client';
import ListeFormules from './ListeFomules';
import FormulesDevis from './FomulesDevis';
import CardFormuleDetail from '../../Components/CardsFormule/CardFormuleDetail';

import Postmail from '../../Components/Email/PostMail';
import Historique from './Historique'; 
import Notes from '../../Components/Notes/Notes';
import Upload from '../../Components/UploadFiles/Upload';

import InfosContrat from './InfosContrat';

import './FicheClientBottom.scss';


const champsformulesdevis = ['formuledevis1','formuledevis2','formuledevis3']
const classNames = require('classnames');
function FicheClientBottom(){
  const dispatch = useDispatch() ; 

 const formulesaffichees = useSelector(state=>state.formules.formulesaffichees);
 const datasformules = useSelector(state=>state.formules.datas);
   const ids_datasformules= datasformules.map(x=>x.id);

  const ficheclient = useSelector(state=>state.lead.leadactu);
  const formulesdevis = useSelector(state=>state.formules.formulesdevis)


  const [leftselect, setLeftselect] = useState('leftselect1')
  const [rightselect, setRightselect] = useState('rightselect1')
  const [showformule, setShowformule] = useState(false)
  const selectLeft=(e)=>{setLeftselect(e.target.id) ;  }
  const selectRight=(e)=>{setRightselect(e.target.id)}

  
  const mesdevis = champsformulesdevis.map(x=>ficheclient[x]).filter(x=>x!==undefined); 
 
  const mesdevisid = mesdevis.map(x=>x.split('_')[0]).map(x=>parseInt(x)).filter(x=>ids_datasformules.indexOf(x)!==-1);


  useEffect(()=>{  if(!ficheclient.date_lastmodif){return }
        ((new Date(ficheclient.date_lastmodif)).getTime() <  new Date().getTime()-3*31*24*60*60*1000)  &&  setRightselect('rightselect4'); 
      },[ficheclient.date_lastmodif])

  
      useEffect(()=>{dispatch(defineFormulesDevis([...datasformules].filter(x=>mesdevisid.indexOf(x.id)!==-1))) ;  },[datasformules])

    return(<div className="ficheclient_bottom">
              <div className="ficheclient_bottom_left">
                <div className="ficheclient_bottom_left_top">
                    <div id='leftselect1' className={classNames('case', {'selected':leftselect==='leftselect1'})}  onClick={selectLeft}  >Coordonnées</div>
                   <div id='leftselect2' className={classNames('case', {'selected':leftselect==='leftselect2'})}  onClick={selectLeft}  >Docs</div>
                   <div id='leftselect3' className={classNames('case', {'selected':leftselect==='leftselect3'})}  onClick={selectLeft}  >Notes</div>
                </div>
                 
               { leftselect==='leftselect1' && <Client />}  
              { leftselect==='leftselect2' &&   <div className='contentupload'><Upload     /></div>}
              { leftselect==='leftselect3' && <Notes />}

              </div>
              <div className="ficheclient_bottom_right">
              <div className="ficheclient_bottom_right_top">
                    <div id='rightselect1' className={classNames('case', {'selected':rightselect==='rightselect1'})}  onClick={selectRight}  >Formules</div>
                    <div id='rightselect2' className={classNames('case', {'selected':rightselect==='rightselect2'})}  onClick={selectRight}  >Favoris <div className='nbdevis'>{formulesdevis.length}</div></div>
                    <div id='rightselect3' className={classNames('case', {'selected':rightselect==='rightselect3'})}  onClick={selectRight}  >Messagerie</div>
                    <div id='rightselect4' className={classNames('case', {'selected':rightselect==='rightselect4'})}  onClick={selectRight}  >Contrat</div>
                    <div id='rightselect5' className={classNames('case', {'selected':rightselect==='rightselect5'})}  onClick={selectRight}  >Historique</div>
                </div>
                <div className="ficheclient_bottom_right_content">
                { rightselect==='rightselect1' && <><GroupFiltres  /> {showformule? <CardFormuleDetail setShowformule={setShowformule}  /> :<ListeFormules  setShowformule={setShowformule}  datas={formulesaffichees}  pt="pt200" /> }</>   }
             
                { rightselect==='rightselect2' && <FormulesDevis />}
                { rightselect==='rightselect3' && <Postmail  />}
            
                { rightselect==='rightselect4' &&   <InfosContrat />}
                { rightselect==='rightselect5' &&  <Historique  />}
                </div>
              </div>
    </div>)
}


export default FicheClientBottom