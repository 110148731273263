import React ,{useState}  from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import { modifyDisplayscreenchangemdp } from "../state/redux";
import { useUser } from "../state/reactquery";


import ButtonSimple from "./Buttons/ButtonSimple";

import croixclose from '../images/croixclose.svg';

import './ChangeMdp.scss' ;
function ChangeMdp(){
    const dispatch = useDispatch()  ;
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const [datamdp, setDatamdp] = useState({'oldmdp':'','newmdp1':'','newmdp2':''}) ;

    const changeinput = (e)=>{setDatamdp({...datamdp,[e.target.id]:e.target.value})} ;

   const disablebutton = datamdp['oldmdp'].length===0 ||datamdp['newmdp1'].length===0 || datamdp['newmdp2'].length===0 || datamdp['newmdp1']!==datamdp['newmdp2'] ; 


    const modifiermdp = async()=>{
        const  requete = await  axios.post(  `/users/changepassword`,{...datamdp , 'email': user['email']},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
       console.log(requete)
    }
    return(<div className="container_formulaire">
        <div className="changemdp" >
            <div className="cardresilitationclose" ><img src={croixclose}  alt="imgclose" onClick={()=>dispatch(modifyDisplayscreenchangemdp(false))}  /></div>
            <div className="changemdp_titre" >Modification de mot de passe</div>
            <div className="changemdp_line">
                <div className="changemdp_line_text" >Ancien mot de passe</div>
                <input id='oldmdp' className="changemdp_line_input"  value={datamdp['oldmdp']}  onChange={changeinput}  />
            </div>
            <div className="changemdp_line">
                <div className="changemdp_line_text" >Nouveau mot de passe</div>
                <input id='newmdp1' className="changemdp_line_input"  value={datamdp['newmdp1']}  onChange={changeinput} />
            </div>
            <div className="changemdp_line">
                <div className="changemdp_line_text" >Confirmation nouveau mot de passe</div>
                <input id='newmdp2' className="changemdp_line_input" value={datamdp['newmdp2']}  onChange={changeinput} />
            </div><br />
            
            <ButtonSimple  texte="Enregistrer"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'   onclick={modifiermdp}    disable={disablebutton}  /><br />
            
        </div>

    </div>)
}
export  default ChangeMdp