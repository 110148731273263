import React  from "react";
import { useSelector } from "react-redux";
import { useContrats , useUser} from "../../state/reactquery";

import CardFormuleContrat from '../../Components/Contrats/CardFormuleContrat';


import './ListeContrats.scss'

function ListeContrats(){

    const ficheclient = useSelector(state=>state.lead.leadactu);
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const {data: contrats} = useContrats(ficheclient?.email,user?.courtier) ; 

    const contratattentesignature = contrats?.filter(x=>x.etat_contrat==='attentesignature')?.sort((a,b)=>(new Date(b.date_insert)).getTime()  - (new Date(a.date_insert)).getTime()  );
    const contratsencours = contrats?.filter(x=>x.etat_contrat==='encours')?.sort((a,b)=>(new Date(b.date_insert)).getTime()  - (new Date(a.date_insert)).getTime()  );
    const contratsautres = contrats?.filter(x=>(x.etat_contrat!=='encours'&& x.etat_contrat!=='attentesignature'))?.sort((a,b)=>(new Date(b.date_insert)).getTime()  - (new Date(a.date_insert)).getTime()  );

    return(<>{contratattentesignature?.length >0 && <><div className="contrats_section_titre" >Contrat en attente de signature</div>
                                                            {contratattentesignature?.map(contrat=><CardFormuleContrat key={contrat.indexe}  contrat={contrat}   /> )} </>  } 
                                                        
            {contratsencours?.length >0 && <><div className="contrats_section_titre" >Contrat en cours</div>
                                                        {contratsencours?.map(contrat=><CardFormuleContrat key={contrat.indexe}  contrat={contrat}   /> )} </>  } 
            
            {contratsautres?.length >0 && <><div className="contrats_section_titre" >Résiliations contrats extérieurs</div>
                                                        {contratsautres?.map(contrat=><CardFormuleContrat key={contrat.indexe}  contrat={contrat}   /> )} </>  }
         </>)


}



export default ListeContrats