import { redirect } from "react-router-dom";
import { nanoid } from '@reduxjs/toolkit';
import axios from 'axios' ;
import { useQuery ,useMutation, useQueryClient} from '@tanstack/react-query'


import { store } from "./redux";
import { queryClient } from './queryClient';
import { ordergroupe } from "../utils/utils_courtier";


//CLIENTS
const  queryclients= async()=>{
  let response = await axios({method:'POST',url:`/clients/findallclients`,headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`    } })
  return response.data
} 

export const useClients = ()=>useQuery({ queryKey: ['clients','list'], queryFn: queryclients ,    staleTime: Infinity  });

export const useAddclient = () => {
    const queryClient = useQueryClient()
  
    return useMutation({
      mutationFn: async(lead) =>{
        const reqrecord   =await   axios.post(  `/clients/recordclients/`,lead  , {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
        return reqrecord.data},

      onSuccess: (newClient) => {
        queryClient.setQueryData('clients',previous => [...previous, newClient])
      },
    })
  }


// COURTIER
const queryCourtier= async()=>{  
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  const response =await axios.post(`/courtier/getinfoscourtier`,{'courtier':user?.['courtier']|| 'fidelis'},{headers:{'Accept':'application/json','Content-Type':'application/json'} }); 
  return response?.data || []
} 

export const useCourtier = ()=>useQuery({ queryKey: ['courtier'], queryFn:async () =>queryCourtier() ,    staleTime: Infinity  });


// TICKETS
 const queryTickets = async()=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  if(!user?.postmail) { return  [] }
  let response = await axios({method:'POST',url:`/twilio/gettickets`,data:{'postmail' : user.postmail},headers:{'Accept':'application/json','Content-Type':'application/json','Authorization':`Bearer ${localStorage.getItem('token')}`}})
  return response?.data?.tickets || []
} 

export const useTickets = ()=>useQuery({ queryKey: ['tickets'], queryFn: queryTickets ,    staleTime: Infinity  });

const updateTitcket = async(ticket)=>{  await axios({method:'POST',url:`/twilio/updateticket`,data:ticket,headers:{'Accept':'application/json','Content-Type':'application/json'}});return  ticket}

export const useUpdateTicket = () => {
  const queryClient= useQueryClient();
  return useMutation({mutationFn: updateTitcket,
                      onSuccess:(ticket)=>{queryClient.setQueryData(['tickets'],[...queryClient.getQueryData(['tickets']).filter(x=>x.id!==ticket.id),ticket])},})
}

export const useAddTicket = () => {
  const queryClient= useQueryClient();
  return useMutation({mutationFn:  (ticket)=>{  return  ticket},
                      onSuccess:(ticket)=>{ queryClient.setQueryData(['tickets'],[...queryClient.getQueryData(['tickets']),ticket])   },})
}

//USERS
const queryUsers= async()=>{
    const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
    let response = await axios.post(`/courtier/getequipe`,{'courtier':user['courtier']|| 'fidelis'},{headers:{'Accept':'application/json','Content-Type':'application/json'} });
    return ordergroupe(response.data)
} 

export const useUsers= ()=>useQuery({ queryKey: ['users'], queryFn: () =>queryUsers() ,    staleTime: Infinity  });



// DOCUMENTS 
const queryDocuments= async(id_client)=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  let response = await axios({method:'POST',url:`/files/getclientdocs`,data: {"id_client": id_client,"courtier":user?.courtier}});
  return response.data
} 

export const useDocuments = (id_client)=>useQuery({ queryKey: ['documents',id_client], queryFn: () =>queryDocuments(id_client) ,    staleTime: Infinity  });

export const useAddDocuments = (id_client) => {
  const queryDocuments= useQueryClient();
  return useMutation({
    mutationFn:  (docu)=>{  return  docu},
    onSuccess:(docu)=>{ 
      const docactus = queryDocuments.getQueryData(['documents',id_client])  ?? [];
      queryDocuments.setQueryData(['documents',id_client],[...docactus,docu])
    }
  })
}

export const addDocument = (id_client,docu)=>{
  const docactus = queryClient.getQueryData(['documents',id_client])  ?? [];
  queryClient.setQueryData(['documents',id_client],[...docactus,docu])
}

export const useDeleteDocument = (id_client) => {
  const queryClient= useQueryClient();
  return useMutation({mutationFn: async(url) =>{ const req =   await axios({method:'POST',url:`/files/deleteclientfile`,data: {url}});   ; return req?.data?.url},
                      onSuccess:(url)=>{  queryClient.setQueryData(['documents',id_client],[...queryClient.getQueryData(['documents',id_client]).filter(x=>x.url!==url)])   }})
}


// HISTORY
const queryHistories= async(id_lead)=>{ 
  let response = await axios.post( `/memory/gethorodatagebyid`,{id_lead}, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
  return response.data
} 

export const useHistories = (id_lead)=>{  return   useQuery({ queryKey: ['histories',id_lead], queryFn:async () =>queryHistories(id_lead) ,    staleTime: Infinity  }); }
  
export const addEltHistory = async(type,description)=>{
  console.log('addEltHistory')
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  const lead = store.getState().lead.leadactu ;
  const id = nanoid()  ;                                  
  await axios.post(  `/memory/writehorodatage/`,{type,description,id,'id_lead':lead.id ,'courtier':user?.['nomdomaine'],'commercial':user?.['nom']},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
  queryClient.invalidateQueries({ queryKey: ['histories',lead?.id]})     
  
}

export const useAddHistories = (id_client) => {
  const queryClient= useQueryClient();

  return useMutation({
    mutationFn:  (history)=>{  return  history},
    onSuccess:(history)=>{ 
      const newhistory = {...history,date: new Date()}
      const historyactus = queryClient.getQueryData(['histories',id_client])  ?? [];
      queryClient.setQueryData(['histories',id_client],[...historyactus,newhistory])
    }
  })
}

// EMAILS  POUR UN CLIENT
const queryListmails= async(userpostmail,leadactuemail)=>{
  let response =  await axios({method:'POST',url :`/email/getclientemails`,data:{"emails" : [userpostmail,leadactuemail]}  ,headers:{'Accept':'application/json','Content-Type':'application/json'} });
  return response.data
} 

export const useListmails = (userpostmail,leadactuemail)=>useQuery({ queryKey: ['clientmails',userpostmail,leadactuemail], queryFn: () =>queryListmails(userpostmail,leadactuemail) ,    staleTime: Infinity  });


// EMAILS   POUR LA BOITE MAIL
const queryAllmails= async()=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  let response =  await axios({method:'POST',url :`/email/getallemails`,data:{"postmail" : user.postmail}  ,headers:{'Accept':'application/json','Content-Type':'application/json'} });
  return response.data
} 

export const useAllmails = ()=>useQuery({ queryKey: ['listmails'], queryFn: () =>queryAllmails() ,    staleTime: Infinity  });

export const modifyMailRead = (id)=>{
    axios({method:'POST',url :`/email/modifymailread`,data:{id}  ,headers:{'Accept':'application/json','Content-Type':'application/json'} });
    const othermails= queryClient.getQueryData(['listmails']).filter(x=>x.id!==id)  ; 
    const mailchange = queryClient.getQueryData(['listmails']).filter(x=>x.id===id)[0]  ; 
    queryClient.setQueryData(['listmails'],[...othermails,{...mailchange, RecordType:'Open'}]);
}


// USER
const queryUser= async(token)=>{  let user  ;
   if(!localStorage.getItem('token') )  { return  { 'authenticate' : false}   ;}

  try{ const  response =  await axios({method:'POST',url:`/users/userauthenticate/`,
                                       headers:{'Accept':'application/json','Content-Type':'application/json','Authorization':`Bearer ${token}`}});
            
                                       if(response?.data?.error){    user ={ 'authenticate' : false} ;localStorage.removeItem('token') ;redirect("/login")  ; }
                                       else{user =  {...response.data , 'authenticate' : true}  ; 
                                       response.data.telephone && localStorage.setItem('teltwilio', response.data.telephone)
                                       window.usetifulTags = { userId : response.data.id , segment: response.datacourtier, firstName : response.data.firstname, lastName :response.data.lastname  , role :response.data.roles?.join(',')  }  }                                
  }catch(err){user =  { 'authenticate' : false}   }
  return user
} 

export const useUser = (token)=>useQuery({ queryKey: ['user',token], queryFn: () =>queryUser(token) ,    staleTime: Infinity  });

export const modifyUser = (datajson)=>{; 
  const user_actu = queryClient.getQueryData(['user',localStorage.getItem('token')]) ?? {};
  queryClient.setQueryData(['documents'],{...user_actu ,...datajson})
}


// NOTIFICATIONS
const queryNotifs = async()=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  if(!user?.postmail) { return  [] }
  let response = await axios({method:'POST',url:`/notifs/getnotifs`,data:{'postmail' : user.postmail },headers:{'Accept':'application/json','Content-Type':'application/json'}})
  return response?.data|| []
} 

export const useNotifs = ()=>useQuery({ queryKey: ['notifs'], queryFn: queryNotifs ,    staleTime: Infinity  });

const updateNotif = async(notif)=>{  await axios({method:'POST',url:`/notifs/updatenotif`,data:notif,headers:{'Accept':'application/json','Content-Type':'application/json'}});return  notif}

export const useUpdateNotif= () => {
  const queryClient= useQueryClient();
  return useMutation({mutationFn: updateNotif,
                      onSuccess:(notif)=>{  queryClient.setQueryData(['notifs'],[...queryClient.getQueryData(['notifs']).filter(x=>x.id!==notif.id),notif])   },})
}

export const useAddNotif = () => {
  const queryClient= useQueryClient();
  return useMutation({mutationFn:  (notif)=>{  return  notif},
                      onSuccess:(notif)=>{ queryClient.setQueryData(['notifs'],[...queryClient.getQueryData(['notifs']),notif])},})

}

export const updatenotif = (notif) =>{
    axios({method:'POST',url:`/notifs/updatenotif`,data:notif,headers:{'Accept':'application/json','Content-Type':'application/json'}});
    const othersnotifs = queryClient.getQueryData(['notifs']).filter(x=>x.id!==notif.id)  ; 
    queryClient.setQueryData(['notifs'],[...othersnotifs,notif]);
}

export const deletenotif = (notif) =>{

    axios({method:'POST',url:`/notifs/deletenotif`,data:{id:notif.id},headers:{'Accept':'application/json','Content-Type':'application/json'}});
    const othersnotifs = queryClient.getQueryData(['notifs']).filter(x=>x.id!==notif.id)  ; 
    queryClient.setQueryData(['notifs'],[...othersnotifs]);
}



export const addnotif = (notif) => {
  const notifsactus = queryClient.getQueryData(['notifs'])  ?? []
   queryClient.setQueryData(['notifs'],[...notifsactus,notif]) 
}


export const addnotiffromfront = async (notif) => {
  await axios({method:'POST',url:`/notifs/addnotif`,data:notif,headers:{'Accept':'application/json','Content-Type':'application/json'}});
  queryClient.invalidateQueries({ queryKey: ['notifs']}) ;     
}

//  MANAGERS LEADS


export const  queryManagerleads= async()=>{
  const user= queryClient.getQueryData(['user',localStorage.getItem('token')])  ;
  let response = await axios({method:'POST',url :`/clients/findmanagerclients`,data:{'commerciaux': user['commerciaux']||[]},headers:{'Accept':'application/json','Content-Type':'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}`  } });
  return response.data
} 

export const useManagerleads = ()=>useQuery({ queryKey: ['managerleads','list'], queryFn: queryManagerleads ,    staleTime: Infinity  });

export const updateonemanagerlead= (lead) => {
 
  const actus = queryClient.getQueryData(['managerleads','list']) ?? [] ;
  const others = actus.filter(x=>x.id!==lead.id)  ; 
  //axios.post(  `/clients/updateclients/`,{...lead}, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
  queryClient.setQueryData(['managerleads','list'],[...others,lead]);
}


export const updatemultimanagerlead= (listidchangecom, newcommercial ) => {
  const actus = queryClient.getQueryData(['managerleads','list']) ?? [] ;
  const changes = actus.filter(x=>listidchangecom.includes(x.id)).map(lead=> {return {...lead,commercial:newcommercial}}  )   ;
  const others = actus.filter(x=>!listidchangecom.includes(x.id))  ; 

  queryClient.setQueryData(['managerleads','list'],[...others,...changes]);
}


// NOTES
const queryNotes= async(id_lead)=>{
  let response = await axios.post( `/memory/getnotebyid`,{'id':id_lead}, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
  return response.data
} 

export const useNotes = (id_lead)=>{return   useQuery({ queryKey: ['notes',id_lead], queryFn:async () =>queryNotes(id_lead) ,    staleTime: Infinity  });}
  
export const addOneNote= (id_lead,note)=>{
  const lead = store.getState().lead.leadactu ;
  const newnote = { 'id_lead':lead.id ,id_note:nanoid() , 'note': note?.replace(/\n/g,'<br />'),date_note: new Date()}
  const notesactus = queryClient.getQueryData(['notes',lead.id])  ?? [];
  axios.post(  `/memory/writenote/`,{id:id_lead,  note},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
  queryClient.setQueryData(['notes',lead.id],[...notesactus,newnote])
}

// CONTRATS
const queryContrats= async(email,courtier)=>{
  let response = await axios.post(`/contrats/getcontratsbyemail/`,{email,courtier},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
  return response.data
}      

export const useContrats = (email,courtier)=>{ 
                                          return   useQuery({ queryKey: ['contrats',email,courtier], queryFn:async () =>queryContrats(email,courtier) ,    staleTime: Infinity  });
                                        }
  
export const deleteContrat = (indexe)=>{
  const lead = store.getState().lead.leadactu ;
  const contratsactus =  queryClient.getQueryData(['contrats',lead.email,lead.courtier])  ?? [];
  const newcontrats = contratsactus.filter(x=>x.indexe!==indexe);
   axios.post(`/contrats/deletecontrat/`,{indexe},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
   queryClient.setQueryData(['contrats',lead.email,lead.courtier],[...newcontrats])
  
}

