

import React  from 'react';
import { useSelector,  useDispatch} from "react-redux";

import { modifyDisplaygestionnewcontrat } from '../../state/redux';


import ButtonSimple from '../../Components/Buttons/ButtonSimple';
import ListeContrats from '../../Components/Contrats/ListeContrats';

import { listestatus } from '../../utils/listeprogression';

import './Contrats.scss';

const classNames = require('classnames');
function Contrats(){

  const dispatch= useDispatch();
  const lead =  useSelector(state=>state.lead.leadactu);
    return( <div id='contrats' className='contratsgestion' >
       <div className='contratcommercial'  >Origine: {lead.origine}  </div>
       <div className='contratcommercial'  >Campagne: {lead.campagne} </div>
                <div className='contratcommercial'  >Commercial: {lead.commercial?.toUpperCase()}  </div>
               
                {(lead?.statut_client?.split('_'))?.map((y,i)=> (listestatus[y] &&<span  key={i} className={classNames('minibadge',{"minibadgeechec":lead?.statut_client?.includes('echec')},{"minibadgegagne":lead?.statut_client?.includes('gagne')})}> {listestatus[y]} </span> )) }
                <ButtonSimple  texte="Nouveau Contrat" containerbouton="mbb10"  color='bouton_color_orange ' size='bouton_size_normal'   onclick={()=>{dispatch(modifyDisplaygestionnewcontrat(true))}}    disable={false}  /> 
                <h3>Liste des contrats</h3>
                <div id="listecontrats" className="liste_contrats" >
                    <ListeContrats  />
                </div>
            </div>)

}


export default  Contrats

