import React from "react";
import { useSelector , useDispatch} from "react-redux";

import {  phone,raccrocher, deccrocher}  from '../../utils/phone'
import { modifyPopupmultitel} from '../../state/redux';



import './Phone.scss';
const classNames = require('classnames');
function Phone(){
    
    const dispatch = useDispatch();
    const { etat} = useSelector(state=>state.tel);
   const ficheclient = useSelector(state=>state.lead.leadactu);

    const telephone =()=>{  ficheclient.telephone2 ? dispatch(modifyPopupmultitel(true))   :   phone(ficheclient.telephone)  ; }

    const clicktelephone = ()=>{etat==='normal'?  telephone() : etat==='sonnerie'? deccrocher() : raccrocher() }
    
    return(<div className={classNames("phone",{'telanimation':etat==='sonnerie'},{'telrouge':etat==='appel'})} onClick={clicktelephone}></div>)

 }


export default Phone