import React   from 'react';
import axios from 'axios';

import { queryClient } from '../../state/queryClient';
import { useNotifs , updatenotif ,deletenotif ,  useUser} from '../../state/reactquery';

import {formatdatenotif } from '../../utils/utils_dates'
import { searchandinitialiseclient } from '../../utils/actions_lead';

import ButtonSimple from '../Buttons/ButtonSimple';




import './Notifs.scss';

const classNames = require('classnames');

const divnotif = (notif)=>{

    const  openedtotrue = (e)=>{!([...e.target.classList].includes('delnotif')) &&  searchandinitialiseclient(notif?.id_lead, false) ;  !([...e.target.classList].includes('delnotif')) && updatenotif({...notif, 'opened':1}) ;}
    const notifdelete = ()=>{    deletenotif(notif)}

       
    return( <div key={notif.id} id={notif.id} onClick={openedtotrue} className={classNames('notif','notif_'+notif.type_notif,{'notif_noopened': notif.opened ===0  })}   >
                    <div className='delnotif'   onClick={notifdelete}  ></div>
                    <div className='notif_date' >{formatdatenotif(notif?.date_notif ?? new Date())} </div>
                    <div className='notif_texte'   dangerouslySetInnerHTML={{__html: notif?.notification}} />     
            </div>)
}
 

function Notifs(){
    const {data : notifs} = useNotifs();
    const {data: user} = useUser(localStorage.getItem('token'))  ;

    const vidernotifs = async()=>{
            await axios({method:'POST',url:`/notifs/vidernotifs`,data:{'postmail' : user.postmail },headers:{'Accept':'application/json','Content-Type':'application/json'}});           
            queryClient.invalidateQueries({ queryKey: ['notifs']})
    }

    return(<div   id='notifs' className='notifs' >
               <div className='notifs_top' > Notifications  <span>{notifs?.length} </span>    </div>
               <div className='notifs_middle' >
                       {notifs?.length>0 && notifs.sort((b,a)=> new Date(a?.date_notif) - new Date(b?.date_notif)  ).map(notif=>divnotif(notif) )  }
                 </div>
                <div className='notifs_bottom' >
                        <ButtonSimple  texte="Vider"   color='bouton_color_grisblanc' size='bouton_size_normal' disable={false} onclick={vidernotifs}   />  
                </div>
           </div>);
}


export default Notifs