import React  from "react";
import { useSelector , useDispatch} from "react-redux";

import { modifyDisplayresiliation,modifyDisplayscreensignature } from '../../state/redux';


import ListeContrats from "../../Components/Contrats/ListeContrats";
import ButtonSimple from "../../Components/Buttons/ButtonSimple";


import './InfosContrat.scss'


function InfosContrat(){
    const dispatch= useDispatch();
    const ficheclient = useSelector(state=>state.lead.leadactu);

    const clickbtnresil = ()=>{
        if(!ficheclient.adresse){ document.getElementById('popuparvertresil').classList.add('yes');
                                setTimeout(()=>{document.getElementById('popuparvertresil').classList.remove('yes')},3000)
        }else{ dispatch(modifyDisplayresiliation(true) ) }
    }


    return ( <div id='infoscontrat' className='infoscontrat' >
                        <div className="signaturenonlistee" >
                                        <div className="signaturenonlistee_texte" >Vous souhaitez vendre une formule  <span className="signaturenonlistee_texte_bold" >non listée ?</span> </div>
                                        <ButtonSimple id="signaturenonlistee" texte="Signature pour formule non listée" containerbouton="mbb10"color='bouton_color_orange mb10' size='bouton_size_normal' 
                                                       onclick={()=>{dispatch(modifyDisplayscreensignature(true))}}      disable={false}  />  
                                        <div className="signaturenonlistee_texte" > &gt;&gt; Pour les compagnies listées sur Mutuello, rendez-vous dans <span className="signaturenonlistee_texte_orange" > l’onglet formules</span> </div>
                        </div>

                        <ButtonSimple id="d" texte="Émettre une résiliation"  containerbouton="mbb10" color='bouton_backcolor_orange mb10' size='bouton_size_normal'   onclick={clickbtnresil}    disable={false}  />  
                        
                        <div id="listecontrats" className="listecontrats" >
                            <ListeContrats />
                        </div>
            </div>)
}



export default InfosContrat