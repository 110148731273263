import React,  {useState} from "react";
import axios from 'axios' ; 
import { useDispatch , useSelector} from "react-redux";
       

import { modifyDisplaycalendarrdv, modifyPopupqualiflead , modifyPopupechecs , modifyDisplaycalendarrelance , modifyQualiflead,
        modifyEtattel , modifyEcran , modifyDisplayscreengagne} from '../../state/redux';

import { addEltHistory } from "../../state/reactquery";

import {modifystatus_nrp,modifystatus_fauxnum} from "../../utils/actions_modifstatuts";
import { modifyonelead  } from "../../utils/actions_lead";
import { rdvgagne } from "../../utils/actions_echec_gagne";
import { raccrocher ,telephone , deccrocher , rejectcall} from "../../utils/phone";



import ButtonImgTel from "../../Components/Buttons/ButtonImgTel";
import Phone from "../../Components/Telephone/Phone";

import threepoints from '../../images/threepoints.svg';
import telephonietoclient from '../../images/telephonietoclient.svg';

import './Telephonie.scss'
const classNames = require('classnames');
function Telephonie(){
    const dispatch = useDispatch();
    const lead = useSelector(state=>state.lead.leadactu);
    const eventtochange = useSelector(state=>state.calendar.eventtochange);
    const  ecran = useSelector(state=>state.display.ecran) ;

    const { etat} = useSelector(state=>state.tel);

    const [showboutons,setShowboutons] = useState(false) ;
    const [muted, setMuted] = useState(false);

    const relancerdv = ()=>{dispatch(modifyDisplaycalendarrelance(true)) }    
    const prendrerdv = ()=>{dispatch(modifyDisplaycalendarrdv(true)) }

    const maintientcontrat = () =>{
        axios.post(  `/contrats/maintiencontrat/`,{email: lead.email} , {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
        modifyonelead({'statut_client': 'gagne', 'hot': 0}) ;  // 'recyclage' : 2 , 
        rdvgagne(lead.id); 
        addEltHistory('qualiflead', `<div class='horoline1' >Recyclage: Maintient du contrat </div>`)  
        dispatch(modifyQualiflead(true));
        dispatch(modifyPopupqualiflead(false));
    }

    const tooglemute =() =>{  
        if (telephone.getTelephone().connexion===null ){return }
         setMuted(muted => !muted)
         telephone.getTelephone().connexion.isMuted() ?   telephone.getTelephone().connexion.mute(false) : telephone.getTelephone().connexion.mute(true)   }

    return( <div className="telephonie">

                {(ecran !== 'ficheclient' && !showboutons) && <div className="threepoints" onClick={() => { setShowboutons(true) }}  > <img src={threepoints} alt='threepoints' /> </div>}

                {((ecran !== 'ficheclient' && showboutons) || ecran === 'ficheclient') && <>
                    {lead.recyclage === 2 && <ButtonImgTel texte="Maintien" type='bouton_maintien' onclick={maintientcontrat} />}
                    {lead.contratsigne===1 &&<ButtonImgTel texte="Gagné" type='bouton_gagne' onclick={() => {dispatch(modifyDisplayscreengagne(true))}} />}
                    <ButtonImgTel texte={(eventtochange.id && lead.statut_client?.includes('relance')) ? "Modifier Relance" : "Relancer"} type='bouton_relance' onclick={relancerdv} />
                    <ButtonImgTel texte={(eventtochange.id && lead.statut_client?.includes('rdv')) ? "Modifier Rdv" : "Rdv"} type='bouton_rdv' onclick={prendrerdv} />
                    <ButtonImgTel texte="NRP" type='bouton_nrp' onclick={() => { raccrocher(); modifystatus_nrp(); }} />
                    <ButtonImgTel texte="Fx Num" type='bouton_fauxnum' onclick={() => { modifystatus_fauxnum(); raccrocher() }} />
                    <ButtonImgTel texte="Echec" type='bouton_echec' onclick={() => { dispatch(modifyPopupqualiflead(false)); dispatch(modifyPopupechecs(true)); raccrocher() }} /></>}
                <div className="telephonietextes" >
                    <div className="telephonienom" >{lead.nom??''} {lead.prenom??''}</div>
                    <div className="telephoniestatus" >{etat === 'appel' ? 'Appel en cours' : etat === 'sonnerie' ? 'Appel rentrant' : 'En pause'}</div>
                </div>

                { etat === 'sonnerie'?<div className="telephoniebtns" >
                                            <div className="telephoniebtns_repondre" onClick={()=>{deccrocher();}} >Répondre</div>
                                            <div className="telephoniebtns_reject" onClick={()=>{rejectcall(); dispatch(modifyEtattel('normal')) ;  }}  >Messagerie</div>
                                        </div>:<>
                                        <div className={classNames("silence", { "ismuted": muted === true }, { "nomuted": muted === false })} onClick={tooglemute} ></div>
                                       { ecran !=='ficheclient'  &&   <div className="telephonietoclient" onClick={()=>{ dispatch(modifyEcran('ficheclient'));dispatch(modifyEtattel('normal')) }}  > <img src={telephonietoclient} alt='telephonietoclient' />  </div>}
                                        <Phone />
                          </>  }

            </div> )
}



export default Telephonie