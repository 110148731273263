import React , { useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import { filterFormules,   defineListeCompagniesAffichees} from '../../state/redux';
import djerbaredux from "../../utils/djerbaredux";

import { logocompagnie } from '../../utils/logocompagnie';


import './FiltresCompagnies2.scss'
const classNames = require('classnames');
function FiltresCompagnies2(){
    const dispatch = useDispatch();

    const [tooglebox, setTooglebox] = useState(true)
    const datas = useSelector(state=>state.formules.datas);
    const listecompagnies =  useSelector(state=>state.formules.listecompagnies);
    const listecompagniesaffichees =  useSelector(state=>state.formules.listecompagniesaffichees);

    datas.map(x=>x.compagnie).filter((x,i)=>datas.map(x=>x.compagnie).indexOf(x)===i).sort();

    const clickCompagnie =(e)=>{
            if(listecompagniesaffichees.indexOf(e.target.id)===-1){  
                dispatch(defineListeCompagniesAffichees([...listecompagniesaffichees,e.target.id]));
            } else{ dispatch(defineListeCompagniesAffichees(listecompagniesaffichees.filter(x=>x!==e.target.id)));}
            dispatch(filterFormules({'type':'liste', 'liste':djerbaredux()}))
    }

    const clickboxcompagnies= ()=>{
        if(tooglebox===true){ setTooglebox(false)  ; dispatch(defineListeCompagniesAffichees([]));}
        else{ setTooglebox(true)  ;dispatch(defineListeCompagniesAffichees([...listecompagnies])); }
        dispatch(filterFormules({'type':'liste', 'liste':djerbaredux()}))
    }
//    useEffect(()=>{defineListeCompagnies(listecompagnies);defineListeCompagniesAffichees(listecompagnies); },[datas,listecompagnies]);

    
    return( <div className="groupeimgcompagnies">
                   <div className={'casevalidall '+(tooglebox ?'validate':'')}  onClick={clickboxcompagnies}></div>  
                   {listecompagnies.map((comp,index)=>(<div className="container_imgcompagnies"  id={comp} key={comp}    onClick={clickCompagnie}> 
                                                                <div className={classNames('compagnievu',{'compagnievu_selected':listecompagniesaffichees.indexOf(comp)===-1})} ></div>
                                                                <img src={logocompagnie(comp?.toUpperCase())}  width='50' alt='logoCompagnie'/>
                                                        </div>)  )}
         </div>)
}


export default FiltresCompagnies2;

