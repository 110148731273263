import axios from 'axios';

import { store,modifyDisplayscreensignature} from '../state/redux';
import { addEltHistory } from "../state/reactquery";
import { queryClient } from '../state/queryClient';

import { modifyonelead , popup} from "./actions_lead";
import { threeyearsafter  } from './utils_dates';
import { progressions_gagne } from './listeprogression';


const listesigneagrees=[]

export const signatureformule = async(formule) =>{
    console.log('signatureformule')
    const lead = store.getState().lead.leadactu; 
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);


    if(lead.id==="leadfirstline"){ 
        setTimeout(()=>{modifyonelead({'formulesigne':formule.id+'_'+formule.prix,'formulesigne_compagnie':formule.compagnie , 'formulesigne_formule':formule.nom_formule , 'formulesigne_gamme':formule.gamme, 'date_rdvrelance':threeyearsafter(lead.date_lastmodif),'statut_client':'attente_signature', });    },5000)
        setTimeout(()=>{modifyonelead({'formulesigne':formule.id+'_'+formule.prix,'formulesigne_compagnie':formule.compagnie , 'formulesigne_formule':formule.nom_formule , 'formulesigne_gamme':formule.gamme, 'date_rdvrelance':threeyearsafter(lead.date_lastmodif),'statut_client':'gagne', });    },10000)
     return 
    }
  
    const nb_date_naissances = ['date_naissance','date_naissance_conjoint','date_naissance_enf1','date_naissance_enf2','date_naissance_enf3','date_naissance_enf4','date_naissance_enf5'].map(x=>lead[x]).filter(x=>x!==undefined && x!==null).length;
    const nb_noms = ['nom','nom_conjoint','nom_enf1','nom_enf2','nom_enf3','nom_enf4','nom_enf5'].map(x=>lead[x]).filter(x=>x!==undefined && x!==null).length;
    const nb_prenoms = ['prenom','prenom_conjoint','prenom_enf1','prenom_enf2','prenom_enf3','prenom_enf4','prenom_enf5'].map(x=>lead[x]).filter(x=>x!==undefined && x!==null).length;
    const nb_numsecus = ['numsecu','numsecu_conjoint','numsecu_enf1','numsecu_enf2','numsecu_enf3','numsecu_enf4','numsecu_enf5'].map(x=>lead[x]).filter(x=>x!==undefined && x!==null).length;
     
    const validation = nb_noms ===nb_date_naissances &&  nb_prenoms ===nb_date_naissances  &&  nb_numsecus ===nb_date_naissances && lead.adresse && lead.iban1;
   
    if(!validation){ document.getElementById('popuparvertsign').classList.add('yes'); setTimeout(()=>{document.getElementById('popuparvertsign').classList.remove('yes')},3000)  ; return } ;
    if(listesigneagrees.includes(formule.compagnie)){
        addEltHistory('emailliensignaturecrmsend',  `<div class='horoline1' > Email <span  className='horolink' >Lien signature</span>  envoyé </div><div class='horoline2' >${formule.compagnie +" - "+ formule.gamme +" - "+ formule.nom_formule  +" - "+ formule.prixe}</div>`)
        popup('popupsignature');
        modifyonelead({'formulesigne':formule.id+'_'+formule.prix,'formulesigne_compagnie':formule.compagnie , 'formulesigne_formule':formule.nom_formule , 'formulesigne_gamme':formule.gamme, 'date_rdvrelance':threeyearsafter(lead.date_lastmodif),
                        'statut_client':progressions_gagne.includes(lead.statut_client)?lead.statut_client:lead.statut_client?.includes('rdv')?'attente_signature_rdv':lead.statut_client?.includes('relance')?'attente_signature_relance':'attente_signature'});
        await  axios.post(`/signature/sendsignatureyousign`,{lead,user, formule}, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}} ) ;
        queryClient.invalidateQueries({ queryKey: ['histories', lead.id]})     ;
        queryClient.invalidateQueries({ queryKey: ['contrats',lead?.email,user?.nomdomaine]}) ;
    }else{ 
        await  modifyonelead({'formulesigne':formule.id+'_'+formule.prix,'formulesigne_compagnie':formule.compagnie , 'formulesigne_formule':formule.nom_formule , 'formulesigne_gamme':formule.gamme});
        store.dispatch(modifyDisplayscreensignature(true)) 
    }

}





