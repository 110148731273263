import React , { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import {telephone  }  from '../../utils/phone';
import { filterFormules, modifyChampsLeadactu } from '../../state/redux';
import djerbaredux from "../../utils/djerbaredux";
import { majuscule } from "../../utils/validations";


import './FiltresBesoins.scss'
const classNames = require('classnames');
function FiltresBesoins(){
    const {getTelephone} = telephone ; 
    const [tooglebox, setTooglebox] = useState(false)
    const dispatch = useDispatch();
     const {need_hospi,need_orthodontie,need_protheses,need_lentilles,need_lunettes,need_prevention,need_implant,need_specialiste}= useSelector(state=>state.lead.leadactu)
    const [jsonliste , setJsonliste] = useState({need_hospi:need_hospi===1?1:0 ,need_orthodontie:need_orthodontie===1?1:0 ,need_protheses:need_protheses===1?1:0 ,
                                                need_lentilles:need_lentilles===1?1:0 ,need_lunettes:need_lunettes===1?1:0 ,need_prevention: need_prevention===1?1:0,
                                                need_implant:need_implant===1?1:0 ,need_specialiste:need_specialiste===1?1:0 })


    const clickBesoin = (e)=>{console.log('getTelephone().device',(new Date()).toISOString() , getTelephone(), getTelephone().device , getTelephone().device)
        const lines_choice = {...jsonliste,[e.target.id]: jsonliste[e.target.id] !==1?1:0  }
        dispatch(modifyChampsLeadactu( {[e.target.id]: jsonliste[e.target.id] !==1?1:0 }));
         setJsonliste(lines_choice)  ;
        dispatch(filterFormules({'type':'liste', 'liste':djerbaredux()}))
    }
    
    const clickallbesoin=async ()=>{ 


        if(tooglebox===true){ setTooglebox(false)  ; 
         setJsonliste({need_hospi:0 ,need_orthodontie:0 ,need_protheses:0 ,need_lentilles:0 ,need_lunettes:0 ,need_prevention: 0,need_implant:0 ,need_specialiste:0 });
             dispatch(modifyChampsLeadactu( {need_hospi:0 ,need_orthodontie:0 ,need_protheses:0 ,need_lentilles:0 ,need_lunettes:0 ,need_prevention: 0,need_implant:0 ,need_specialiste:0 }));
             dispatch(filterFormules({'type':'all'})); 

     }
        else{ setTooglebox(true)  ;    
              setJsonliste({need_hospi:1 ,need_orthodontie:1 ,need_protheses:1 ,need_lentilles:1 ,need_lunettes:1 ,need_prevention: 1,need_implant:1 ,need_specialiste:1 });
              await dispatch(modifyChampsLeadactu( {need_hospi:1 ,need_orthodontie:1 ,need_protheses:1 ,need_lentilles:1 ,need_lunettes:1 ,need_prevention: 1,need_implant:1 ,need_specialiste:1}));
             dispatch(filterFormules({'type':'liste', 'liste':djerbaredux()})) ;
        }
    }
    const listebesoins = ['need_hospi','need_orthodontie','need_protheses','need_implant','need_lentilles','need_lunettes','need_specialiste','need_prevention']
    
    return( 
    <div className="divfiltresbesoins">
         <div className={'filtresnone '+(tooglebox ?'validate':'')}   onClick={clickallbesoin}></div>  
         {listebesoins.map((x,index)=><button id={x} key={x}   className={classNames("btn btn_besoins",{'first':index===0},  {'last':index===listebesoins.length-1},  {'selected':jsonliste[x]===1})}  onClick={clickBesoin}>  {majuscule(x.replace('need_',''))} </button>)}</div>)
}


export default FiltresBesoins;

