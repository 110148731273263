import React, { useState , useEffect } from "react";
import {useDispatch,batch} from 'react-redux';

import axios from "axios";

import { initialiseLeadactu , modifyEcran} from '../../state/redux';
import { getallformules } from "../../utils/calculstarifs";
import { useAllmails , useUser , modifyMailRead } from "../../state/reactquery";

import { formatdatenotif } from "../../utils/utils_dates";

import './EcranEmails.scss' ;

const classNames = require('classnames');
function  EcranEmails(){
    const dispatch = useDispatch(); 
    
    const {data: user} = useUser(localStorage.getItem('token')) ;
    const {data: emails} = useAllmails(user?.postmail) ; 
  
    const [choice , setChoice] = useState('selectemailsentrants')  ;
    const [emailchoice , setEmailchoice] = useState(null)  ;
    const [namesearch, setNameseach]  = useState('')  ;
    const [leadmail, setLeadmail] = useState(null) ;
    const [emailsaffiches, setEmailsaffiches] = useState([]) ;


    useEffect(()=>{choice==='selectemailsentrants' ?setEmailsaffiches(emails?.filter(x=>x.emailTo ===user?.postmail).sort((a,b) => new Date(b.created_at) - new Date(a.created_at))) :
                                                    setEmailsaffiches(emails?.filter(x=>x.emailFrom ===user?.postmail).sort((a,b) => new Date(b.created_at) - new Date(a.created_at)))},[choice,emails])  ;

    const mailsnonouverts = emails?.filter(x=>x.emailTo ===user?.postmail && x.RecordType!=='Open').sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
    const changenamesearch =(e)=>{setNameseach(e.target.value) ;
                                  e.target.value?.length ===0 ? setEmailsaffiches(emails) : setEmailsaffiches(emails.filter(x=>x.nom?.toLowerCase()?.includes(e.target.value?.toLowerCase())));}


    const gotothislead =async(e)=>{
        const response = await axios.post(`/clients/searchclientbyid/`,{id:leadmail?.id},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
        if(!response?.data?.id){ return }
        batch(async ()=>{ await dispatch(initialiseLeadactu(response.data));  dispatch(getallformules()); dispatch(modifyEcran('ficheclient'));  })
    }


    const choiceemail =async (e)=>{
        const  messagtechoiced = emails.filter(x=>x.id===e.target.id)?.[0] ;

        if(messagtechoiced?.RecordType!=='Open'&&  messagtechoiced.emailTo===user?.postmail ){  modifyMailRead(messagtechoiced?.id)}

        const reqclient = await  axios.post(  `/clients/searchclientbyid`,{'id' :messagtechoiced?.id_lead });
        setLeadmail(reqclient.data) ;
        setEmailchoice(messagtechoiced)
    }

// {message?.listattachements?.length > 0 && <div >
//     {message?.listattachements?.map((link, index) => (<a key={index} className="messageattachementlink" href={link} target="blank" >{link?.split('/')?.reverse()[0]}</a>))}</div>}

const mail_button = (message)=>(<div key={message?.id} className={classNames("mailbutton",{"emailselected": message?.id===emailchoice?.id } , {"emailnonlu": message.RecordType!=='Open'})  } id={message?.id} onClick={choiceemail} >
                                  <div className="mailbutton_initiales" >{message?.nom?.[0]}{message?.prenom?.[0]}</div>
                                  <div className="mailbutton_details" >
                                        <div className="mailbutton_details_time" >{formatdatenotif(message?.created_at)}</div>
                                        <div className="mailbutton_details_subject" >{message?.Subject}</div>
                                        <div className="mailbutton_details_nomprenom" >{message?.nom} {message?.prenom}</div>
                                  </div>
                                </div>)
     
 
    return(<div className="container_screenmails">
                 <div className="ecranmails_left">
                        <div className="ecranmails_left_top">
                            <div className={classNames("ecranmails_left_top_btn",{"btnchoiced":choice==="selectemailsentrants"})} onClick={() =>{setChoice('selectemailsentrants')}}  >Messages reçus <div className="nbmailsnonouverts">{mailsnonouverts?.length}</div>  </div>
                            <div className={classNames("ecranmails_left_top_btn",{"btnchoiced":choice==="selectemailsortants"})}  onClick={() =>{setChoice('selectemailsortants')}}   >Messages envoyés</div>
                        </div>
                        <input className="ecranmails_left_search"  value={namesearch}  onChange={changenamesearch}  placeholder="Rechercher un nom" />

                        <div className="ecranmails_left_liste">
                            {emailsaffiches?.length >0 && emailsaffiches.map(email => mail_button(email)  )}
                        </div>
                 </div>
                 <div className="ecranmails_right">
                   {leadmail && <div className="ecranmails_right_top">
                            <div className={classNames("ficheclienttopleft_flamme", { "cold": leadmail?.hot === 0 }, { "hot": leadmail?.hot === 1 })}  ></div>
                            <div className="ficheclienttopleft_avatar"  >
                                <div className="avatar">  {(leadmail?.nom && leadmail?.prenom) ? leadmail?.nom[0] + leadmail?.prenom[0] : leadmail?.email ? leadmail?.email[0] : ''} </div>
                            </div>
                                <div  className="ecranmails_right_top_texte"   > {leadmail?.nom ? leadmail?.nom + ' ' + leadmail?.prenom : leadmail?.email}
                                    {(leadmail?.statut_client?.includes('rdv')) ? <span className='statutcontacttop'> RDV </span> : <></>}
                                    {(leadmail?.statut_client?.includes('relance')) ? <span className='statutcontacttop'> RELANCE  </span> : <></>}
                                    {(leadmail?.statut_client?.includes('nrp')) ? <span className='statutcontacttop'> NRP </span> : <></>}
                                </div>
                            <div  className="ecranmails_right_gotolead" onClick={gotothislead}   >      
                            </div>
                    </div>}
                    { emailchoice &&   <div className="ecranmails_right_bottom">
                         <div className="ecranmails_right_bottom_email">
                            <div className="ecranmails_right_bottom_email_titre">{emailchoice?.Subject} </div>
                            <div className="ecranmails_right_bottom_email_time"> {formatdatenotif(emailchoice?.created_at)}</div>
                            <div className="ecranmails_right_bottom_email_content"  dangerouslySetInnerHTML={{ __html: emailchoice?.TextBody?.replaceAll('\\n','') }} ></div>
                           
                        </div>
                    </div>}
                 </div>
</div>

    )
}

export default EcranEmails