import React ,{useState, useEffect} from 'react';

import './Calendar.scss'

const classNames = require('classnames');

const listemois = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']
const joursdelasemaine = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"]

const today = new Date();   const [anneeactu, moisactu] = [today.getFullYear(),today.getMonth()];
//const touteslescases = document?.getElementsByClassName('casetwodates');

function CalendarTwoDates({selectdate, setSelecdate}){


    const [mois, setMois]  = useState(moisactu);
    const [annee, setAnnee]  = useState(anneeactu);
    const [nbjoursmois, setNbjoursmois] = useState('');
 
    const [indexfirstday, setIndexfirstday]  = useState();

    const  touteslescases = document?.getElementsByClassName('casetwodates');
    const idcases =     Array.from(touteslescases).map(( unecase ) => unecase['id'])  ;
    const changeecrancalendar =async (mois,annee)=>{  
        const idcases =     Array.from(touteslescases).map(( unecase ) => unecase['id']) 
         Array.from(touteslescases).filter(x=>idcases.includes(x.id)).map(( unecase ) => unecase.classList.remove('selected0','selected'))  ;
        setMois(mois) ; setAnnee(annee) ;  setNbjoursmois(new Date(annee,mois+1,0).getDate()) ;
        setIndexfirstday(joursdelasemaine.map(x=>x.toLowerCase()).indexOf(new Date(annee+'-'+(mois+1)+'-0'+1).toLocaleDateString('fr-FR', { weekday: 'long'})))   ;
        if(selectdate?.date1 && selectdate?.date2 ){
            Array.from(touteslescases).forEach(( unecase ) => { (  new Date(unecase['id']) >=new Date(selectdate?.date1) &&   new Date(unecase['id']) <=new Date(selectdate?.date2)  )  && unecase.classList.add('selected0')  } )
         }
    }
    
    useEffect(()=>{ changeecrancalendar(mois,annee) ;  },[idcases])

    const changemonth = (e)=>{const [newmonth,newyear]=e.target.id==='next'?[(mois+1)%12,annee+(mois===11?1:0) ]:[mois===0?11:(mois-1)%12,annee-(mois===1?1:0)]; 
                                 changeecrancalendar(newmonth,newyear) ;}


        const clickjour = (e)=>{if(!selectdate.date1){  setSelecdate({...selectdate,date1:new Date(new Date(e.target.id).setHours(0,0,0))  } ) ; 
                               }else if(selectdate.date1 && selectdate.date2  ){ 
                                       Array.from(touteslescases).map(( unecase ) => unecase.classList.remove('selected0','selected'))  ;
                                       setSelecdate({date1:new Date(new Date(e.target.id).setHours(0,0,0))  } ) ; 
                                } else if((new Date(selectdate.date1)).getTime()  > new Date(new Date(e.target.id).setHours(23,59,59))    ) { return ;
                                }else{    setSelecdate({...selectdate,date2:new Date(new Date(e.target.id).setHours(23,59,59)) } )} ;
                            }
  

        const mouseover=(e)=>{if(!selectdate.date1){return }
                for(let i=0;i<touteslescases.length; i++){           
                    let macase = touteslescases[i]
                    if(new Date(selectdate.date1)<= new Date(macase['id']) && new Date(e.target.id) >= new Date(macase['id']) ){macase.classList.add('selected');}
                    else{macase.classList.remove('selected');}
                }
    }

    return(<div className='calendrier_contenttwodates' >
                      <div className='datepicker' >
                       
                       <div className='datepicker_top' >
                               <div id='back'  onClick={changemonth} ></div>
                               <div className='datepicker_top_middle'>{listemois[mois]} {annee}</div>
                               <div id='next' onClick={changemonth} ></div>
                       </div>
                     
                       <div className='datepicker_pickercontainer' >
                           <div id='datepicker_picker' className='datepicker_picker' >
                                   {joursdelasemaine.map(x=>(<div  key={x.substring(0,2)}  className='day2lettres' >{x.substring(0,2)}</div>))}
                                   {[...Array(indexfirstday).keys()].map((x)=>(<div key={'vide'+x}  ></div>))  }
                                   {[...Array(nbjoursmois).keys()].map(x=>x+1).map((x,i)=>(<div key={i} id={annee+'-'+(mois+1)?.toString()?.padStart(2,'0')+'-'+x} onClick={clickjour}  onMouseOver={mouseover}
                                                                                           className={classNames('casetwodates',  {'today':  mois===moisactu && annee===anneeactu && x===today.getDate()},
                                                                                                               {'selected': (new Date(annee+'-'+(mois+1)?.toString()?.padStart(2,'0')+'-'+x)).getTime() === new Date((new Date(selectdate?.date1)).setHours(0,0,0)).getTime() })}  >
                                                                                           {x} </div>))  }    
                           </div>
                       </div>
                   </div>
            </div>)
}

export default CalendarTwoDates