import React from "react";

import TabLeadsManager from "../../Components/TabLeads/TabLeadsManager";



import './EcranManager.scss'
function EcranManager(){

    return (<div className="ecranmanager" >
       <TabLeadsManager  />
    </div>)
}



export default EcranManager
