import React  , {useState,useEffect} from "react";
import { useSelector  } from "react-redux";
import axios from "axios";
import { nanoid } from '@reduxjs/toolkit';

import { useAddDocuments , useDeleteDocument } from "../../state/reactquery";
import { modifyonelead , senddevisfromfiles } from '../../utils/actions_lead';
import { useDocuments } from "../../state/reactquery";

import { threeyearsafter } from "../../utils/utils_dates";
import uploadcheck  from '../../images/uploadcheck.svg'; 
import uploaddel from '../../images/uploaddel.svg'; 

import './Upload.scss'

const classNames = require('classnames');


const nomsdocuments ={ 'cni':"Pièce d’identité",'attestationro':"Attestation  R.O", 'ribfirst':"RIB Prélèv.", 'ribsecond':"RIB Remb.",
                        'kbis':"KBIS",'resil':"Lettre de résil." ,'signature':"Contrat Signé",'devis':"Devis",'autre':"Autre" }

let jsonliste ={ 'cni':[],'attestationro':[], 'ribfirst':[], 'ribsecond':[], 'kbis':[],'resil':[],'signature':[],'devis':[],'autre':[] }
                        

const test_fichiers = (liste)=>{return ['cni','attestationro','ribfirst','signature'].every(elt=>[...Object.keys(liste).filter(x=>liste[x].length >0)].includes(elt) )}   ;

const orderdocuments = (liste) => {

let jsonliste ={ 'cni':[],'attestationro':[], 'ribfirst':[], 'ribsecond':[], 'kbis':[],'resil':[],'signature':[],'devis':[],'autre':[] }
  if(!liste){ return }
    for(let doc of liste ){ 
      jsonliste.hasOwnProperty(doc.type_file)?(jsonliste[doc.type_file]=[...jsonliste[doc.type_file] , doc]):(jsonliste['autre'] = [...jsonliste['autre'],doc]) 
    }
    return jsonliste
}

function UploadFile({listedocs,typefile}){

  const ficheclient = useSelector(state=>state.lead.leadactu);
  
    const deledocuments = useDeleteDocument(ficheclient?.id_client) ; 
    const addDocument = useAddDocuments(ficheclient?.id_client) ;

    const [progress, setProgress] = useState(0);

    const uploadfileins3 = async (e)=>{  console.log(typefile)
      if(typefile=== 'signature'){return }
      typefile==='devis' &&  senddevisfromfiles()  ; 

      const nomfichier = typefile+(nanoid(5)).toString() ;
      const testfichiersobligatoire = ['cni','attestationro','ribfirst'].every(elt=>[...new Set([...Object.keys(listedocs).filter(x=>listedocs[x].length >0), typefile])].includes(elt)) ;
      console.log('testfichiersobligatoire',testfichiersobligatoire)
      if(testfichiersobligatoire     &&  ficheclient?.contratsigne===1)  {  modifyonelead({'documents':1, 'statut_client':'gagne', 'date_rdvrelance':threeyearsafter(ficheclient.date_lastmodif),'documents': 1 ,'hot':0})}
      if(testfichiersobligatoire     &&  ficheclient?.contratsigne!==1) {   modifyonelead({'documents':1,  'statut_client':ficheclient.statut_client?.includes('rdv')?"attente_signature_rdv":ficheclient.statut_client?.includes('relance')?"attente_signature_relance":  'attente_signature'})}
  

      const data = new FormData();
      data.append('datajson', JSON.stringify({courtier:ficheclient?.courtier,id_client:ficheclient['id_client'] ,id_lead:ficheclient['id_client'] ,type_file:typefile,commercial:ficheclient?.commercial,nomfichier }) )
      data.append('file',e.target.files[0],`/${nomfichier}.${e.target.files[0].name.split('.')[1]}`);
      const newdoc = await axios.post(`/files/addclientfile/`, data ,{  headers:  { 'Accept': 'application/json',"Content-Type": "multipart/form-data"},
                                         onUploadProgress:(p)=>{   const percent= Math.round((p.loaded*100)/p.total) ; setProgress(percent); percent===100 && setTimeout(()=>{setProgress(0)},3000);  } })

      setTimeout(()=>{ addDocument.mutate(newdoc.data); document.getElementsByName(typefile)[0].value= null ;  },200); 
}




//     const uploadfileins3 = async (e)=>{ 
//       typefile==='devis' &&  senddevisfromfiles()  ; 

//       const data = new FormData();
//       const nomfichier = typefile+(nanoid(5)).toString() ;
//       const testfichiersobligatoire = ['cni','attestationro','ribfirst'].every(elt=>[...new Set([...Object.keys(listedocs).filter(x=>listedocs[x].length >0), typefile])].includes(elt)) ;
//       if(typefile=== 'contratsigne'  &&  ficheclient?.documents===1) {  modifyonelead({'contratsigne':1 })}
//       if(typefile=== 'contratsigne'  &&  ficheclient?.documents!==1) {  modifyonelead({'contratsigne':1, 'statut_client':ficheclient.statut_client?.includes('rdv')?"attente_documents_rdv":ficheclient.statut_client?.includes('relance')?"attente_documents_relance":  'attente_documents'})}
//       if(testfichiersobligatoire     &&  ficheclient?.contratsigne===1)  {  modifyonelead({'documents':1, 'statut_client':'gagne'})}
//       if(testfichiersobligatoire     &&  ficheclient?.contratsigne!==1) {   modifyonelead({'contratsigne':1,  'statut_client':ficheclient.statut_client?.includes('rdv')?"attente_signature_rdv":ficheclient.statut_client?.includes('relance')?"attente_signature_relance":  'attente_signature'})}
  
//       let datajson ={courtier: ficheclient?.courtier, id_client: ficheclient['id_client'] ,id_lead: ficheclient['id_client'] ,type_file: typefile,commercial: ficheclient?.commercial,nomfichier }

      
//       data.append('datajson', JSON.stringify(datajson) )
//       data.append('file',e.target.files[0],`/${nomfichier}.${e.target.files[0].name.split('.')[1]}`);
//       const newdoc = await axios.post(`/files/addclientfile/`, data ,{  headers:  { 'Accept': 'application/json',"Content-Type": "multipart/form-data"},
//                                          onUploadProgress:(p)=>{   const percent= Math.round((p.loaded*100)/p.total) ; setProgress(percent); percent===100 && setTimeout(()=>{setProgress(0)},3000);  } })

//       setTimeout(()=>{ addDocument.mutate(newdoc.data); document.getElementsByName(typefile)[0].value= null ;  },200); 
// }



  const delfile = (e)=>{  deledocuments.mutate(e.target.id) ;
                          let newliste = {...listedocs}  ; newliste[typefile] = listedocs[typefile].filter(x=>x!==e.target.id);  
                          !test_fichiers(newliste)&&  modifyonelead({'documents': 0, 'statut_client':ficheclient.statut_client?.includes('rdv')?"attente_documents_rdv":ficheclient.statut_client?.includes('relance')?"attente_documents_relance":  'attente_documents' }) ;
                        }   

  useEffect(()=>{
    document.getElementById(typefile) && document.getElementById(typefile+typefile).addEventListener('dragover', () => {  document.getElementById(typefile).classList.add('hover') });
    document.getElementById(typefile) && document.getElementById(typefile+typefile).addEventListener('dragleave', () => {  document.getElementById(typefile).classList.remove('hover') });
  });          

   return(<div id={typefile}    className={"uploadvalidcontrat "+((listedocs[typefile]&& listedocs[typefile].length >0 )?'uploaded':'uploadwait') } >
                <input id={typefile+typefile} className={classNames("uploadvalidcontrat_input",{"little":(listedocs[typefile]&& listedocs[typefile].length >0)})} type="file" name={typefile} onChange={uploadfileins3} />
                <div className="uploadvalidcontrat_2">
                      <span className={"uploadvalidcontrat_2_top "+((listedocs[typefile]&& listedocs[typefile].length >0 )?'uploaded':'uploadwait')} >{nomsdocuments[typefile]}</span>
                      {(listedocs[typefile]&& listedocs[typefile].length >0 ) &&  <img  src={uploadcheck} alt='imgcheck' />}<br/> 
                      
                      {listedocs[typefile] && listedocs[typefile].map((doc,i)=>{ return <div  className="validcontrat_linkdoc" key={i}>
                                                                                          <a  className="uploadcontratlink" href={doc.url} rel="noopener noreferrer"   target="_blank">   {doc?.nomfichier}</a>   
                                                                                          <img id={doc?.url} className="uploaddel" src={uploaddel} alt='imgdel'  onClick={delfile} />  
                                                                                  </div>})}                       
                  </div>
                <div className={"uploadvalidcontrat_progress_container "+((listedocs[typefile]&& listedocs[typefile].length >0 )?'uploaded':'uploadwait')} >
                   <div className={"uploadvalidcontrat_progress lenght"+progress} > </div>
                </div>
          </div>)
}


function Upload(){
      const ficheclient = useSelector(state=>state.lead.leadactu);
      const {data: documents} = useDocuments(ficheclient?.id_client) ;
      const listedocs =documents?  orderdocuments(documents) : jsonliste; 

      return(<>{Object.keys(nomsdocuments).map(x=>(<UploadFile listedocs={listedocs} key={x} typefile={x} />))}</>)
}

export default Upload

