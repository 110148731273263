import React , { useState }   from "react";
import { useSelector , useDispatch} from "react-redux";
import axios from "axios";

import { setLeadsfiltres , setChoiceliste , setFiltres } from "../../state/redux";

import CalendarTwoPickers from '../../Components/Calendar/CalendarTwoPickers' ; 
import ButtonSimple from "../../Components/Buttons/ButtonSimple";

import './DashboardFiltres.scss'

const listemoismini = ['Jan','Fev','Mars','Avr','Mai','Juin','Juil','Août','Sept','Oct','Nov','Dec'];
const listestatus  = {'contact':'Contact','devisenvoye': 'Devis envoyé','attente_signature':'Signature en attente','attente_documents':'Documents en attente','gagne':'Gagné'};
const listeinteractions ={'nrp':'Ne répond pas','rdv':'Rendez-vous','relance': 'Relance','echec':'Echec'} ;

const formatdatemini = (date)=>{if(!date)  return ''  ; 
  return   listemoismini[(new Date(date)).getMonth()]+' '+(new Date(date)).getDate()+' ,     '+ (new Date(date)).getFullYear()
}

const classNames = require('classnames');
function DashboardFiltres(){
    const dispatch = useDispatch() ;
    const campagnes= useSelector(state=>state.leads.campagnes);
    const {leadsfiltres, filtres  , choiceliste}= useSelector(state=>state.leads);
   

   const [displaytwocalendars, setDisplaytwocalendars]  = useState(false);
   const [displaybottomfiltres, setDisplaybottomfiltres]  = useState(false);


    const selectstatus =(x)=>(<div  key={x} className="filtreselect" onClick={()=>{x===filtres?.['status']?dispatch(setFiltres({...filtres, 'status': null})) :dispatch(setFiltres({...filtres, 'status': x})) }}  >
                                    <div className={classNames("filtreselect_check",{"selectedcheck":filtres?.['status']===x })} ></div>
                                    <div className="filtreselect_texte">{listestatus[x]}</div>
                            </div>);
                                                                             
    const selectinteraction =(x)=>(<div  key={x} className="filtreselect"  onClick={()=>{x===filtres?.['interaction']?dispatch(setFiltres({...filtres, 'interaction': null})) :dispatch(setFiltres({...filtres, 'interaction': x})) }} >
                                    <div className={classNames("filtreselect_check",{"selectedcheck":filtres?.['interaction']===x })} ></div>
                                    <div className="filtreselect_texte">{listeinteractions[x]}</div>
                           </div>);
                                                                          
    const selectcampagne =(x)=>(<div  key={x} className="filtreselect"  onClick={()=>{x===filtres?.['campagne']?dispatch(setFiltres({...filtres, 'campagne': null})) :dispatch(setFiltres({...filtres, 'campagne': x})) }} >
        <div className={classNames("filtreselect_check",{"selectedcheck":filtres?.['campagne']===x })} ></div>
        <div className="filtreselect_texte"> {x}  </div>
    </div>);
                                                                   
    const selecthot =()=>(<div  key='hot' className="filtreselect"   onClick={()=>{filtres?.['hot']===1?dispatch(setFiltres({...filtres, 'hot': 0})) :dispatch(setFiltres({...filtres, 'hot': 1})) }} >
        <div className={classNames("filtreselect_check",{"selectedcheck":filtres?.['hot']===1 })} ></div>
        <div className="filtreselect_texte"> Leads Chauds  </div>
    </div>);

   const filtrer = async()=>{   setDisplaytwocalendars(false) ; 
                        let allfiltres ={'choicestatus':filtres?.['status'],'choiceinteraction':filtres?.['interaction'],'choicecampagne':filtres?.['campagne'],'choicehot':filtres?.['hot'],'date1':filtres?.['date1'],'date2':filtres?.['date2']} ; 
                        Object.keys(allfiltres).map(x=>allfiltres[x]===null &&  delete allfiltres[x]  )  ;   
                        setDisplaybottomfiltres(false)  ; dispatch(setChoiceliste('customchoice'))  ;
                        const response = await axios({method:'POST',url:`/clients/findcustomclients`,data : allfiltres,headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`    } });
                        dispatch(setLeadsfiltres([...response.data?.sort((a,b)=>new Date(new Date(a.date_reception) - new Date(b.date_reception)))]));}

    return(<div className="dashboardfiltres" >
                <div className="dashboardfiltres_top" >
                    <div className="dashboardfiltres_top_left" >
                        <div className="dashboardfiltres_top_toggle" onClick={()=>{setDisplaybottomfiltres(!displaybottomfiltres) ; dispatch(setChoiceliste('customchoice'));  }}  >Plus de filtres{leadsfiltres!==null && <div  className="nbfiltres">{leadsfiltres.length}</div> }</div>
                  { choiceliste==='customchoice'  &&   <div  className="dashboardfiltres_top_btngrp" >
                               { filtres?.['status']!==null  && <div className="dashboardfiltres_top_filtre" >{listestatus[filtres?.['status']]}<div onClick={()=>{dispatch(setFiltres({...filtres, 'status': null}))}}></div></div>}
                                { filtres?.['interaction']!==null  && <div className="dashboardfiltres_top_filtre" >{listeinteractions[filtres?.['interaction']]}<div onClick={()=>{dispatch(setFiltres({...filtres, 'interaction': null})) }}></div></div>}
                                { filtres?.['campagne']!==null  && <div className="dashboardfiltres_top_filtre" >{filtres?.['campagne']}<div onClick={()=>{dispatch(setFiltres({...filtres, 'campagne': null}))}}></div></div>}
                                {leadsfiltres!==null && <div className="dashboardfiltres_top_filtredate" > {formatdatemini(filtres?.['date1'])} - {formatdatemini(filtres?.['date2'])} </div>}
                        </div>}
                    </div>
                    
                    {displaytwocalendars &&  <CalendarTwoPickers  setDisplaytwocalendars={setDisplaytwocalendars} />}
                </div>
                {displaybottomfiltres &&<div className="dashboardfiltres_bottom" >
                <div className="dashboardfiltres_top_displaycalendar" onClick={()=>{setDisplaytwocalendars(!displaytwocalendars)}} > {formatdatemini(filtres?.['date1'])} - {formatdatemini(filtres?.['date2'])} </div>
                <div   className="dashboardfiltres_bottom_listes">
                        <div   className="dashboardfiltres_bottom_liste">
                            <div className="dashboardfiltres_bottom_titre" >Status</div>
                            { ['contact','devisenvoye','attente_signature','attente_documents','gagne'].map(x=>selectstatus(x))}
                        </div>
                        <div   className="dashboardfiltres_bottom_liste">
                            <div className="dashboardfiltres_bottom_titre" >Interactions</div>
                            { ['nrp','rdv','relance','echec'].map(x=>selectinteraction(x))}
                            <br />
                            {selecthot()}
                        </div>
                        <div   className="dashboardfiltres_bottom_liste">
                            <div className="dashboardfiltres_bottom_titre" >Campagnes</div>
                            { [...campagnes].map(x=>selectcampagne(x))}
                        </div>
                    </div>
                    <div className="dashboardfiltres_bottom_containtbtn">
                        <ButtonSimple color='bouton_color_orange' size='bouton_size_normal' texte="Filtrer"   onclick={filtrer}    />
                    </div>
                </div>}
          
           </div>)
}


export default DashboardFiltres