import React , {useEffect, useCallback }   from 'react';
import { useSelector, useDispatch} from 'react-redux';


import { seteventtochange } from '../../state/redux';

import { searchrdvgooglecalendar } from '../../utils/actions_calendar';

import FicheClientTop from './FicheClientTop';
import FicheClientBottom from './FicheClientBottom';
import PopupMultiTel from '../../Components/Popup/PopupMultiTel';


import './EcranFicheClient.scss';


function EcranFichierClient(){
    const dispatch = useDispatch(); 
    const popupmultitel = useSelector(state=>state.display.popupmultitel ) ;
    const lead = useSelector(state=>state.lead.leadactu);

    const initialiseficheclient = useCallback(() => {
  
                    (async()=>{let ev =await searchrdvgooglecalendar() ; 
                                 dispatch(seteventtochange(ev)) ;  
                    })() ; 
        }, [dispatch,lead.id]) ;

        useEffect(()=>{   initialiseficheclient()   },[initialiseficheclient])



    return(<div className="ficheclient">
                { popupmultitel  &&  <PopupMultiTel    />}
                <FicheClientTop   />
                <FicheClientBottom  />
            </div>)
}


export default EcranFichierClient


