import React from 'react';



import { nbjours ,  verifleadgagne_arecycle , convertdateAA  } from '../../utils/utils_dates';
import { modifyhot , searchandinitialiseclient ,  demarrersequence} from '../../utils/actions_lead';

import { progressions , listestatus } from '../../utils/listeprogression';

import Periodes from './Periodes';

import recyclage from '../../images/recyclage.svg';
import meilleurassu from '../../images/origines/meilleurassu.svg';
import gads from '../../images/origines/gads.svg';
import fb from '../../images/origines/fb.svg';
import inconnu from '../../images/origines/inconnu.png';
import crm from '../../images/origines/crm.png';
import tel from '../../images/tel.svg';



import './TableLeadsDUMB.scss';

const imagesorigine ={"meilleureassurance.com":meilleurassu, "fb": fb, "gads": gads, "crm":crm , "recyclage":recyclage}
const dates_titres ={"newleads":"Date de réception","rdv":"Date de rendez-vous","relances":"Date de relance",
                     "injoignables":"Date du dernier contact","injoignablesdevis":"Date du dernier contact","waitsign":"Date du dernier contact",
                    "waitdocs":"Date du dernier contact","hot":"Date du dernier contact",'searchtable':"Date de réception" ,'customchoice':"Date de réception",
                    "impayes":"Date du dernier contact"}

const dates_champs ={"newleads":"date_reception","rdv":"date_rdvrelance","relances":"date_rdvrelance","injoignables":"date_lastmodif",
                        "injoignablesdevis":"date_lastmodif","waitsign":"date_lastmodif","waitdocs":"date_lastmodif",
                        "hot":"date_lastmodif",'searchtable':"date_reception",'customchoice':"date_reception","impayes":"date_lastmodif"}



const classNames = require('classnames');
function TableLeadsDUMB({id,titre, datas=[],  clicktel}){

   
        const  clickline = (x) => { searchandinitialiseclient(x?.id,false)  }

        let maliste =[]
        progressions.map(x=>x.split('_').map(x=>maliste.push(x)))  ;
   
        const click = (e) => {const lead =  datas.filter(x=>x.id === e.target.closest('tr').id)[0]; 
                            if(e.target.closest('.caseimgactions')===null && e.target.closest('.flamme')===null ){clickline(lead) } }


        const classe_iamge = (x)=>{
              return (  x.date_rdvrelance===undefined ? (x.hot===1 ? 'flamme hot' : 'flamme cold')  :
                        x?.statut_client?.includes('relance') || x?.statut_client?.includes('rdv') ) && ((new Date(x.date_rdvrelance)).getTime() < (new Date()).getTime()   ) ? 'alert':
                        x.hot===1? 'flamme hot' : 'flamme cold'
        }
 
        return(<div id={id}  className={titre !=='notitre'?'tableads':""}>
                       {titre !=='notitre' && <div className='tableads_titre  '>
                                                  <div className='texte'>{titre}</div> 
                                                  {id==='injoignables' && <Periodes /> }
                                                  {((id==='newleads' || id==='waitsign'|| id==='waitdocs' || id==='hot' || id==='impayes' || id==='customchoice') && datas?.length >0 )&&  <div className='demarrersequence'onClick={() =>{demarrersequence(datas)}}  >Démarrer la séquence</div> }
                         </div>}
                                                        
                         <div className='toptablo' >
                         <div className="col0 pl10">{(id==="injoignables") && "Reception"}</div>
                                       <div className="col1 pl10">{dates_titres[id]}</div>
                                        <div className="col2 pl10">Prénom et Nom</div>
                                        <div className="col3 pl0">Origine</div>
                                        <div className="col4 pl10">Statut</div>
                                        <div className="col5 pl10">  </div>
                         </div>
                         <div className={classNames('containertablo',{'containertablo_customchoice':id==="customchoice"})}   id="customchoice" >
                          <table>
                                <tbody className='tbodytableads' >{datas?.length > 0 &&  [...datas].map(x=>(<tr  className='trpositionabsolute' onClick={click}  id={x.id} key={x.id}  >
                                       <td className="col0"  >{id==="injoignables" ?  <div className='nbjours'>{nbjours(x)}j</div>  :<div className={classe_iamge(x)} onClick={()=>{modifyhot(x)}}  ></div>}</td> 
                                                        <td className="col1"  > <div  >{convertdateAA(x[dates_champs[id]])} </div></td>
                                                        <td className="col2"  ><div className='tablecasenomtel' > <span>{x.nom} {x.prenom}</span><span>{x.telephone}</span> </div></td>
                                                        <td  className='col3 caseimg' >
                                                                <img height="30" width="30"   src={ verifleadgagne_arecycle(x)?recyclage :imagesorigine[x.origine]||inconnu} alt="imageorigine"   />  
                                                                <span  className='nomcampagne'> {x?.campagne?.split('/')?.[0]}</span>
                                                        </td>
                                                        <td  className="col4" >
                                                       <div>
                                                           {(x?.statut_client?.split('_'))?.map((y,i)=> (listestatus[y] &&<span  key={i} className={classNames('minibadge',{"minibadgeechec":x?.statut_client?.includes('echec')},{"minibadgegagne":x?.statut_client?.includes('gagne')})}> {listestatus[y]} </span> )) }
                                                       </div>
                                                       </td>
                                                        <td  className='col5 caseimgactions' ><img src={tel} height="22" alt="imgtel" onClick={clicktel}  /></td>
                                                </tr>))}
                                </tbody>
                        </table>
                        </div>
                </div>)
}

export default TableLeadsDUMB
